'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import UiHelper from '../ui/UiHelper';
import SearchResultType from '../enums/SearchResultType';
import ErrorScreenType from '../enums/ErrorScreenType';
import SxQueryObject from '../../sxQuery/SxQueryObject';
var ICONS = {
    // eslint-disable-next-line max-len
    offline: '<svg width="120" height="120" fill="#4a4f62" viewBox="0 0 30 30"><path d="M16.333 17.674C17.318 17.182 18 16.175 18 15c0-1.657-1.343-3-3-3-1.175 0-2.182.682-2.674 1.667L16.333 17.674zM22.778 22.778c-.326.326-.364.815-.144 1.197l.362.362c.154.088.318.149.49.149.256 0 .512-.098.707-.293 5.068-5.069 5.068-13.316 0-18.385-.391-.391-1.023-.391-1.414 0s-.391 1.023 0 1.414C27.067 11.511 27.067 18.489 22.778 22.778zM10.154 11.495c.184-.255.373-.509.602-.738.391-.391.391-1.023 0-1.414s-1.023-.391-1.414 0C9.116 9.57 8.91 9.811 8.716 10.057L10.154 11.495z"/><path d="M19.1 20.441l.359.359c.154.089.319.15.491.15.256 0 .512-.098.707-.293 3.119-3.119 3.119-8.194 0-11.313-.391-.391-1.023-.391-1.414 0s-.391 1.023 0 1.414c2.339 2.339 2.339 6.146 0 8.485C18.916 19.57 18.88 20.059 19.1 20.441zM6.585 7.926c.202-.241.41-.478.637-.705.391-.391.391-1.023 0-1.414s-1.023-.391-1.414 0c-.226.226-.43.466-.637.705L6.585 7.926zM9.243 13.412l-1.581-1.581c-1.258 2.922-.701 6.444 1.681 8.826.195.195.451.293.707.293s.512-.098.707-.293c.391-.391.391-1.023 0-1.414C9.179 17.665 8.692 15.425 9.243 13.412zM5.429 9.598L3.971 8.14c-3.118 5.014-2.513 11.702 1.837 16.053.195.195.451.293.707.293s.512-.098.707-.293c.391-.391.391-1.023 0-1.414C3.656 19.212 3.065 13.791 5.429 9.598z"/><path d="m1 1 28 28" fill="none" stroke="#4a4f62" stroke-miterlimit="10" stroke-width="2"/></svg>',
    // eslint-disable-next-line max-len
    blocked: '<svg width="120" height="120" fill="#4a4f62" viewBox="0 0 128 128"><path d="m108.3 18c-1.3-1.2-3.1-1.1-4.3 0.1-1.1 1.2-1.1 3.1 0.1 4.3 0.6 0.6 1.3 0.8 2.1 0.8s1.6-0.3 2.2-0.9c1.1-1.2 1.1-3.1-0.1-4.3zm-34.1 9.5v0.1c-0.4 1.6 0.6 3.2 2.1 3.6 0.2 0.1 0.5 0.1 0.7 0.1 1.3 0 2.6-0.9 2.9-2.3 0.4-1.6-0.5-3.2-2.1-3.7-1.5-0.4-3.2 0.6-3.6 2.2zm15.9-8.7c1.6-0.4 2.5-2.1 2.1-3.7s-2.1-2.5-3.7-2.1h-0.1c-1.6 0.5-2.5 2.1-2 3.7 0.4 1.3 1.6 2.2 2.9 2.2 0.3 0 0.6-0.1 0.8-0.1zm18 17.3v0.1c-0.4 1.6 0.6 3.2 2.2 3.6 0.2 0.1 0.5 0.1 0.7 0.1 1.4 0 2.6-0.9 2.9-2.3l-2.9-0.8 2.9 0.7c0.4-1.6-0.6-3.2-2.2-3.6s-3.2 0.6-3.6 2.2zm-58-10.8c-1.6 0.4-2.5 2.1-2.1 3.7 0.3 1.4 1.6 2.3 2.9 2.3 0.2 0 0.5 0 0.7-0.1 1.6-0.4 2.6-2 2.2-3.6v-0.1c-0.5-1.7-2.1-2.7-3.7-2.2zm-35.9 12.8c0.4 1.3 1.6 2.2 2.9 2.2 0.3 0 0.5 0 0.8-0.1 1.6-0.4 2.5-2.1 2.1-3.7s-2-2.6-3.6-2.2-2.6 2-2.2 3.6v0.2zm25.1-25.2h-0.1c-1.6-0.4-3.2 0.6-3.6 2.2s0.6 3.2 2.2 3.7c0.3 0.1 0.5 0.1 0.8 0.1 1.3 0 2.5-0.9 2.9-2.2 0.4-1.7-0.6-3.3-2.2-3.8zm-19.8 5.4c-1.2 1.2-1.2 3.1 0 4.3 0.6 0.6 1.4 0.9 2.1 0.9s1.5-0.3 2.1-0.9l0.1-0.1c1.2-1.2 1.2-3.1 0-4.2-1.2-1.2-3.1-1.2-4.3 0zm14.5 6.9a7.5 7.5 0 1 0 0 15 7.5 7.5 0 1 0 0-15zm60 0a7.5 7.5 0 1 0 0 15 7.5 7.5 0 1 0 0-15zm-78 65.6h96c2.8 0 5-2.2 5-5v-31c0-2.8-2.2-5-5-5h-96c-2.8 0-5 2.2-5 5v31c0 2.7 2.2 5 5 5zm95-6h-24.6l24.6-28.7v28.7zm-7.6-29-24.9 29h-26.1l24.9-29h26.1zm-34 0-24.9 29h-23.9l23.7-29h25.1zm-52.4 0h19.6l-19.6 23.9v-23.9zm20 64v-23c0-1.7-1.3-3-3-3s-3 1.3-3 3v23c0 1.7 1.3 3 3 3s3-1.4 3-3zm60 0v-23c0-1.7-1.3-3-3-3s-3 1.3-3 3v23c0 1.7 1.3 3 3 3s3-1.4 3-3z"/></svg>',
    // eslint-disable-next-line max-len
    siteId: '<svg width="120" height="120" fill="#4a4f62" viewBox="0 0 128 128"><path d="m24.9 109.8h78.3c6 0 10.8-4.8 10.8-10.8v-46.7c0-5.9-4.9-10.8-10.8-10.8h-28.3v-18.8c0-2.5-2-4.6-4.6-4.6h-12.5c-2.5 0-4.6 2-4.6 4.6v18.8h-28.3c-6 0-10.8 4.8-10.8 10.8v46.7c-0.1 6 4.8 10.8 10.8 10.8zm34.3-85.6h9.7v25.2h-9.7v-25.2zm-39.2 28.1c0-2.6 2.2-4.8 4.8-4.8h28.3v3.2c0 2.5 2 4.6 4.6 4.6h17.5c2.5 0 4.6-2 4.6-4.6v-3.2h23.3c2.7 0 4.8 2.1 4.8 4.8v46.7c0 2.6-2.2 4.8-4.8 4.8h-78.2c-2.7 0-4.8-2.1-4.8-4.8v-46.7z"/><path d="m76.9 67h16.6c1.7 0 3-1.3 3-3s-1.3-3-3-3h-16.6c-1.7 0-3 1.3-3 3s1.3 3 3 3zm0 11.7h16.6c1.7 0 3-1.3 3-3s-1.3-3-3-3h-16.6c-1.7 0-3 1.3-3 3s1.3 3 3 3zm0 11.7h16.6c1.7 0 3-1.3 3-3s-1.3-3-3-3h-16.6c-1.7 0-3 1.3-3 3s1.3 3 3 3zm-32.4 0c8.1 0 14.8-6.6 14.8-14.7s-6.6-14.7-14.8-14.7-14.8 6.6-14.8 14.7 6.6 14.7 14.8 14.7zm0-23.4c4.8 0 8.8 3.9 8.8 8.7s-3.9 8.7-8.8 8.7-8.8-3.9-8.8-8.7 3.9-8.7 8.8-8.7z"/></svg>',
    // eslint-disable-next-line max-len
    generic: '<svg width="120" height="120" viewBox="0 0 128 128" fill="#4a4f62"><path d="m64 23.6c-3.7 0-6.9008 1.9-8.8008 5l-36.1 60.301c-1.9 3.2-1.9996 7.0988-0.099609 10.299 1.8 3.2 5.1008 5.2012 8.8008 5.2012h72.299c3.7 0 7.1004-1.9012 8.9004-5.2012 1.8-3.2 1.8004-7.0988-0.09961-10.299l-36.1-60.301c-1.9-3.1-5.1008-5-8.8008-5zm0 6c1.5 0 2.7996 0.69961 3.5996 2.0996l36.201 60.301c0.8 1.3 0.79961 2.9008 0.09961 4.3008-0.8 1.3-2.1012 2.0996-3.7012 2.0996h-72.398c-1.5 0-2.9012-0.79961-3.7012-2.0996s-0.70039-3.0008 0.09961-4.3008l36.201-60.301c0.8-1.3 2.0996-2.0996 3.5996-2.0996zm0 19.701c-1.7 0-3 1.3-3 3v21c0 1.7 1.3 3 3 3s3-1.3 3-3v-21c0-1.7-1.3-3-3-3zm0 31.199a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3z"/></svg>'
};
ICONS[ErrorScreenType.IpBlocked] = ICONS.blocked;
export default {
    show: function (status, statusText, context, addSearchField, retryHandler, uiBuilder) {
        if (statusText === void 0) { statusText = ''; }
        if (retryHandler === void 0) { retryHandler = function () { }; }
        var layer = context.layer;
        var searchResultType = context.getSearchResultType();
        var type = ErrorScreenType.Generic;
        // get settings
        if ('onLine' in navigator && !navigator.onLine) {
            type = ErrorScreenType.Offline;
            var eventName_1 = "online.".concat(context.generateId('errorScreen'));
            sxQuery(window).off(eventName_1).on(eventName_1, function () {
                if (sxQuery("#".concat(context.errorLayerId)).length > 0) {
                    retryHandler();
                }
                sxQuery(window).off(eventName_1);
            });
        }
        else if (status >= 400 && status < 500) {
            if (status === 403) {
                type = ErrorScreenType.IpBlocked;
            }
            else {
                type = ErrorScreenType.SiteId;
            }
        }
        else if (status === 0 && statusText.length === 0) {
            type = ErrorScreenType.Blocked;
        }
        var errorScreenSettings = context.pluginConfiguration.errorScreen[type];
        var icon = ICONS[type];
        // prepare layer
        layer.empty();
        var $layer = layer.get(uiBuilder);
        addSearchField($layer);
        var layerContent = layer.getOrResetLayerContent(searchResultType, context.pluginConfiguration.layout, context.searchResultHeadingId);
        $layer.append(layerContent);
        context.getExistingLayer().find('.ss360-filter__mobile-toggle, .ss360-filter__backdrop').remove();
        context.getExistingFilterLayer().remove();
        context.getExistingFilteredResultsLayer().remove();
        context.getExistingSorting().remove();
        // render and show
        var headingLevel = context.pluginConfiguration.accessibility.resultTopHeadingLevel;
        var errorScreenContent = sxQuery("<div id=\"".concat(context.errorLayerId, "\" class=\"ss360-error ss360-suggests ss360-flex ss360-flex--column ss360-flex--justify-center ss360-flex--align-center\"></div>"));
        var errorIcon = sxQuery("<i role=\"presentation\" class=\"ss360-error__icon\">".concat(icon, "</i>"));
        var errorTextWrapper = sxQuery('<div class="ss360-error__content ss360-flex ss360-flex--column"></div>');
        var errorHeading = sxQuery("<h".concat(headingLevel, " class=\"ss360-error__heading\">").concat(errorScreenSettings.title, "</h").concat(headingLevel, ">"));
        var errorMessage = sxQuery("<p class=\"ss360-error__message\">".concat(errorScreenSettings.message, "</p>"));
        var buttonClassName = 'ss360-error__button ss360-n-button ss360-ac-c ss360-ac-b ss360-ac-bg--hover';
        var retryButton = errorScreenSettings.tryAgain !== undefined ? sxQuery("<button type=\"button\" class=\"".concat(buttonClassName, "\">").concat(errorScreenSettings.tryAgain, "</button>")) : new SxQueryObject([]);
        if (type === 'siteId') {
            retryButton = sxQuery("<a href=\"https://docs.sitesearch360.com/installation\" target=\"_blank\" class=\"".concat(buttonClassName, "\">").concat(errorScreenSettings.tryAgain, "</button>"));
        }
        else {
            retryButton.on('click', retryHandler);
        }
        var errorContentWrapper = sxQuery('<div class="ss360-error__description ss360-flex"></div>');
        var errorButtonWrapper = sxQuery('<div class="ss360-error__cta ss360-flex ss360-flex--column"></div>');
        errorButtonWrapper.append(retryButton);
        errorTextWrapper.append(errorHeading).append(errorMessage);
        errorContentWrapper.append(errorIcon).append(errorTextWrapper);
        errorScreenContent.append(errorContentWrapper).append(errorButtonWrapper);
        layerContent.html(errorScreenContent);
        if (searchResultType === SearchResultType.Layover) {
            // // layover
            UiHelper.prepareLayoverLayer(layer.get(context.core.getUiBuilder()), context.core.closeLayer.bind(context.core), context.pluginConfiguration);
            sxQuery('#ss360Darken').on('click', function () {
                context.core.closeLayer();
            });
        }
        else {
            // // embed
            if (!$layer.isVisible()) {
                $layer.fadeIn();
            }
            if ($layer.parents(context.pluginConfiguration.results.embedConfig.contentBlock, true).length === 0) {
                sxQuery(context.pluginConfiguration.results.embedConfig.contentBlock).html($layer);
            }
        }
    }
};

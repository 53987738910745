'use strict';
var CallbackEnhanceType;
(function (CallbackEnhanceType) {
    CallbackEnhanceType["EnterResult"] = "enterResult";
    CallbackEnhanceType["Type"] = "type";
    CallbackEnhanceType["Enter"] = "enter";
    CallbackEnhanceType["Focus"] = "focus";
    CallbackEnhanceType["Blur"] = "blur";
})(CallbackEnhanceType || (CallbackEnhanceType = {}));
export default CallbackEnhanceType;

'use strict';
var SearchResultType;
(function (SearchResultType) {
    SearchResultType["Fullscreen"] = "fullscreen";
    SearchResultType["Embed"] = "embed";
    SearchResultType["Layover"] = "layover";
    /* internal result types */
    SearchResultType["Smart404"] = "smart404";
})(SearchResultType || (SearchResultType = {}));
export default SearchResultType;

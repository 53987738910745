'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import NavigatorHelper from '../utils/NavigatorHelper';
import StringHelper from '../../global/StringHelper';
import UiHelper from './UiHelper';
import Handlers from './Handlers';
import ImageHandler from '../utils/ImageHandler';
import Masonry from '../layout/Masonry';
import InfiniteScrollObserver from '../utils/InfiniteScrollObserver';
import TemplateRenderer from '../../global/TemplateRenderer';
import Logger from '../../global/Logger';
import Polyfill from '../polyfills/Polyfill';
import SuggestionRenderer from './SuggestionRenderer';
import CtaPosition from '../interface/CtaPosition';
import SearchResponse from '../model/SearchResponse';
import SearchIntentHelper from '../utils/SearchIntentHelper';
import Icons from '../components/Icons';
import SxQueryUtils from '../../sxQuery/SxQueryUtils';
import SearchResultType from '../enums/SearchResultType';
import Carousel from './Carousel';
import Helper from '../utils/Helper';
import SimilarContent from './SimilarContent';
import LayoutType from '../enums/LayoutType';
import NoResultsPage from '../components/NoResultsPage';
import Zoe from '../components/Zoe';
import Comparison from '../components/Comparison';
import SuggestType from '../enums/SuggestType';
var getSelectedContentGroup = function (results, headingNode, parentGroup) {
    var selectedContentGroup = '';
    if (results.group) {
        // @ts-ignore
        if (headingNode.length === 0 || (headingNode.attr('id') || '_').replace('ss360-heading-', '') === '_') {
            selectedContentGroup = '_';
        }
        else if (parentGroup.hasClass('ss360-group-ss360_all_results')) {
            selectedContentGroup = 'ss360_all_results';
        }
        else {
            // @ts-ignore
            selectedContentGroup = headingNode.text();
        }
    }
    return selectedContentGroup;
};
var UiBuilder = /** @class */ (function () {
    function UiBuilder(context) {
        this.urlNodeCache = {};
        this.context = context;
        // accessibility
        this.groupHasImageCache = {};
        this.captionHeadingLevel = context.pluginConfiguration.accessibility.resultTopHeadingLevel;
        this.contentGroupHeadingLevel = this.captionHeadingLevel + 1;
        this.searchResultHeadingLevel = this.contentGroupHeadingLevel + 1;
        this.hiddenParts = UiHelper.getHiddenParts(this.context.pluginConfiguration.layout);
        var originalCta = context.pluginConfiguration.results.cta;
        var cta;
        if (originalCta !== undefined && (originalCta instanceof Object) && !(originalCta instanceof Array) && (originalCta.text !== undefined || originalCta.link !== undefined)) {
            cta = [{
                    renderAsButton: true,
                    text: originalCta.text,
                    link: originalCta.link,
                    icon: originalCta.icon,
                    position: CtaPosition.Right,
                    includeContentGroups: originalCta.includeContentGroups,
                    excludeContentGroups: originalCta.excludeContentGroups,
                    clickCallback: originalCta.clickCallback
                }];
        }
        else if (originalCta !== undefined && (originalCta instanceof Array)) {
            cta = originalCta;
        }
        this.cta = cta;
        this.hasCtas = cta !== undefined && cta instanceof Array && cta.length > 0;
        var resultTemplate = context.pluginConfiguration.results.resultTemplate;
        this.resultRenderer = resultTemplate !== undefined && resultTemplate.template !== undefined ? new TemplateRenderer(resultTemplate, context.pluginConfiguration.style.defaultCss) : undefined;
        this.comparison = context.pluginConfiguration.comparison.enabled ? new Comparison(context) : undefined;
    }
    UiBuilder.prototype.buildSuggestItem = function (suggest, query, contentGroup, isHidden, groupHasImages, scripts, queryTags) {
        var _this = this;
        if (isHidden === void 0) { isHidden = false; }
        if (scripts === void 0) { scripts = []; }
        if (queryTags === void 0) { queryTags = []; }
        var pluginConfiguration = this.context.pluginConfiguration;
        var style = pluginConfiguration.style, results = pluginConfiguration.results;
        if (groupHasImages === undefined) {
            groupHasImages = this.groupHasImageCache[suggest.contentGroup] !== undefined ? this.groupHasImageCache[suggest.contentGroup] : true;
        }
        var renderSettings = {
            suggest: suggest,
            contentGroup: contentGroup,
            pluginConfiguration: pluginConfiguration,
            hiddenParts: this.hiddenParts,
            headingLevel: this.searchResultHeadingLevel,
            query: query,
            isHidden: isHidden,
            scripts: scripts,
            groupHasImages: groupHasImages,
            hasCtas: this.hasCtas,
            cta: this.cta,
            contentDataPointName: this.context.pluginConfiguration.results.contentDataPoint,
            preventLazyLoad: !this.context.pluginConfiguration.results.lazyLoadImages,
            similarContent: this.context.pluginConfiguration.similarContent,
            queryTags: queryTags,
            isZoovuBranding: this.context.getIsZoovu(),
            zoe: this.context.pluginConfiguration.zoe,
            comparison: this.context.pluginConfiguration.comparison
        };
        if (this.context.pluginConfiguration.callbacks.singleResultPreRenderCallback) {
            try {
                this.context.pluginConfiguration.callbacks.singleResultPreRenderCallback(suggest.suggest, suggest.variants);
            }
            catch (err) {
                Logger.error(err);
            }
        }
        var useCustomTemplate = this.resultRenderer !== undefined && (pluginConfiguration.results.resultTemplate.includeContentGroups === undefined || pluginConfiguration.results.resultTemplate.includeContentGroups.indexOf(suggest.getOriginalContentGroup()) !== -1);
        // bind events
        var $item;
        var cacheKey = (suggest.getLink() || suggest.getIdentifier()) && suggest.getType() !== SuggestType.Custom ? "".concat(suggest.getLink(), "#").concat(suggest.getIdentifier()) : undefined;
        var safeKey = StringHelper.getSafeKey(contentGroup);
        if (useCustomTemplate) {
            $item = this.resultRenderer.render(suggest, contentGroup, isHidden);
        }
        else if (cacheKey !== undefined && this.urlNodeCache[cacheKey] !== undefined) {
            $item = this.urlNodeCache[cacheKey].clone();
            if (isHidden) {
                $item.addClass('ss360-suggests--hidden');
            }
            else {
                $item.removeClass('ss360-suggests--hidden');
            }
            var originalContentGroup = suggest.getOriginalContentGroup();
            var originalGroup = contentGroup === '*' && originalContentGroup !== undefined ? "ss360-original-group-".concat(StringHelper.getSafeKey(originalContentGroup)) : "ss360-original-group-".concat(safeKey);
            if (contentGroup === '*') {
                $item.addClass(originalGroup);
            }
            else {
                $item.removeClass(originalGroup);
            }
            var headingId = "ss360-heading-".concat(Math.round(100000 * Math.random()));
            $item.find('.ss360-suggests__link-wrap').attr('id', headingId);
            $item.find('.ss360-suggests__wrap').attr('aria-labelledby', headingId);
        }
        else {
            $item = sxQuery(SuggestionRenderer.renderSS360Suggest(renderSettings));
            if (cacheKey !== undefined) {
                this.urlNodeCache[cacheKey] = $item;
            }
        }
        if (this.resultRenderer !== undefined && $item.find('#zoovu-assistant').length > 0) {
            $item.find('script').each(function (elem) {
                scripts.push(sxQuery(elem));
            });
        }
        var bindEvents = function () {
            if ($item.find('#zoovu-assistant').length > 0) {
                $item.addClass('ss360-suggests--assistant');
            }
            if (_this.hasCtas) {
                $item.find('.ss360-cta').each(function (singleCta, idx) {
                    var clickCallback = (_this.cta[idx] || {}).clickCallback;
                    if (clickCallback !== undefined) {
                        sxQuery(singleCta).on('click', function (e) { clickCallback(e, suggest.suggest); });
                    }
                });
                $item.find('.ss360-cta--ss360-icon').on('focus', function (e) {
                    sxQuery(e.target).find('path').attr('fill', '#fefefe');
                }).on('focusout', function (e) {
                    sxQuery(e.target).find('path').attr('fill', style.themeColor);
                });
            }
            if (_this.context.hasInsights()) {
                $item.find('a:not(.ss360-js-add-to-cart):not(.ss360-js-exclude-tracking)').on('click', function () {
                    sxQuery(window).off("beforeunload.".concat(_this.context.generateId('ss360Insights')));
                    var cgItems = $item.parent().find('.ss360-suggests:not(.ss360-suggests--hidden)').get();
                    var allItems = _this.context.getExistingLayer().find('.ss360-suggests:not(.ss360-suggests--hidden)').get();
                    var itemEl = $item.get()[0];
                    var contentGroupPosition = SxQueryUtils.indexInNodeList(itemEl, cgItems) + 1;
                    var position = SxQueryUtils.indexInNodeList(itemEl, allItems) + 1;
                    _this.context.getInsights().trackSerpClick(query, itemEl, _this.context.getExistingLayer().find('.ss360-layer__content').get()[0], allItems.length, position, contentGroupPosition, suggest.getLink(), $item.parents("".concat(_this.context.layer404Id)).length > 0 ? SearchResultType.Smart404 : _this.context.getSearchResultType(), undefined, undefined, undefined, contentGroup, suggest.getOriginalContentGroup());
                });
                $item.find('.ss360-js-add-to-cart').on('click', function () {
                    var $el = $item.find('.ss360-js-add-to-cart');
                    // @ts-ignore
                    var priceValue = $el.data('ss360Price') || $el.data('zoovuPrice');
                    // @ts-ignore
                    var priceUnit = $el.data('ss360PriceUnit') || $el.data('zoovuPriceUnit');
                    var unitPrice = parseFloat(priceValue);
                    if (Helper.isNaN(unitPrice)) {
                        unitPrice = parseFloat(priceValue.replace(/,/g, '.'));
                    }
                    if (Helper.isNaN(unitPrice)) {
                        unitPrice = undefined;
                    }
                    sxQuery(window).off("beforeunload.".concat(_this.context.generateId('ss360Insights')));
                    _this.context.getInsights().trackAddToCart(undefined, suggest.getIdentifier(), suggest.getLink(), 1, unitPrice, priceUnit, true);
                });
            }
            $item.find('a').on('click', function (e) {
                if (SxQueryUtils.linkOpensInNewTab(e)) {
                    return;
                }
                var $target = sxQuery(e.target);
                var $parentGroup = sxQuery($target.parents('.ss360-group')[0]);
                var headingNode = $parentGroup.find('.ss360-group__heading');
                var selectedContentGroup = getSelectedContentGroup(results, headingNode, $parentGroup);
                var liNode = $target.parents('li')[0];
                var liNodes = $parentGroup.find('li.ss360-suggests').get();
                var idx = liNodes.indexOf(liNode);
                if (pluginConfiguration.allowCookies) {
                    _this.context.createCookie('-cg--c', selectedContentGroup, 1 / 24);
                    _this.context.createCookie('-offset--c', idx, 1 / 24);
                    _this.context.createCookie('-query--c', query, 1 / 24);
                }
                else if (NavigatorHelper.hasHistoryAPI(_this.context.pluginConfiguration.results)) {
                    var searchParams = NavigatorHelper.buildQueryDict();
                    if (selectedContentGroup) {
                        searchParams[_this.context.getContentGroupQueryParam()] = selectedContentGroup;
                    }
                    searchParams[_this.context.getOffsetQueryParam()] = idx;
                    NavigatorHelper.pushState(searchParams, _this.context.pluginConfiguration);
                }
            });
            var $similarContentTrigger = $item.find('.ss360-similar-content__trigger');
            SimilarContent.bind($similarContentTrigger, $item, suggest, _this.context, query);
            Carousel.bindImageCarouselEvents($item, suggest.getImages());
            if (pluginConfiguration.zoe.showChat) {
                var zoe_1;
                var isChatVisible_1 = false;
                $item.find('.ss360-actions__action--chat').on('click', function () {
                    if (zoe_1 === undefined) {
                        zoe_1 = new Zoe(pluginConfiguration.zoe.region, pluginConfiguration.zoe.apiKey, pluginConfiguration.zoe.locale, pluginConfiguration.zoe.title, pluginConfiguration.zoe.context, suggest.getName(), suggest.getImage(true), suggest.getIdentifier(), $item, pluginConfiguration.ecomDev);
                    }
                    isChatVisible_1 = !isChatVisible_1;
                    if (isChatVisible_1) {
                        $item.css('maxHeight', 'none');
                    }
                    _this.context.getExistingLayer().find('.ss360-suggests:not(.ss360-suggests--hidden)').each(function (el) {
                        var $el = sxQuery(el);
                        if (el !== $item.get(0) && $el.find('.ss360-zoe-chat').length === 0) {
                            if (isChatVisible_1) {
                                $el.css('maxHeight', $el.height());
                            }
                            else {
                                $el.css('maxHeight', 'none');
                            }
                        }
                    });
                    zoe_1.toggleChat();
                });
            }
            if (pluginConfiguration.comparison.enabled) {
                var isComparisonEnabled_1 = _this.comparison.isInComparison(suggest.getIdentifier());
                var isDisabled_1 = false;
                var $compareInput_1 = $item.find('.ss360-actions__compare-checkbox');
                var $compareLabel_1 = $item.find('.ss360-actions__compare');
                var updateComparisonButton_1 = function () {
                    if ($compareInput_1.get(0)) {
                        $compareInput_1.get(0).checked = isComparisonEnabled_1;
                    }
                    if (isComparisonEnabled_1) {
                        _this.comparison.addProduct(suggest, query);
                    }
                    else {
                        _this.comparison.remove(suggest.getIdentifier());
                    }
                };
                if (isComparisonEnabled_1) {
                    updateComparisonButton_1();
                }
                $compareInput_1.on('input', function () {
                    isComparisonEnabled_1 = !isComparisonEnabled_1;
                    updateComparisonButton_1();
                });
                _this.comparison.onRemove(function (identifier) {
                    if (!isComparisonEnabled_1) {
                        return;
                    }
                    if (identifier === suggest.getIdentifier() || identifier === '*') {
                        isComparisonEnabled_1 = false;
                        updateComparisonButton_1();
                    }
                });
                var onDisabledChange_1 = function (state) {
                    if (state && !isComparisonEnabled_1 && !isDisabled_1) {
                        $compareInput_1.attr('disabled', 'disabled');
                        $compareLabel_1.addClass('ss360-actions__compare--disabled');
                        isDisabled_1 = true;
                    }
                    else if (!state && isDisabled_1) {
                        $compareInput_1.attr('disabled', null);
                        $compareLabel_1.removeClass('ss360-actions__compare--disabled');
                        isDisabled_1 = false;
                    }
                };
                if (!isComparisonEnabled_1 && _this.comparison.isComparisonDisabled()) {
                    onDisabledChange_1(true);
                }
                _this.comparison.onDisableChange(function (state) {
                    onDisabledChange_1(state);
                });
            }
        };
        Carousel.bindVariantEvents($item, renderSettings, bindEvents, this.context);
        bindEvents();
        if (!suggest.hasImage() && this.resultRenderer === undefined) {
            $item.addClass('ss360-no-img');
        }
        return $item;
    };
    UiBuilder.prototype.appendSearchConsole = function () {
        var searchConsole = sxQuery("<div id=\"".concat(this.context.consoleId, "\" class=\"ss360-fullscreen-layer\">"));
        var fullScreenConfig = this.context.pluginConfiguration.results.fullScreenConfig;
        if (fullScreenConfig.transition === 'fade') {
            searchConsole.addClass('ss360-fullscreen-layer--fade');
        }
        if (fullScreenConfig.caption !== undefined) {
            searchConsole.append("<h".concat(this.captionHeadingLevel, " class=\"ss360-fullscreen-layer__heading\">").concat(fullScreenConfig.caption, "</h").concat(this.captionHeadingLevel, ">"));
        }
        searchConsole.append(this.buildSearchField('', false, true));
        if (fullScreenConfig.caption !== undefined) {
            searchConsole.find('.ss360-custom-search').addClass('ss360-custom-search--no-margin');
        }
        searchConsole.append("<div id=\"".concat(this.context.resultsId, "\" class=\"ss360-fullscreen-layer__results\"></div>"));
        UiHelper.prependCloseButton(searchConsole);
        sxQuery('body').append(searchConsole);
    };
    UiBuilder.prototype.buildSearchField = function (lastSearchTerm, bindEvents, applyStylingColors, idPostfix) {
        var _this = this;
        if (bindEvents === void 0) { bindEvents = true; }
        if (applyStylingColors === void 0) { applyStylingColors = false; }
        if (idPostfix === void 0) { idPostfix = ''; }
        var ss360SearchWrapper = sxQuery("<section role=\"search\" id=\"".concat(this.context.customSearchId).concat(idPostfix, "\" class=\"ss360-flex ss360-n-section ss360-custom-search\">"));
        var searchBoxId = this.context.customSearchBoxId + idPostfix;
        var _a = this.context.pluginConfiguration, accessibility = _a.accessibility, searchBox = _a.searchBox, callbacks = _a.callbacks;
        var searchBoxSelector = searchBox.selector;
        var searchFieldLabel = accessibility.searchFieldLabel;
        var defaultPlaceholder = searchBox.placeholder;
        var enterCallback = callbacks.enter;
        if (searchFieldLabel !== undefined) {
            var ss360Label = sxQuery("<label style=\"".concat(SxQueryUtils.srOnlyCss, "\" for=\"").concat(searchBoxId, "\" class=\"ss360-sr-only\">").concat(searchFieldLabel, "</label>"));
            ss360SearchWrapper.append(ss360Label);
        }
        var ss360SearchField = sxQuery("<input autocomplete=\"off\" type=\"search\" id=\"".concat(searchBoxId, "\" class=\"ss360-custom-search__searchbox ss360-n-input\">"));
        if (searchBoxSelector !== undefined) {
            var placeholder = sxQuery(searchBoxSelector).attr('placeholder') || defaultPlaceholder || 'Search';
            ss360SearchField.attr('placeholder', placeholder);
            ss360SearchField.val(lastSearchTerm);
            ss360SearchField.on('change', function (e) {
                sxQuery(searchBoxSelector).val(e.target.value);
            });
        }
        var ss360SearchButton = sxQuery("<button type=\"button\" id=\"".concat(this.context.customSearchButtonId, "\" class=\"ss360-custom-search__searchbutton ss360-n-button ss360-ac-b ss360-ac-bg ss360-acd-b--hover ss360-acd-bg--hover\">\n\t\t\t</button>"));
        var searchBoxConfig = this.context.pluginConfiguration.searchBox;
        if (searchBoxConfig.searchButtonLabel !== undefined) {
            ss360SearchButton.html(searchBoxConfig.searchButtonLabel);
        }
        else {
            ss360SearchButton.html(Icons.getSvgIcon(Icons.MAGNIFIER, applyStylingColors ? UiHelper.getSearchButtonIconColor(this.context.pluginConfiguration) : '#fefefe'));
            ss360SearchButton.attr('aria-label', 'Search');
        }
        if (bindEvents) {
            var performSearch_1 = function (query, searchButton) {
                if (query === undefined || query.length === 0) {
                    return;
                }
                if (enterCallback !== undefined && typeof enterCallback === 'function') {
                    enterCallback(query, undefined, undefined, searchButton);
                }
                else {
                    _this.context.core.showResults(query, {
                        searchButton: searchButton
                    });
                }
            };
            ss360SearchField.on('keyup', function (e) {
                if (e.keyCode === 13) {
                    performSearch_1(e.target.value);
                }
            });
            ss360SearchButton.on('click', function (e) {
                performSearch_1(ss360SearchField.val(), e.target);
            });
        }
        ss360SearchWrapper.append(ss360SearchField);
        ss360SearchWrapper.append(ss360SearchButton);
        return ss360SearchWrapper;
    };
    UiBuilder.prototype.buildLayer = function () {
        var tagName = this.context.pluginConfiguration.accessibility.isMainContent ? 'main' : 'section';
        var $layer = sxQuery("<".concat(tagName, " id=\"").concat(this.context.layerId, "\" style=\"display:none\" class=\"ss360-layer ss360-n-section\"></").concat(tagName, ">"));
        $layer.attr('aria-label', this.context.pluginConfiguration.results.searchResultsLayerLabel);
        return $layer;
    };
    UiBuilder.prototype.buildHeadlineNode = function (label, shouldRenderLogo) {
        var headlineNode = sxQuery("<h".concat(this.captionHeadingLevel, " id=\"").concat(this.context.searchResultHeadingId, "\" class=\"ss360-layer__heading\"><a class=\"ss360-layer__heading-anchor\" tabindex=\"-1\" href=\"#\">\n\t\t\t").concat(label, "</a></h").concat(this.captionHeadingLevel, ">"));
        if (this.context.isSmart404()) {
            headlineNode.addClass('ss360-tc-bg ss360-layer__heading--padded');
        }
        headlineNode.find('.ss360-layer__heading-anchor').click(function (e) {
            e.preventDefault();
            e.stopPropagation();
        });
        if (shouldRenderLogo) {
            headlineNode.css('paddingTop', '26px');
        }
        return headlineNode;
    };
    UiBuilder.prototype.buildQueryCorrectionNode = function (correction, id, pluginConfig) {
        var caption = this.context.pluginConfiguration.results.queryCorrectionText;
        var queryCorrectionText = caption.replace('#CORRECTION#', "<a id=\"".concat(id, "\" href=\"#\">").concat(StringHelper.escapeHtml(correction), "</a>"));
        var didYouMeanNode = sxQuery("<div class=\"ss360-query-correction\">".concat(queryCorrectionText, "</div>"));
        didYouMeanNode.on('click', function (e) {
            Handlers.queryCorrection(e, correction, pluginConfig);
        });
        return didYouMeanNode;
    };
    UiBuilder.prototype.buildQueryCorrectionRewriteNode = function (correction, pluginConfig, allowOverride, originalQuery, id) {
        if (allowOverride === void 0) { allowOverride = false; }
        var caption = this.context.pluginConfiguration.results.queryCorrectionRewrite;
        var queryCorrectionText = caption.replace('#CORRECTION#', StringHelper.escapeHtml(correction));
        if (allowOverride) {
            queryCorrectionText = "".concat(queryCorrectionText, ". ").concat(this.context.pluginConfiguration.results.queryCorrectionOverride
                .replace('#ORIGINAL_QUERY#', "<a id=\"".concat(id, "\" href=\"#\">").concat(StringHelper.escapeHtml(originalQuery), "</a>")), ".");
        }
        var didYouMeanNode = sxQuery("<div class=\"ss360-query-correction ss360-query-correction--rewrite\">".concat(queryCorrectionText, "</div>"));
        if (allowOverride) {
            didYouMeanNode.find("#".concat(id)).on('click', function (e) {
                Handlers.queryOverride(e, originalQuery, pluginConfig);
            });
        }
        return didYouMeanNode;
    };
    UiBuilder.prototype.renderSearchResults = function (data, navigation, layerContent, query, totalResults, isPopState) {
        var _this = this;
        if (isPopState === void 0) { isPopState = false; }
        var pluginConfiguration = this.context.pluginConfiguration;
        var layoutConfig = pluginConfiguration.layout;
        var searchResultConfig = pluginConfiguration.results;
        var callbacks = pluginConfiguration.callbacks;
        var moreResultsButton = searchResultConfig.moreResultsButton;
        var totalCounter = 0;
        if (moreResultsButton === undefined) {
            moreResultsButton = null;
        }
        // Prerender callback
        if (callbacks.preRender !== undefined && (typeof callbacks.preRender) === 'function') {
            try {
                var callbackResult = callbacks.preRender(data.plain.suggests, data);
                if (callbackResult !== undefined && callbackResult !== null) {
                    data.setSuggests(callbackResult);
                }
                else { // still set suggests, because copy has been created --> modifications on the original object would not get applied
                    data.setSuggests(data.plain.suggests);
                }
                totalResults = Helper.getTotalCount(data, pluginConfiguration.results.limitPerGroup, pluginConfiguration);
            }
            catch (err) {
                Logger.error(err);
            }
        }
        // prerender callback might have changed the entry count, make sure to rebuild the navigation if necessary
        var groupCount = data.suggests.length;
        if (callbacks.preRender !== undefined && groupCount >= 2 && navigation.entryCount < 2) {
            navigation.entryCount = groupCount;
            navigation.build(this.context.getExistingLayer(), layerContent);
            if (navigation.nav !== null) {
                this.context.getExistingLayer().prepend(navigation.nav);
                this.context.getExistingLayer().prepend(this.context.getExistingCustomSearch());
            }
        }
        // Make sure navigation with one tab/item is not shown
        var shouldRenderNav = navigation.getPosition() !== 'none'
            && (groupCount > 1 || (navigation.isTabbed() && !navigation.isDropdown())); // don't show scroll or dropdown navigation for one-item entries
        if (navigation.isDropdown()) {
            if (shouldRenderNav) {
                navigation.show();
            }
            else {
                navigation.hide();
            }
        }
        var showAllResultsTab = false;
        if (shouldRenderNav && (navigation.isTabbed() || navigation.isDropdown()) && layoutConfig.navigation.showAllResultsTab && data.showAllResults && groupCount > 1) {
            showAllResultsTab = true;
            SearchIntentHelper.buildAllResultsTab(data);
        }
        var allResultsFirst = this.context.pluginConfiguration.layout.navigation.allResultsFirst;
        var keys = data.getResultGroupNames().sort(function (a, b) {
            if (a === '*') {
                return allResultsFirst ? -1 : 1;
            }
            if (b === '*') {
                return allResultsFirst ? 1 : -1;
            }
            return 0;
        });
        var idx = -1;
        var maxResultCounts = {};
        var preloadedResults = {};
        this.urlNodeCache = {};
        keys.forEach(function (key) {
            idx++;
            totalCounter += _this.renderResultGroup({
                data: data,
                showAllResultsTab: showAllResultsTab,
                key: key,
                keys: keys,
                totalResults: totalResults,
                idx: idx,
                query: query,
                navigation: navigation,
                shouldRenderNav: shouldRenderNav,
                moreResultsButton: moreResultsButton,
                layerContent: layerContent
            }, maxResultCounts, preloadedResults);
        });
        this.urlNodeCache = {};
        navigation.onResultsRendered(isPopState);
        Polyfill.grid(this.context);
        if (Polyfill.shouldApplyGrid(pluginConfiguration.layout)) {
            var eventName = "resize.".concat(this.context.generateId('ss360GridPolyfill'));
            sxQuery(window).off(eventName);
            var isApplying_1 = false;
            sxQuery(window).on(eventName, function () {
                if (isApplying_1)
                    return;
                setTimeout(function () {
                    Polyfill.grid(_this.context);
                    isApplying_1 = false;
                }, 200);
            });
        }
        if (this.comparison) {
            this.comparison.show();
        }
        return totalCounter;
    };
    UiBuilder.prototype.renderResultGroup = function (settings, maxResultsCounts, preloadedResults) {
        var _this = this;
        var key = settings.key, showAllResultsTab = settings.showAllResultsTab, data = settings.data, keys = settings.keys, totalResults = settings.totalResults, navigation = settings.navigation, shouldRenderNav = settings.shouldRenderNav, idx = settings.idx, moreResultsButton = settings.moreResultsButton, query = settings.query, layerContent = settings.layerContent;
        var pluginConfiguration = this.context.pluginConfiguration;
        var contentGroupsConfig = pluginConfiguration.contentGroups;
        var moreResultsCallback = pluginConfiguration.callbacks.moreResults;
        var searchResultConfig = pluginConfiguration.results;
        var layoutConfig = pluginConfiguration.layout;
        var callbacks = pluginConfiguration.callbacks;
        var pagingSize = searchResultConfig.moreResultsPagingSize;
        var highlight = searchResultConfig.highlightQueryTerms;
        var isAllResultsTab = key === '*' && showAllResultsTab;
        var values = data.getSuggestsArray(key);
        var safeKey = StringHelper.getSafeKey(isAllResultsTab ? 'ss360_all_results' : key);
        if (values === null || values === undefined) {
            values = [];
        }
        // the view key
        var viewKey = key;
        if (viewKey === '_') {
            viewKey = contentGroupsConfig.otherName;
            if (keys.length > 1 && !contentGroupsConfig.ignoreOther && (viewKey === undefined || viewKey === '')) {
                viewKey = 'Other';
            }
        }
        else if (isAllResultsTab) {
            viewKey = layoutConfig.navigation.allResultsTabName;
        }
        if (contentGroupsConfig.viewNames[viewKey] !== undefined) {
            viewKey = contentGroupsConfig.viewNames[viewKey];
        }
        if (viewKey === undefined) {
            viewKey = '';
        }
        // result count
        var maxResultsCount = UiHelper.getResultCount(key, values, showAllResultsTab, searchResultConfig, data, totalResults);
        maxResultsCounts[key] = maxResultsCount;
        if (shouldRenderNav) {
            navigation.addEntry(viewKey, safeKey, query, maxResultsCount, idx, this.context.getSearchResultType(), idx, keys.length, key);
        }
        // all results that belong under the given caption
        var headingId = this.context.generateId("ss360-heading-".concat(safeKey));
        var labelledBy = safeKey !== '_' || contentGroupsConfig.otherName !== '' ? "aria-labelledby=\"".concat(headingId, "\"") : '';
        var groupedNode = sxQuery("<section class=\"ss360-n-section ss360-group ss360-group-".concat(safeKey, "\" ").concat(labelledBy, "></section>"));
        if (pluginConfiguration.contentGroups.classMap[safeKey]) {
            groupedNode.addClass(pluginConfiguration.contentGroups.classMap[safeKey]);
        }
        if (navigation.isTabbed() && idx === 0) {
            groupedNode.addClass('ss360-group--active');
        }
        if (viewKey.length > 0 && values.length > 0 && searchResultConfig.showContentGroupHeadings) {
            var keyNode = sxQuery("<h".concat(this.contentGroupHeadingLevel, " id=\"").concat(headingId, "\" class=\"ss360-group__heading ss360-tc-bg\">").concat(viewKey, "</h").concat(this.contentGroupHeadingLevel, ">"));
            if (layoutConfig.navigation.type === 'tabs' && keys.length === 1) {
                keyNode.addClass('ss360-sr-only');
            }
            groupedNode.append(keyNode);
        }
        var list = sxQuery("<ul class=\"ss360-list".concat(UiHelper.getGridClassName(layoutConfig, key, this.context), "\"></ul>"));
        groupedNode.append(list);
        var resultCounter = 0;
        var shadowResultCounter = 0;
        var hasHiddenResults = false;
        var placeholderImage = this.context.pluginConfiguration.results.placeholderImage;
        if (this.groupHasImageCache[key] === undefined && placeholderImage !== undefined && placeholderImage !== null && placeholderImage.length > 0) {
            this.groupHasImageCache[key] = true;
        }
        var groupHasImages = this.groupHasImageCache[key] === undefined ? values.reduce(function (acc, res) {
            if (acc) {
                return true;
            }
            return res.hasImage();
        }, false) : this.groupHasImageCache[key];
        this.groupHasImageCache[key] = groupHasImages;
        var scripts = [];
        var queryTags = data.getQueryTags();
        SxQueryUtils.each(values, function (index, suggest) {
            if (_this.resultRenderer !== undefined || UiHelper.hasVisibleContent(suggest, _this.hiddenParts)) {
                var isVisible = moreResultsButton !== null ? (resultCounter < pagingSize) : true;
                var suggestNode = _this.buildSuggestItem(suggest, query, key, !isVisible, groupHasImages, scripts, queryTags);
                if (callbacks.resultLine !== undefined) {
                    try {
                        callbacks.resultLine(suggest.suggest, suggestNode.get()[0]);
                    }
                    catch (ex) {
                        console.warn(ex);
                        // ccl
                    }
                }
                list.append(suggestNode);
                hasHiddenResults = hasHiddenResults || !isVisible;
                resultCounter++;
            }
            else {
                shadowResultCounter++;
            }
        });
        if (list.hasClass('ss360-grid') && list.find('.ss360-suggests__variants-count').length > 0) {
            list.addClass('ss360-grid--has-variants-label');
        }
        if (values.length > pagingSize && shadowResultCounter !== 0) {
            hasHiddenResults = true;
            groupedNode.data('skippedResults', shadowResultCounter);
        }
        if (values.length > 0) {
            // highlight stuff
            if (highlight) {
                var highlightContext = this.context.pluginConfiguration.results.resultTemplate.highlightContext;
                UiHelper.highlightQueryTermsInResult(query, groupedNode, highlightContext);
                if (data.queryCorrectionRewrite && data.queryCorrectionRewrite !== query) {
                    UiHelper.highlightQueryTermsInResult(data.queryCorrectionRewrite, groupedNode, highlightContext);
                }
            }
            layerContent.append(groupedNode);
            var offsetIncrement_1 = 0;
            var isInifniteScroll_1 = searchResultConfig.infiniteScroll && (navigation.isTabbed() || Object.keys(data.suggests).length === 1);
            if ((moreResultsButton !== null || isInifniteScroll_1) && hasHiddenResults) { // render search button if defined and results left
                var isMasonryLayout_1 = layoutConfig.desktop.type === LayoutType.Masonry || layoutConfig.mobile.type === LayoutType.Masonry;
                var brokenImageHandler_1 = function (toggledGroup) {
                    // handle the case where all images failed
                    if (searchResultConfig.hideResultsWithoutImage && callbacks.resultImageError === undefined) {
                        var toObserve = toggledGroup !== undefined ? sxQuery(toggledGroup).find('img') : groupedNode.find('.ss360-suggests:not(.ss360-suggests--hidden):not(.ss360-custom-result) img');
                        var active_1 = false;
                        var brokenArr_1 = [];
                        var errorCallback_1 = function (result) {
                            brokenArr_1.push(result);
                            offsetIncrement_1++; // add this to the search offset
                            if (active_1)
                                return;
                            active_1 = true;
                            setTimeout(function () {
                                brokenArr_1.forEach(function (target) {
                                    if (toggledGroup !== undefined) {
                                        toggledGroup.splice(toggledGroup.indexOf(target.get()[0]), 1);
                                    }
                                    target.remove();
                                });
                                var isEmpty = toggledGroup === undefined ? groupedNode.find('.ss360-suggests:not(.ss360-suggests--hidden):not(.ss360-masonry-break)').length === 0 : toggledGroup.length === 0;
                                if (isEmpty && groupedNode.find('.ss360-suggests.ss360-suggests--hidden').length > 0) {
                                    showMoreCallback_1();
                                }
                                active_1 = false;
                            }, 50);
                        };
                        groupedNode.find('.ss360-suggests.ss360-no-img:not(.ss360-custom-result)').each(function (entry) {
                            errorCallback_1(sxQuery(entry));
                        });
                        toObserve.on('error', function (e) { errorCallback_1(sxQuery(e.target).parents('.ss360-suggests', true)); });
                    }
                };
                var formatNumber_1 = function (num) { return 'Intl' in window ? new Intl.NumberFormat().format(num) : num.toString(); };
                var getPaginationLabel_1 = function (count, total) { return searchResultConfig.pageDescriptionLabel ? "".concat(StringHelper.escapeHtml(searchResultConfig.pageDescriptionLabel)
                    .replace(/#COUNT#/g, "<strong class=\"ss360-pagination__visible\">".concat(formatNumber_1(count), "</strong>")).replace(/#TOTAL#/g, "<strong class=\"ss360-pagination__total\" data-num=\"".concat(total, "\">").concat(formatNumber_1(total), "</strong>"))) : ''; };
                var showMoreCallback_1 = function (e) {
                    var hidden = groupedNode.find('.ss360-suggests.ss360-suggests--hidden');
                    var offset = groupedNode.find('.ss360-suggests').length + offsetIncrement_1;
                    var preloaded = offset - _this.context.getExistingLayer().find(".ss360-group-".concat(safeKey, " ul li.ss360-suggests:not(.ss360-suggests--hidden)")).length;
                    if (hidden.length > 0 && !isInifniteScroll_1) { // focus first
                        var $toFocus_1 = sxQuery(hidden[0]).find('.ss360-suggests__link');
                        var $html_1 = sxQuery('html');
                        var modifyScrollBehavior_1 = $html_1.css('scrollBehavior') === 'smooth';
                        var scrollBehavior_1 = ($html_1.attr('style') || '').toLowerCase().indexOf('scroll-behavior') !== -1 ? $html_1.css('scrollBehavior') : null;
                        if (modifyScrollBehavior_1) {
                            $html_1.css('scrollBehavior', 'initial');
                        }
                        setTimeout(function () {
                            $toFocus_1.focus();
                            if (modifyScrollBehavior_1) {
                                $html_1.css('scrollBehavior', scrollBehavior_1);
                            }
                        }, 5);
                    }
                    var visibleCount = groupedNode.find('.ss360-suggests:not(.ss360-suggests--hidden)').length;
                    var toggleCount = Math.min(pagingSize, hidden.length);
                    var toggledGroup = [];
                    var _loop_1 = function (i) {
                        var elem = hidden.get(i);
                        toggledGroup.push(elem);
                        visibleCount++;
                        if (isMasonryLayout_1) {
                            sxQuery(elem).removeClass('ss360-suggests--hidden').show();
                        }
                        else if (!('ss360IsBeingShown' in elem)) {
                            elem.ss360IsBeingShown = true;
                            sxQuery(elem).fadeIn(30, (function (element) {
                                var $elem = sxQuery(element);
                                $elem.removeClass('ss360-suggests--hidden');
                            }).bind(_this, elem));
                            var $paginationLabel_1 = groupedNode.find('.ss360-pagination__label');
                            if ($paginationLabel_1.length > 0) {
                                setTimeout(function () {
                                    var visibleResults = groupedNode.find('.ss360-suggests:not(.ss360-suggests--hidden)').length;
                                    $paginationLabel_1.get(0).innerHTML = "".concat(getPaginationLabel_1(visibleResults, maxResultsCount));
                                    Helper.updatePaginationProgress(groupedNode.find('.ss360-pagination__progress'), visibleResults, maxResultsCount);
                                }, 100);
                            }
                        }
                    };
                    for (var i = 0; i < toggleCount; i++) {
                        _loop_1(i);
                    }
                    if (isMasonryLayout_1) {
                        Masonry.init(_this.context); // redraw layer
                    }
                    if (moreResultsCallback !== undefined && typeof moreResultsCallback === 'function') {
                        try {
                            moreResultsCallback.bind((e || {}).target || _this, visibleCount, maxResultsCount, viewKey, data.plain).call();
                        }
                        catch (ex) {
                            Logger.error(ex);
                        }
                    }
                    // highlight stuff
                    if (highlight) {
                        UiHelper.highlightQueryTermsInResult(query, groupedNode);
                    }
                    ImageHandler(_this.context);
                    Polyfill.grid(_this.context);
                    brokenImageHandler_1(toggledGroup);
                    var moreResultsPagingSize = _this.context.pluginConfiguration.results.moreResultsPagingSize;
                    // prefetch
                    if (offset < maxResultsCount || preloaded > moreResultsPagingSize) {
                        var callback_1 = function () {
                            var hiddenPrefetched = groupedNode.find('.ss360-suggests.ss360-suggests--hidden').get().filter(function (item) { return hidden.get().indexOf(item) === -1; });
                            if (hiddenPrefetched.length === 0 && !isAllResultsTab) {
                                sxQuery(moreResultsButton).remove();
                                groupedNode.find('.ss360-pagination').remove();
                            }
                            else if (hiddenPrefetched.length === 0 && isAllResultsTab
                                && Object.keys(preloadedResults).reduce(function (acc, resultGroupKey) { return preloadedResults[resultGroupKey].length + acc; }, 0) === 0
                                && groupedNode.find('.ss360-suggests.ss360-suggests--hidden').get().filter(function (item) { return toggledGroup.indexOf(item) === -1; }).length === 0) {
                                sxQuery(moreResultsButton).remove();
                                sxQuery((e || {}).target).remove();
                                groupedNode.find('.ss360-pagination').remove();
                            }
                            ImageHandler(_this.context);
                            if (isMasonryLayout_1) {
                                Masonry.init(_this.context); // force to redraw
                            }
                            if (isInifniteScroll_1) {
                                new InfiniteScrollObserver(showMoreCallback_1).observe(groupedNode, _this.context.layerId);
                            }
                            Polyfill.grid(_this.context);
                        };
                        var sort_1 = data.sorting;
                        if (sort_1 === undefined && data.activeSortingOption !== undefined) {
                            try {
                                sort_1 = _this.context.pluginConfiguration.results.nameParsing ? data.activeSortingOption.name : data.activeSortingOption.key;
                            }
                            catch (ex) {
                                // ccl
                            }
                        }
                        if (!isAllResultsTab) {
                            _this.context.core.prefetchResults({
                                offset: offset,
                                contentGroup: key,
                                query: StringHelper.unescapeHtml(query),
                                callback: function (suggestions) {
                                    if (preloadedResults[key] === undefined) {
                                        preloadedResults[key] = suggestions;
                                    }
                                    else {
                                        preloadedResults[key] = preloadedResults[key].concat(suggestions);
                                    }
                                    callback_1();
                                },
                                filters: data.activeFilterOptions,
                                preloaded: preloaded,
                                sort: sort_1
                            });
                        }
                        else {
                            var groupKeys_1 = Object.keys(maxResultsCounts).filter(function (groupKey) { return groupKey !== '*'; });
                            var loadOffsets_1 = {};
                            var ignoredGroups_1 = 0;
                            groupKeys_1.forEach(function (groupKey) {
                                var loadedOffset = sxQuery(".ss360-group-".concat(StringHelper.getSafeKey(groupKey), " .ss360-suggests")).length;
                                if (loadedOffset < maxResultsCounts[groupKey]) {
                                    loadOffsets_1[groupKey] = loadedOffset;
                                }
                                else {
                                    ignoredGroups_1++;
                                }
                            });
                            var loaded_1 = 0;
                            var loadCallback_1 = function (groupKey, suggestions) {
                                if (groupKey !== undefined && suggestions !== undefined) {
                                    if (preloadedResults[groupKey] === undefined) {
                                        preloadedResults[groupKey] = suggestions;
                                    }
                                    else {
                                        preloadedResults[groupKey] = preloadedResults[groupKey].concat(suggestions);
                                    }
                                }
                                loaded_1++;
                                if ((groupKey === undefined && suggestions === undefined) || loaded_1 + ignoredGroups_1 === groupKeys_1.length) {
                                    var searchResults = Object.keys(preloadedResults).map(function (entryKey) {
                                        var arr = preloadedResults[entryKey].splice(0, moreResultsPagingSize);
                                        var results = arr.map(function (s) { return (s.plain instanceof Array ? s.plain : [s.plain]); });
                                        return {
                                            results: results,
                                            type: entryKey,
                                            name: entryKey,
                                            totalResults: maxResultsCounts[entryKey]
                                        };
                                    });
                                    var fakeResponse = new SearchResponse({
                                        searchResults: searchResults,
                                        totalResultsPerContentGroup: maxResultsCounts,
                                        sorting: data.sorting,
                                        sortingOptions: data.sortingOptions,
                                        activeSortingOption: data.activeSortingOption,
                                        sortingOrder: data.sortingOrder
                                    });
                                    SearchIntentHelper.buildAllResultsTab(fakeResponse);
                                    var suggestArray = fakeResponse.getSuggestsArray('*');
                                    var renderTarget_1 = groupedNode.find('.ss360-list');
                                    suggestArray.forEach(function (suggest) {
                                        if (UiHelper.hasVisibleContent(suggest, _this.hiddenParts)) {
                                            var $item = _this.buildSuggestItem(suggest, StringHelper.unescapeHtml(query), '*', true, undefined, [], queryTags);
                                            renderTarget_1.append($item);
                                        }
                                    });
                                    callback_1();
                                }
                            };
                            if (groupKeys_1.length > ignoredGroups_1 && moreResultsPagingSize >= groupedNode.find('.ss360-suggests--hidden').length) {
                                groupKeys_1.forEach(function (groupKey) {
                                    if (loadOffsets_1[groupKey] === undefined) {
                                        return;
                                    }
                                    if (preloadedResults[groupKey] !== undefined && preloadedResults[groupKey].length >= moreResultsPagingSize) {
                                        loadCallback_1(groupKey, []); // do not append, the results are already there
                                    }
                                    else {
                                        _this.context.core.prefetchResults({
                                            offset: loadOffsets_1[groupKey],
                                            contentGroup: groupKey,
                                            query: StringHelper.unescapeHtml(query),
                                            callback: function (suggests) {
                                                loadCallback_1(groupKey, suggests);
                                            },
                                            filters: data.activeFilterOptions,
                                            sort: sort_1,
                                            preloaded: 100000 // hacky (like the whole thing over here...), make results always hidden
                                        });
                                    }
                                });
                            }
                            else if (moreResultsPagingSize >= groupedNode.find('.ss360-suggests--hidden').length) {
                                loadCallback_1();
                            }
                            else {
                                callback_1();
                            }
                        }
                    }
                    else if ((searchResultConfig.limitPerGroup || hidden.length - toggleCount <= 0) && e !== undefined) { // results already rendered and all shown
                        sxQuery(e.target).remove();
                        groupedNode.find('.ss360-pagination').remove();
                    }
                };
                brokenImageHandler_1();
                if (isInifniteScroll_1) {
                    new InfiniteScrollObserver(showMoreCallback_1).observe(groupedNode, this.context.layerId);
                }
                else {
                    var $moreResultsButton = sxQuery("<button type=\"button\" class=\"ss360-more-results ss360-n-button ss360-ac-b ss360-ac-bg ss360-acd-b--hover ss360-acd-bg--hover\">".concat(moreResultsButton, "</button>"));
                    var paginationWrap = sxQuery('<section role="navigation" aria-label="Pagination" class="ss360-pagination"></section>');
                    if (searchResultConfig.pageDescriptionLabel) {
                        var visibleResults = groupedNode.find('.ss360-suggests:not(.ss360-suggests--hidden)').length;
                        paginationWrap.append("<span class=\"ss360-pagination__label\">".concat(getPaginationLabel_1(visibleResults, maxResultsCount), "</span>"));
                        paginationWrap.append("<div role=\"presentation\" class=\"ss360-pagination__progress\">\n\t\t\t\t\t\t\t\t\t\t\t\t\t<div class=\"ss360-pagination__progress-bar ss360-pagination__progress-bar--active\"></div>\n\t\t\t\t\t\t\t\t\t\t\t\t\t<div class=\"ss360-pagination__progress-bar ss360-pagination__progress-bar--total\"></div>\n\t\t\t\t\t\t\t\t\t\t\t\t</div>");
                        Helper.updatePaginationProgress(paginationWrap.find('.ss360-pagination__progress'), visibleResults, maxResultsCount);
                    }
                    paginationWrap.append($moreResultsButton);
                    $moreResultsButton.on('click', showMoreCallback_1);
                    if (searchResultConfig.showCopyLinkToPositionButton && ('clipboard' in window.navigator) && window.navigator.clipboard.writeText) {
                        var label_1 = StringHelper.escapeHtml(searchResultConfig.copyLinkToPositionButtonLabel);
                        var copiedLabel_1 = StringHelper.escapeHtml(searchResultConfig.copiedLinkToPositionButtonLabel);
                        var copyTimeout_1;
                        var copyLinkToPositionButton = sxQuery("<button class=\"ss360-pagination__copy ss360-n-button ss360-ac-c--hover\">\n\t\t\t\t\t\t\t\t".concat(Icons.getSvgIcon(Icons.LINK, '#a1a7b9', 'ss360-pagination__copy-icon ss360-pagination__copy-icon--inactive'), "\n\t\t\t\t\t\t\t\t").concat(Icons.getSvgIcon(Icons.LINK, pluginConfiguration.style.accentColor, 'ss360-pagination__copy-icon ss360-pagination__copy-icon--active'), "\n\t\t\t\t\t\t\t\t<span class=\"ss360-pagination__copy-label\">").concat(label_1, "</span>\n\t\t\t\t\t\t\t</button>"));
                        var $label_1 = copyLinkToPositionButton.find('.ss360-pagination__copy-label');
                        copyLinkToPositionButton.on('click', function () {
                            // TODO: use different param name for ecom searches, strip ss360 from all results for ecom searches and use it
                            var linkBase = window.location.href.split('?')[0];
                            var queryDict = NavigatorHelper.buildQueryDict();
                            queryDict[_this.context.getPageLinkQueryParam()] = '1';
                            queryDict[_this.context.getOffsetQueryParam()] = (groupedNode.find('.ss360-suggests:not(.ss360-suggests--hidden)').length - 1).toString();
                            var headingNode = groupedNode.find('.ss360-group__heading');
                            var selectedContentGroup = getSelectedContentGroup(searchResultConfig, headingNode, groupedNode);
                            if (selectedContentGroup !== '') {
                                queryDict[_this.context.getContentGroupQueryParam()] = selectedContentGroup;
                            }
                            window.navigator.clipboard.writeText("".concat(linkBase, "?").concat(Object.keys(queryDict).map(function (key) { return "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(queryDict[key])); }).join('&'))).then(function () {
                                $label_1.html(copiedLabel_1);
                                clearTimeout(copyTimeout_1);
                                copyTimeout_1 = setTimeout(function () {
                                    $label_1.html(label_1);
                                }, 2000);
                            });
                        });
                        paginationWrap.append(copyLinkToPositionButton);
                        paginationWrap.addClass('ss360-pagination--has-copy-button');
                    }
                    groupedNode.append(paginationWrap);
                }
            }
            try {
                scripts.forEach(function (script) {
                    var e = document.createElement('script');
                    if (script.attr('src') !== undefined && script.attr('src') !== null) {
                        e.src = script.attr('src');
                    }
                    else {
                        e.innerText = script.get()[0].innerText;
                    }
                    document.getElementsByTagName('body')[0].appendChild(e);
                });
            }
            catch (ex) {
                // ccl
            }
        }
        return resultCounter;
    };
    UiBuilder.prototype.renderNoResultsText = function (layerContent, query, relatedQueries) {
        var results = this.context.pluginConfiguration.results;
        if (results.noResultsRedirect === undefined) {
            new NoResultsPage(this.context, query, this, relatedQueries).render(layerContent);
        }
        else {
            window.location.href = results.noResultsRedirect;
        }
    };
    UiBuilder.prototype.renderWatermark = function (layerContent) {
        if (this.context.getIsZoovu()) {
            return;
        }
        var isIe = !('objectFit' in document.documentElement.style);
        if (this.waterMarkNode !== undefined) {
            this.waterMarkNode.remove();
        }
        this.waterMarkNode = sxQuery("<div style=\"display:block!important;visibility:visible!important;opacity:1!important;\"><a href=\"https://sitesearch360.com\" target=\"_blank\" rel=\"sponsored\" style=\"display:inline!important;visibility:visible!important;opacity:1!important;\">\n\t\t\t<img role=\"presentation\" style=\"max-width:120px!important;float:right!important;visibility:visible!important;position:absolute!important;top:0!important;right:5px!important;opacity:1!important;display:block!important;".concat(isIe ? 'width:120px!important;' : '', "height:auto!important\" src=\"").concat(UiHelper.getLogoSrc(this.context.pluginConfiguration.allowCookies), "\"></a></div>"));
        this.waterMarkNode.find('img').attr('alt', '');
        this.waterMarkNode.find('a').attr('aria-label', this.context.pluginConfiguration.results.watermarkLabel);
        layerContent.append(this.waterMarkNode);
    };
    UiBuilder.prototype.renderLoader = function () {
        var style = this.context.pluginConfiguration.style;
        // ss360 spinner
        var loader = sxQuery('.ss360-loader');
        if (loader.length === 0) {
            loader = sxQuery("<div class=\"ss360-loader\"><div class=\"ss360-loader ss360-loader__bounce ss360-tc-bg\"></div>\n\t\t\t   <div class=\"ss360-loader ss360-loader__bounce ss360-tc-bg ss360-loader__bounce--delayed\"></div></div>");
            sxQuery('body').append(loader);
        }
        var animationDurationS = parseInt(style.animationSpeed.toString(), 10) / 1000;
        var loaderType = style.loaderType;
        if (loaderType === 'square') {
            loader.html('');
            loader.addClass('ss360-loader--square ss360-tc-bg');
            loader.css('animationDuration', "".concat(1 + animationDurationS, "s"));
        }
        else if (loaderType === 'none') {
            loader.html('');
        }
        else {
            loader.find('.ss360-loader__bounce').css('animationDuration', "".concat(2 + animationDurationS, "s"));
        }
    };
    UiBuilder.prototype.clearHasImagesCache = function () {
        var _this = this;
        Object.keys(this.groupHasImageCache).forEach(function (key) {
            delete _this.groupHasImageCache[key];
        });
    };
    return UiBuilder;
}());
export default UiBuilder;

'use strict';
import StringHelper from '../global/StringHelper';
import SxQueryShared from './SxQueryShared';
import Logger from '../global/Logger';
// @ts-ignore
var _callbacksByName = ('__ss360CallbacksMap' in window) ? window['__ss360CallbacksMap'] : {};
// @ts-ignore
window['__ss360CallbacksMap'] = _callbacksByName;
var CSS_TO_PIXEL = {
    width: true,
    height: true,
    minWidth: true,
    minHeight: true,
    maxWidth: true,
    maxHeight: true,
    padding: true,
    paddingLeft: true,
    paddingRight: true,
    paddingTop: true,
    paddingBottom: true,
    left: true,
    right: true,
    top: true,
    bottom: true,
    borderWidth: true
};
var makePureArray = function (elems) {
    if (elems.length === 0) {
        return [];
    }
    var pureArray = [];
    for (var g = 0; g < elems.length; g++) {
        pureArray.push(elems[g]);
    }
    return pureArray;
};
var canMatch = function (node) { return node.matches || node.matchesSelector || node.msMatchesSelector; };
var match = function (node, querySelector) {
    if (node.matches) {
        return node.matches(querySelector);
    }
    if (node.matchesSelector) {
        return node.matchesSelector(querySelector);
    }
    return node.msMatchesSelector(querySelector);
};
var _animate = function (el, animationType, duration, cosParameter, setter, callback) {
    var count = 0;
    var last = +new Date();
    var tick = function () {
        var current = new Date().getTime();
        count += Math.PI / (duration / (current - last));
        var val = cosParameter + cosParameter * Math.cos(count);
        setter(el, val);
        last = +new Date();
        if (count >= Math.PI) {
            if (callback !== undefined && typeof callback === 'function') {
                callback(el);
            }
        }
        else {
            SxQueryShared._notifyAnimation(el, animationType, SxQueryShared._requestAnimation(tick));
        }
    };
    SxQueryShared._notifyAnimation(el, animationType, SxQueryShared._requestAnimation(tick));
};
var _construct = function (data, preventCreatingElement) {
    if (preventCreatingElement === void 0) { preventCreatingElement = false; }
    if (data === undefined || data === null) {
        return new SxQueryObject([]);
    }
    var isWindow = false;
    try {
        isWindow = data instanceof Window || data === window;
    }
    catch (e) {
        isWindow = window.constructor ? data instanceof window.constructor : data === window;
    }
    if (typeof data === 'string') {
        var parsedHTML = !preventCreatingElement && data.indexOf('<') !== -1 ? SxQueryShared.parseHTML(data) : [];
        if (parsedHTML.length === 0) {
            try {
                return new SxQueryObject(querySelectorAll(data));
            }
            catch (ex) {
                console.error(ex);
                return new SxQueryObject([]);
            }
        }
        return new SxQueryObject(parsedHTML);
    }
    if (data instanceof Node || data === document || (data !== undefined && data.appendChild && data.cloneNode)) {
        return new SxQueryObject([data]);
    }
    if (data instanceof Array || data instanceof HTMLCollection || data instanceof NodeList) {
        if (data instanceof Array && data.reduce(function (acc, node) { return acc && (typeof node === 'string'); }, true)) { // array of html strings
            return new SxQueryObject(data.map(function (item) { return SxQueryShared.parseHTML(item); }));
        }
        return new SxQueryObject(data);
    }
    if (isWindow) {
        return new SxQueryObject([data]);
    }
    if (data instanceof SxQueryObject) {
        return new SxQueryObject(data.get());
    }
    return new SxQueryObject([]); //
};
var querySelectorAll = function (data, node) {
    node = node || document;
    var matches = [];
    // get just first element for id-only query - same behavior as jQuery
    if (data.indexOf('#') === 0 && data.indexOf(' ') === -1 && data.indexOf('.') === -1 && data.indexOf(':') === -1 && data.indexOf('>') !== -1) {
        var found = node.getElementById ? node.getElementById(data.replace('#', '')) : node.querySelector(data);
        if (found) {
            matches.push(found);
        }
        return matches;
    }
    if (data.indexOf(':first') !== -1 || data.indexOf(':visible') !== -1) { // handle jQuery-like :first and :visible
        var partialQueries = data.split(' ');
        for (var i = 0; i < partialQueries.length; i++) {
            var query = partialQueries[i];
            var justFirst = false;
            var justVisible = false;
            if (query.indexOf(':first') !== -1) {
                justFirst = true;
                query = query.replace(':first', '');
            }
            else if (query.indexOf(':visible') !== -1) {
                justVisible = true;
                query = query.replace(':visible', '');
            }
            matches = matches.length === 0 ? _construct(node).find(query) : matches.find(query);
            if (justFirst && matches.length > 0) {
                matches = _construct(matches[0]);
            }
            else if (justVisible && matches.length > 0) {
                matches = matches.filter(function (m) { return _construct(m).isVisible(); });
            }
        }
        matches = matches.get();
    }
    else if (data) {
        matches = node.querySelectorAll(data);
    }
    return matches;
};
var SxQueryObject = /** @class */ (function () {
    function SxQueryObject(elems) {
        this._elems = makePureArray(elems);
        for (var i = 0; i < this._elems.length; i++) {
            // @ts-ignore
            this[i] = this._elems[i];
        }
        this.length = this._elems.length;
    }
    SxQueryObject.prototype._it = function (callback) {
        var _elems = this._elems;
        for (var i = 0; i < _elems.length; i++) {
            var elem = _elems[i];
            if (elem && callback) {
                callback(elem, i === _elems.length - 1);
            }
        }
    };
    SxQueryObject.prototype._addNode = function (content, append) {
        if (append === void 0) { append = true; }
        var result = [];
        if (typeof content === 'string') { // parseHtml and append
            result = result.concat(this._addNode(SxQueryShared.parseHTML(content), append));
            return result;
        }
        if (content instanceof Array || content instanceof HTMLCollection || content instanceof NodeList) { // append each element
            for (var i = append ? 0 : content.length - 1; (append ? i < content.length : i >= 0); (append ? i++ : i--)) {
                result = result.concat(this._addNode(content[i], append));
            }
            return result;
        }
        if (content instanceof SxQueryObject) { // get elements and append those
            var cInstance = content;
            result = result.concat(this._addNode(cInstance.get(), append));
            cInstance.clear();
            cInstance.push(result);
            return result;
        }
        if (content instanceof Node || (content !== undefined && content.appendChild && content.cloneNode)) { // finally really append
            this._it(function (elem, isLast) {
                var node = !isLast ? content.cloneNode(true) : content;
                result.push(node);
                try {
                    if (append || !elem.firstChild) {
                        elem.appendChild(node);
                    }
                    else {
                        elem.insertBefore(node, elem.firstChild);
                    }
                }
                catch (ex) {
                    Logger.warn(ex);
                }
            });
            return result;
        }
        return result;
    };
    SxQueryObject.prototype.push = function (arr) {
        this._elems = this._elems || [];
        this._elems = this._elems.concat(arr);
        this.length = this._elems.length;
    };
    SxQueryObject.prototype.clear = function () {
        this._elems = [];
        this.length = 0;
    };
    SxQueryObject.prototype.get = function (idx) {
        if (idx !== undefined) {
            return this._elems[idx];
        }
        return this._elems;
    };
    SxQueryObject.prototype.remove = function () {
        this._it(function (elem) {
            if (elem.parentNode) {
                elem.parentNode.removeChild(elem);
            }
        });
    };
    SxQueryObject.prototype.each = function (callback) {
        var idx = 0;
        this._it(function (elem) {
            callback.call(elem, elem, idx);
            idx++;
        });
    };
    SxQueryObject.prototype._trigger = function (eventName) {
        var e;
        if (window.CustomEvent) {
            try {
                e = new CustomEvent(eventName);
            }
            catch (ex) {
                // ccl
            }
        }
        if (e === undefined) {
            e = document.createEvent('CustomEvent');
            e.initCustomEvent(eventName, true, true, {});
        }
        this._it(function (elem) {
            elem.dispatchEvent(e);
        });
    };
    SxQueryObject.prototype.on = function (event, selector, callback, args) {
        if (callback === undefined) {
            // @ts-ignore
            this.on(event, undefined, selector); // selector is actually callback
        }
        else if (!selector) {
            this._it(function (elem) {
                var events = event.split(',');
                for (var i = 0; i < events.length; i++) {
                    var eventName = events[i];
                    if (eventName.indexOf('.') !== -1) {
                        var nameToKey = eventName.split('.');
                        if (nameToKey.length === 2) {
                            eventName = eventName.trim();
                            // save callback
                            if (!_callbacksByName[eventName]) {
                                _callbacksByName[eventName] = [];
                            }
                            _callbacksByName[eventName].push(callback);
                            eventName = nameToKey[0];
                        }
                    }
                    elem["_ss360_".concat(events[i].trim())] = true;
                    elem.addEventListener(eventName.trim(), callback, args);
                }
            });
        }
        else {
            var handler_1 = function (e) {
                if (!e || !e.target) {
                    return;
                }
                if ((canMatch(e.target) && match(e.target, selector))) { // elem triggered the event
                    callback.bind(e.target, e).call();
                }
                else { // check whether elem child triggered the event
                    var node = e.target;
                    while (node.parentNode && canMatch(node.parentNode)) {
                        node = node.parentNode;
                        if (canMatch(node) && match(node, selector)) {
                            callback.bind(e.target, e).call();
                            break;
                        }
                    }
                }
            };
            this._it(function (elem) {
                var events = event.split(',');
                for (var i = 0; i < events.length; i++) {
                    elem.addEventListener(events[i].trim(), handler_1);
                }
            });
        }
        return this;
    };
    SxQueryObject.prototype.off = function (event, listener) {
        var _this = this;
        var events = event.split(',');
        if (!events || events.length === 0) {
            return this;
        }
        events.forEach(function (e) {
            var isNamedEvent = e.indexOf('.') !== -1;
            var eventName = isNamedEvent ? e.split('.')[0].trim() : undefined;
            var cleanEvent = e.trim();
            var namedCallbacks = isNamedEvent ? _callbacksByName[cleanEvent] : undefined;
            _this._it(function (elem) {
                if (!isNamedEvent) {
                    elem.removeEventListener(cleanEvent, listener);
                }
                else if (namedCallbacks && elem["_ss360_".concat(cleanEvent)]) {
                    elem["_ss360_".concat(cleanEvent)] = false;
                    namedCallbacks.forEach(function (callback) {
                        elem.removeEventListener(eventName, callback);
                    });
                }
            });
        });
        return this;
    };
    SxQueryObject.prototype.mouseenter = function (callback) {
        return this.on('mouseenter', undefined, callback);
    };
    SxQueryObject.prototype.mousedown = function (callback) {
        return this.on('mousedown', undefined, callback);
    };
    SxQueryObject.prototype.mouseup = function (callback) {
        return this.on('mouseup', undefined, callback);
    };
    SxQueryObject.prototype.click = function (callback) {
        return this.on('click', undefined, callback);
    };
    SxQueryObject.prototype.scroll = function (callback) {
        return this.on('scroll', undefined, callback);
    };
    SxQueryObject.prototype.focus = function (callback) {
        if (callback === undefined) {
            this._it(function (elem) {
                if (elem.focus) {
                    elem.focus();
                }
                else {
                    _construct(elem)._trigger('focus');
                }
            });
        }
        else {
            this.on('focus', undefined, callback);
        }
        return this;
    };
    SxQueryObject.prototype.blur = function (callback) {
        if (callback === undefined) {
            this._it(function (elem) {
                if (elem.blur) {
                    elem.blur();
                }
                else {
                    _construct(elem)._trigger('blur');
                }
            });
        }
        else {
            this.on('blur', undefined, callback);
        }
        return this;
    };
    SxQueryObject.prototype.keydown = function (callback) {
        return this.on('keydown', undefined, callback);
    };
    SxQueryObject.prototype.keyup = function (callback) {
        return this.on('keyup', undefined, callback);
    };
    SxQueryObject.prototype.focusout = function (callback) {
        return this.on('focusout', undefined, callback);
    };
    SxQueryObject.prototype.find = function (querySelector) {
        var result = [];
        this._it(function (elem) {
            var found = querySelectorAll(querySelector, elem);
            for (var i = 0; i < found.length; i++) {
                result.push(found[i]);
            }
        });
        return _construct(result);
    };
    SxQueryObject.prototype.findChildren = function (querySelector) {
        var result = [];
        this.children()._it(function (elem) {
            if (elem && canMatch(elem) && (querySelector === undefined || match(elem, querySelector))) {
                result.push(elem);
            }
        });
        return _construct(result);
    };
    SxQueryObject.prototype.children = function () {
        return _construct(this.childrenArr());
    };
    SxQueryObject.prototype.childrenArr = function () {
        var result = [];
        if (this.length === 1) {
            return [].slice.call(this._elems[0].children);
        }
        this._it(function (elem) {
            result = result.concat([].slice.call(elem.children));
        });
        return result;
    };
    SxQueryObject.prototype.is = function (node) {
        for (var i = 0; i < this._elems.length; i++) {
            var el = this._elems[i];
            if (el === node) {
                return true;
            }
        }
        return false;
    };
    SxQueryObject.prototype.text = function (text) {
        if (text === undefined) {
            var result_1 = '';
            this._it(function (elem) {
                result_1 += elem.textContent || '';
            });
            return result_1;
        }
        this._it(function (elem) {
            elem.innerText = text;
        });
        return this;
    };
    SxQueryObject.prototype.position = function () {
        var _elems = this._elems;
        if (_elems.length > 0) {
            var elem = _elems[0];
            var $elem = _construct(elem);
            var offset = void 0;
            if ($elem.css('position') === 'fixed') {
                offset = elem.getBoundingClientRect();
            }
            else {
                var offsetParent = elem.offsetParent;
                var $offsetParent = _construct(offsetParent);
                var parentOffset = {
                    top: 0,
                    left: 0
                };
                if (offsetParent === undefined || offsetParent === null) {
                    return parentOffset;
                }
                offset = $elem.offset();
                if (offsetParent.nodeName !== 'html') {
                    parentOffset = $offsetParent.offset();
                }
                // @ts-ignore
                parentOffset.top += parseFloat($offsetParent.css('borderTopWidth'));
                // @ts-ignore
                parentOffset.left += parseFloat($offsetParent.css('borderLeftWidth'));
                // @ts-ignore
                offset.top = offset.top - parentOffset.top - parseFloat($elem.css('marginTop'));
                // @ts-ignore
                offset.left = offset.left - parentOffset.left - parseFloat($elem.css('marginLeft'));
            }
            return offset;
        }
        return { left: -1, top: -1 };
    };
    SxQueryObject.prototype.attr = function (key, value) {
        var _elems = this._elems;
        if (value === undefined) {
            if (_elems.length > 0) {
                return _elems[0].getAttribute(key);
            }
            return undefined;
        }
        this._it(function (elem) {
            if (value !== null) {
                elem.setAttribute(key, value);
            }
            else {
                elem.removeAttribute(key);
            }
        });
        return this;
    };
    SxQueryObject.prototype.removeAttribute = function (key) {
        if (key) {
            this._it(function (el) {
                if (el.removeAttribute) {
                    el.removeAttribute(key);
                }
            });
        }
        return this;
    };
    SxQueryObject.prototype.hide = function () {
        this._it(function (elem) {
            elem.style.display = 'none';
        });
        return this;
    };
    SxQueryObject.prototype.show = function () {
        this._it(function (elem) {
            elem.style.display = '';
        });
        return this;
    };
    SxQueryObject.prototype.data = function (key, value) {
        if (value === undefined) {
            var _elems = this._elems;
            if (_elems.length > 0) {
                return _elems[0].dataset[key];
            }
            return undefined;
        }
        this._it(function (el) {
            if (value === null) {
                delete el.dataset[key];
            }
            else {
                el.dataset[key] = value;
            }
        });
        return this;
    };
    SxQueryObject.prototype.addClass = function (className) {
        var classElems = className.split(' ');
        this._it(function (elem) {
            for (var i = 0; i < classElems.length; i++) {
                if (classElems[i].trim().length > 0) {
                    if (elem.classList) {
                        elem.classList.add(classElems[i]);
                    }
                    else {
                        elem.className += " ".concat(classElems[i]);
                    }
                }
            }
        });
        return this;
    };
    SxQueryObject.prototype.removeClass = function (className) {
        var classElems = className.split(' ');
        this._it(function (elem) {
            for (var i = 0; i < classElems.length; i++) {
                if (classElems[i].trim().length > 0) {
                    if (elem.classList) {
                        elem.classList.remove(classElems[i]);
                    }
                    else {
                        elem.className = elem.className.replace(new RegExp("(^|\\b)".concat(classElems[i].split(' ').join('|'), "(\\b|$)"), 'gi'), ' ');
                    }
                }
            }
        });
        return this;
    };
    SxQueryObject.prototype.toggleClass = function (className) {
        if (this.hasClass(className)) {
            this.removeClass(className);
        }
        else {
            this.addClass(className);
        }
        return this;
    };
    SxQueryObject.prototype.hasClass = function (className) {
        var _elems = this._elems;
        for (var i = 0; i < _elems.length; i++) { // not using _it to be able to break the loop
            var elem = _elems[i];
            if (elem) {
                if (elem.classList) {
                    if (elem.classList.contains(className)) {
                        return true;
                    }
                }
                else if (new RegExp("(^| )".concat(className, "( |$)"), 'gi').test(elem.className)) {
                    return true;
                }
            }
        }
        return false;
    };
    /* Returns new SxQueryObject containing filtered elements */
    SxQueryObject.prototype.filter = function (validator) {
        return _construct(this.get().filter(validator));
    };
    SxQueryObject.prototype.val = function (value) {
        if (value !== undefined) {
            this._it(function (elem) {
                elem.value = value;
            });
            return this;
        }
        if (this._elems.length > 0) {
            return this._elems[0].value;
        }
        return undefined;
    };
    SxQueryObject.prototype.css = function (key, value) {
        key = key.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
        if (value !== undefined) {
            if (value !== null) {
                var valueString = value.toString();
                // @ts-ignore
                if (CSS_TO_PIXEL[key] && valueString !== 'auto' && valueString !== 'none' && valueString.indexOf('px') === -1 && valueString.indexOf('%') === -1 && valueString.indexOf('calc') === -1
                    && valueString.indexOf('em') === -1 && value !== 0 && value !== '') {
                    value = value.toString();
                    value += 'px';
                }
            }
            this._it(function (elem) {
                elem.style[key] = value;
            });
            return this;
        }
        var _elems = this._elems;
        if (_elems.length > 0) {
            for (var i = 0; i < _elems.length; i++) {
                try {
                    // @ts-ignore
                    return window.getComputedStyle(_elems[i])[key];
                }
                catch (e) {
                    // ccl
                }
            }
        }
        return null;
    };
    SxQueryObject.prototype.append = function (content) {
        this._addNode(content, true);
        return this;
    };
    SxQueryObject.prototype.appendIfNeeded = function (content) {
        if (this.length === 0 || content.length === 0) {
            return this;
        }
        if (!content.isMounted() || content.length > 1 || this.length > 1 || this.get(0).lastElementChild !== content.get(0)) {
            return this.append(content);
        }
        return this;
    };
    SxQueryObject.prototype.prepend = function (content) {
        this._addNode(content, false);
        return this;
    };
    SxQueryObject.prototype.parent = function () {
        var result = [];
        this._it(function (elem) {
            if (elem.parentNode !== null) {
                result.push(elem.parentNode);
            }
        });
        return _construct(result);
    };
    SxQueryObject.prototype.parents = function (querySelector, returnSxQueryInstance) {
        if (returnSxQueryInstance === void 0) { returnSxQueryInstance = false; }
        var result = [];
        this._it(function (elem) {
            var parent = elem.parentNode;
            while (parent && canMatch(parent)) {
                if (querySelector === undefined || match(parent, querySelector)) {
                    result.push(parent);
                }
                parent = parent.parentNode;
            }
        });
        return returnSxQueryInstance === true ? _construct(result) : result;
    };
    SxQueryObject.prototype.prev = function () {
        var result = [];
        this._it(function (elem) {
            result.push(elem.previousElementSibling);
        });
        return _construct(result);
    };
    SxQueryObject.prototype.next = function () {
        var result = [];
        this._it(function (elem) {
            result.push(elem.nextElementSibling);
        });
        return _construct(result);
    };
    SxQueryObject.prototype.closest = function (querySelector) {
        var result = [];
        this._it(function (elem) {
            var tested = elem;
            while (tested && canMatch(tested) && !match(tested, querySelector)) {
                tested = tested.parentNode;
            }
            if (!canMatch(tested)) {
                tested = undefined;
            }
            result.push(tested);
        });
        return _construct(result);
    };
    SxQueryObject.prototype.index = function (htmlNode) {
        var _elems = this._elems;
        for (var i = 0; i < _elems.length; i++) {
            if (_elems[i] === htmlNode) {
                return i;
            }
        }
        return -1;
    };
    SxQueryObject.prototype.offset = function () {
        var _elems = this._elems;
        if (_elems.length > 0) {
            var elem = _elems[0];
            if (!elem.getClientRects().length) {
                return {
                    top: 0,
                    left: 0
                };
            }
            var rect = elem.getBoundingClientRect();
            var win = elem.ownerDocument.defaultView;
            return {
                top: rect.top + win.pageYOffset,
                left: rect.left + win.pageXOffset
            };
        }
        return {
            top: undefined,
            left: undefined
        };
    };
    SxQueryObject.prototype.outerWidth = function () {
        var _elems = this._elems;
        if (_elems.length > 0) {
            return _elems[0].offsetWidth;
        }
        return undefined;
    };
    SxQueryObject.prototype.outerHeight = function () {
        var _elems = this._elems;
        if (_elems.length > 0) {
            return _elems[0].offsetHeight;
        }
        return undefined;
    };
    SxQueryObject.prototype.width = function (val) {
        if (val !== null && val !== undefined) {
            if (val.toString().indexOf('px') === -1 && val.toString().indexOf('%') === -1 && val !== 'auto') {
                val = "".concat(val.toString(), "px");
            }
            this.css('width', val);
            return this;
        }
        // @ts-ignore
        return parseFloat(this.css('width'));
    };
    SxQueryObject.prototype.height = function (val) {
        if (val !== null && val !== undefined) {
            if (val.toString().indexOf('px') === -1 && val.toString().indexOf('%') === -1 && val !== 'auto') {
                val = "".concat(val.toString(), "px");
            }
            this.css('height', val);
            return this;
        }
        // @ts-ignore
        return parseFloat(this.css('height'));
    };
    SxQueryObject.prototype.html = function (data) {
        if (data !== undefined) {
            this.empty();
            if ((typeof data) === 'string' && data.length > 0 && SxQueryShared.parseHTML(data).length === 0) { // we cannot parse the HTML String --> probably a simple innerHTML String with escaped entities, just inject it
                this._it(function (el) {
                    el.innerHTML = data;
                });
            }
            else {
                this.append(data);
            }
            return this;
        }
        if (this._elems.length > 0) {
            return this._elems[0].innerHTML;
        }
        return undefined;
    };
    SxQueryObject.prototype.empty = function () {
        this._it(function (elem) {
            while (elem.firstChild) {
                elem.removeChild(elem.firstChild);
            }
        });
    };
    SxQueryObject.prototype.scrollTop = function (val) {
        if (val !== undefined) {
            this._it(function (elem) {
                if (elem.scrollTop !== undefined) {
                    elem.scrollTop = val;
                }
                else if (elem.scrollY !== undefined && elem.scrollTo !== undefined) {
                    elem.scrollTo(elem.scrollX, val);
                }
            });
            return this;
        }
        var _elems = this._elems;
        if (_elems.length > 0) {
            return _elems[0].scrollTop !== undefined ? _elems[0].scrollTop : _elems[0].scrollY;
        }
        return undefined;
    };
    SxQueryObject.prototype.scrollTopBy = function (val) {
        if (val === 0) {
            return;
        }
        this._it(function (elem) {
            if (elem.scrollTop !== undefined) {
                elem.scrollTop = elem.scrollTop + val;
            }
            else if (elem.scrollY !== undefined && elem.scrollTo !== undefined) {
                elem.scrollTo(elem.scrollX, elem.scrollY + val);
            }
        });
        return this;
    };
    SxQueryObject.prototype.ready = function (fn) {
        this._it(function (elem) {
            if (elem.attachEvent ? elem.readyState === 'complete' : elem.readyState !== 'loading') {
                fn();
            }
            else {
                elem.addEventListener('DOMContentLoaded', fn);
            }
        });
    };
    /**
     * ~ jQuery(elem).is(":visible");
     */
    SxQueryObject.prototype.isVisible = function () {
        var _elems = this._elems;
        if (_elems.length > 0) {
            return _construct(_elems[0]).css('display') !== 'none';
        }
        return false;
    };
    SxQueryObject.prototype.isMounted = function () {
        var _elems = this._elems;
        return _elems.length !== 0 && _construct(_elems[0]).parents('body', true).length > 0;
    };
    SxQueryObject.prototype.map = function (callback) {
        var result = [];
        var i = 0;
        this._it(function (elem) {
            result.push(callback(i, elem));
            i++;
        });
        return result;
    };
    SxQueryObject.prototype._fade = function (out, duration, callback) {
        var factor = out ? -1 : 1;
        var setter = function (el, val) {
            if (el === undefined || val === undefined) {
                return;
            }
            el.style.opacity = factor > 0 ? 1 - val : val;
        };
        var completeCallback = function (el) {
            var $el = _construct(el);
            $el.css('opacity', '');
            if (out) {
                $el.hide();
            }
            if (callback && typeof callback === 'function') {
                callback.bind($el).call();
            }
        };
        this._it(function (el) {
            SxQueryShared._clearAnimation(el, 'fade');
            if (duration !== 0 && !SxQueryShared.prefersReducedMotion()) {
                _animate(el, 'fade', duration || 400, 0.5, setter, completeCallback);
            }
            else {
                completeCallback(el);
            }
        });
    };
    SxQueryObject.prototype.fadeIn = function (duration, callback, displayType) {
        if (displayType === undefined || displayType !== 'flex') {
            this.css('display', 'block');
        }
        else {
            this._it(function (el) {
                var $el = _construct(el);
                // @ts-ignore
                var style = $el.attr('style');
                if (style.length > 0 && style[style.length - 1] !== ';') {
                    style += ';';
                }
                style += 'display: -ms-flexbox;display: -webkit-flex;display: flex;';
                $el.attr('style', style);
            });
        }
        this._it(function (el) {
            el.style.opacity = 0;
        });
        this._fade(false, duration, callback);
    };
    SxQueryObject.prototype.fadeOut = function (duration, callback) {
        this._it(function (el) {
            el.style.opacity = 1;
        });
        this._fade(true, duration, callback);
    };
    SxQueryObject.prototype._slide = function (up, duration, callback) {
        var completeCallback = function (el) {
            var $el = _construct(el);
            $el.css('height', '');
            if (up) {
                $el.hide();
            }
            if (callback && typeof callback === 'function') {
                callback.bind($el).call();
            }
        }; // .bind(this, up, callback);
        var setter = function (height, el, val) {
            if (el === undefined || val === undefined) {
                return;
            }
            var nHeight = up ? val : height - val;
            el.style.height = "".concat(nHeight, "px");
        };
        this._it(function (el) {
            SxQueryShared._clearAnimation(el, 'slide');
            if (duration !== 0 && !SxQueryShared.prefersReducedMotion()) {
                var $el = _construct(el);
                var height_1 = $el.outerHeight();
                $el.css('height', 0);
                var cosParam = height_1 / 2;
                _animate(el, 'slide', duration || 400, cosParam, function (element, val) {
                    setter(height_1, element, val);
                }, completeCallback);
            }
            else {
                completeCallback(el);
            }
        });
    };
    SxQueryObject.prototype.slideDown = function (duration, callback, display) {
        var allowedDisplayTypes = ['grid', 'flex', 'block', 'inline-block'];
        this.css('display', display !== undefined && allowedDisplayTypes.indexOf(display) !== -1 ? display : 'block');
        this._slide(false, duration, callback);
    };
    SxQueryObject.prototype.slideUp = function (duration, callback) {
        this._it(function (el) {
            var $el = _construct(el);
            $el.css('height', $el.outerHeight());
        });
        this._slide(true, duration, callback);
    };
    SxQueryObject.prototype._animateScroll = function (target, duration, property, callback) {
        duration = duration || 400;
        var setter = function (startVal, el, newVal) {
            if (target >= startVal) {
                el[property] = startVal + (Math.abs(startVal - target) - newVal);
            }
            else {
                el[property] = target + newVal;
            }
        };
        this._it(function (el) {
            var startVal = el[property];
            var range = Math.abs(startVal - target);
            SxQueryShared._clearAnimation(el, property);
            if (range < 1 || duration === 0 || SxQueryShared.prefersReducedMotion()) {
                el[property] = target;
                return;
            }
            var cosParameter = range / 2;
            _animate(el, property, duration || 400, cosParameter, setter.bind(this, startVal), callback);
        });
    };
    SxQueryObject.prototype.animateScrollTop = function (target, duration, callback) {
        this._animateScroll(target, duration, 'scrollTop', callback);
    };
    SxQueryObject.prototype.animateScrollLeft = function (target, duration, callback) {
        this._animateScroll(target, duration, 'scrollLeft', callback);
    };
    SxQueryObject.prototype.animateTop = function (target, duration) {
        var completeCallback = function (el) {
            _construct(el).css('top', target);
        };
        var setter = function (startVal, trgt, el, nVal) {
            var val;
            if (trgt >= startVal) {
                val = nVal;
                if (startVal < 0) {
                    val *= -1;
                }
            }
            else if (trgt < 0) {
                val = trgt + nVal;
            }
            else {
                val = nVal + startVal;
            }
            _construct(el).css('top', "".concat(val, "px"));
        };
        this._it(function (el) {
            SxQueryShared._clearAnimation(el, 'positionTop');
            if (duration === 0 || SxQueryShared.prefersReducedMotion()) {
                completeCallback(el);
                return;
            }
            var $el = _construct(el);
            // @ts-ignore
            var crnt = parseFloat($el.css('top'));
            var isInPercent = target.toString().indexOf('%') !== 0;
            var trgt;
            if (isInPercent) {
                var parentHeight = void 0;
                var position = $el.css('position');
                if (position === 'fixed') {
                    parentHeight = window.innerHeight;
                }
                else {
                    // @ts-ignore
                    parentHeight = parseFloat($el.parent().css('height'));
                }
                // @ts-ignore
                var multiplier = parseFloat(target) / 100;
                trgt = multiplier * parentHeight;
            }
            else {
                // @ts-ignore
                trgt = parseFloat(target);
            }
            var range = Math.abs(crnt - trgt);
            var cosParameter = range / 2;
            _animate(el, 'positionTop', duration || 400, cosParameter, setter.bind(this, crnt, trgt), completeCallback);
        });
    };
    SxQueryObject.prototype.highlight = function (pat, className) {
        var innerHighlight = function (node, innerPattern) {
            if (_construct(node).parents(".".concat(className)).length !== 0) {
                return 1;
            }
            var skip = 0;
            if (node.nodeType === 3) {
                var pos = node.data.toUpperCase().indexOf(innerPattern);
                pos -= (node.data.substr(0, pos).toUpperCase().length - node.data.substr(0, pos).length);
                if (pos >= 0) {
                    var spannode = document.createElement('span');
                    spannode.className = className;
                    var middlebit = node.splitText(pos);
                    middlebit.splitText(innerPattern.length); //  don't delete this line!
                    var middleclone = middlebit.cloneNode(true);
                    spannode.appendChild(middleclone);
                    middlebit.parentNode.replaceChild(spannode, middlebit);
                    skip = 1;
                }
            }
            else if (node.nodeType === 1 && node.childNodes && !/(script|style)/i.test(node.tagName)) {
                for (var i = 0; i < node.childNodes.length; ++i) {
                    i += innerHighlight(node.childNodes[i], innerPattern);
                }
            }
            return skip;
        };
        if (this.length && pat && pat.length && pat.length > 2) {
            this._it(function (elem) {
                innerHighlight(elem, StringHelper.unescapeHtml(pat.toUpperCase()));
            });
        }
        return this;
    };
    SxQueryObject.prototype.onScrolledIntoViewport = function (callback) {
        if (this.length === 0) {
            return;
        }
        if ('IntersectionObserver' in window) {
            var observer_1 = new IntersectionObserver(function (entries) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        callback(entry);
                        observer_1.unobserve(entry.target);
                    }
                });
            }, {
                rootMargin: "0px"
            });
            this._it(function (node) {
                observer_1.observe(node);
            });
        }
        else {
            var active_1 = false;
            var $document_1 = _construct(document);
            var $window_1 = _construct(window);
            var eventPostfix_1 = Math.round(Math.random() * 100000).toString();
            var removeHandlers_1 = function () {
                $document_1.off("scroll.".concat(eventPostfix_1));
                $window_1.off("resize.".concat(eventPostfix_1));
                $window_1.off("scroll.".concat(eventPostfix_1));
                $window_1.off("orientationchange.".concat(eventPostfix_1));
            };
            var nodes_1 = [];
            this._it(function (node) {
                nodes_1.push(node);
            });
            var check = function () {
                if (active_1 === false) {
                    active_1 = true;
                    setTimeout(function () {
                        nodes_1.forEach(function (node) {
                            if ((node.getBoundingClientRect().top <= window.innerHeight && node.getBoundingClientRect().bottom >= 0) && getComputedStyle(node).display !== 'none') {
                                callback({
                                    target: node
                                });
                                nodes_1 = nodes_1.filter(function (image) { return image !== node; });
                                if (nodes_1.length === 0) {
                                    removeHandlers_1();
                                }
                            }
                        });
                        active_1 = false;
                    }, 200);
                }
            };
            removeHandlers_1();
            $document_1.on("scroll.".concat(eventPostfix_1), undefined, check, { passive: true });
            $window_1.on("scroll.".concat(eventPostfix_1), check);
            $window_1.on("resize.".concat(eventPostfix_1), check);
            $window_1.on("orientationchange.".concat(eventPostfix_1), check);
            check();
        }
    };
    SxQueryObject.prototype.ensureChildPosition = function (node, position) {
        var nodeList = this.childrenArr();
        if (node === nodeList[position]) {
            return;
        }
        if (nodeList[position + 1] !== undefined) {
            this.get(0).insertBefore(node, nodeList[position + 1]);
        }
        else {
            this.append(node);
        }
    };
    SxQueryObject.prototype.clone = function () {
        var arr = [];
        this._it(function (el) {
            arr.push(el.cloneNode(true));
        });
        return _construct(arr);
    };
    return SxQueryObject;
}());
export default SxQueryObject;
export function _constructz(data, preventCreatingElement) {
    if (preventCreatingElement === void 0) { preventCreatingElement = false; }
    return _construct(data, preventCreatingElement);
}
export function querySelectorAllz(data, node) {
    return querySelectorAll(data, node);
}

/* eslint no-restricted-globals: 0 */
'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import Logger from '../../global/Logger';
import Helper from './Helper';
var hasHistoryAPI = function (results) { return 'history' in window && (typeof history.pushState !== 'undefined') && results.allowHistoryApi; };
var encodeParam = function (val, pluginConfiguration) {
    if (pluginConfiguration.results.nameParsing && pluginConfiguration.filters.enabled) {
        return val.replace(/#/g, '%23').replace(/&/g, '%26').replace(/\?/g, '%3F');
    }
    return encodeURIComponent(val);
};
var createQueryString = function (queryDict, pluginConfiguration) {
    if (Object.keys(queryDict).length === 0) {
        return '';
    }
    return "?".concat(Object.keys(queryDict).reduce(function (acc, key) { return "".concat(acc, "&").concat(encodeParam(key, pluginConfiguration), "=").concat(encodeParam(queryDict[key], pluginConfiguration)); }, '').substring(1));
};
var buildState = function (queryDict, pluginConfiguration) {
    var searchString = createQueryString(queryDict, pluginConfiguration);
    var newUrl = window.location.href.split('?')[0].replace(window.location.hash, '').replace(/#/, '') + searchString + window.location.hash;
    var obj = {
        Page: document.title,
        Url: newUrl
    };
    return obj;
};
var createQueryDict = function () { return window.location.search.substring(1).split('&').reduce(function (acc, item) {
    if (item && item.split && item.split('=').length === 2) {
        acc[decodeURIComponent(item.split('=')[0])] = decodeURIComponent(item.split('=')[1]);
    }
    return acc;
}, {}); };
var createQueryDictFromString = function (str) {
    var queryDict = {};
    if (str.indexOf('?') !== -1) {
        str.substring(str.indexOf('?') + 1).split('&').forEach(function (entry) {
            var kv = entry.split('=');
            queryDict[decodeURIComponent(kv[0])] = decodeURIComponent(kv[1]);
        });
    }
    return queryDict;
};
var buildSemanticPathPart = function (query, results) {
    var spaceCharacter = results.semanticModeSpaceCharacter;
    var escapedQuery = encodeURIComponent(query.split(spaceCharacter).join('~').replace(/ /g, spaceCharacter));
    escapedQuery = escapedQuery.split(encodeURIComponent(spaceCharacter)).join(spaceCharacter); // don't encode the space character, if you're dumb enough to set dumb char, just gtfo
    return results.semanticModeParamName + escapedQuery;
};
var pushSemanticState = function (query, queryDict, pluginConfiguration) {
    var results = pluginConfiguration.results;
    var newSemanticPathPart = buildSemanticPathPart(query, results);
    var baseUrl = (results.embedConfig || {}).url !== undefined ? results.embedConfig.url : '/';
    if (newSemanticPathPart.indexOf('?') !== -1) {
        var semanticPartQueryDict_1 = createQueryDictFromString(newSemanticPathPart);
        if (Object.keys(semanticPartQueryDict_1).length > 0) {
            Object.keys(semanticPartQueryDict_1).forEach(function (key) {
                queryDict[key] = semanticPartQueryDict_1[key];
            });
            newSemanticPathPart = newSemanticPathPart.split('?')[0];
        }
    }
    var spaceCharacter = results.semanticModeSpaceCharacter;
    var queryPart = createQueryString(queryDict, pluginConfiguration).split(encodeURIComponent(spaceCharacter)).join(spaceCharacter);
    window.history.pushState(null, null, "".concat(baseUrl).concat(newSemanticPathPart).concat(queryPart));
};
var getSemanticModeQuery = function (embedUrl, semanticModeParamName, results) {
    if (embedUrl === undefined) {
        embedUrl = (results.embedConfig || {}).url;
    }
    if (embedUrl === undefined) {
        embedUrl = '/';
    }
    if (semanticModeParamName === undefined) {
        semanticModeParamName = results.semanticModeParamName;
    }
    var currentLocation = window.location.href;
    if (currentLocation[currentLocation.length - 1] === '/') {
        currentLocation = currentLocation.substring(0, currentLocation.length - 1);
    }
    else if (!results.semanticModeKeepTrailingSlash && currentLocation.indexOf('/?') !== -1) {
        currentLocation = currentLocation.replace('/?', '?');
    }
    if (embedUrl === '/') {
        currentLocation = currentLocation.replace("".concat(window.location.protocol, "//"), '').replace(window.location.host, '');
    }
    var embedIdx = currentLocation.indexOf(embedUrl);
    if (embedIdx === -1) {
        return undefined;
    }
    if (currentLocation.indexOf(semanticModeParamName) === -1) {
        return undefined;
    }
    var queryPart = currentLocation.substring(embedIdx + embedUrl.length);
    if (queryPart.indexOf('?') !== -1 && semanticModeParamName.indexOf('?') === -1) {
        queryPart = queryPart.substring(0, queryPart.indexOf('?'));
    }
    else if (queryPart.indexOf('?') !== -1 && semanticModeParamName.indexOf('?') !== -1) {
        var queryDict_1 = createQueryDict();
        var semanticModeQueryDict = createQueryDictFromString(semanticModeParamName);
        var queryEntry = Object.keys(semanticModeQueryDict).reduce(function (acc, key) {
            if (acc !== undefined) {
                return acc;
            }
            if (queryDict_1[key] !== undefined) {
                return "".concat(key, "=").concat(queryDict_1[key]);
            }
            return undefined;
        }, undefined);
        if (queryEntry !== undefined) {
            queryPart = queryPart.substring(0, queryPart.indexOf('?'));
            queryPart = "".concat(queryPart, "?").concat(queryEntry);
        }
    }
    if (queryPart.length > 0) {
        var spaceCharacter = results.semanticModeSpaceCharacter;
        return decodeURIComponent(queryPart.replace(semanticModeParamName, '').split(spaceCharacter).join(encodeURIComponent(spaceCharacter)))
            .split(spaceCharacter).join(' ')
            .replace(/~/g, spaceCharacter);
    }
    return undefined;
};
var escapeQueryParam = function (val) { return "".concat(val).toLowerCase().replace(/_/g, '__').replace(/ /g, '_').replace(/%/g, '%25')
    .replace(/\//g, '%2F')
    .replace(/,/g, ';;')
    .replace(/&/g, '%26')
    .replace(/\|/g, '%7C'); };
var unescapeQueryParam = function (val) { return val.replace(/#/g, '.IAMHASH.').replace(/__/g, '###').replace(/_/g, ' ').replace(/###/g, '_').replace(/%25/g, '%').replace(/\.IAMHASH\./g, '#')
    .replace(/%2F/g, '/')
    .replace(/;;/g, ',')
    .replace(/%26/g, '&')
    .replace(/%7C/g, '|'); };
var replaceState = function (queryDict) {
    if (window.history.replaceState) {
        var searchPath = Object.keys(queryDict).reduce(function (acc, qpName) { return "".concat(acc, "&").concat(encodeURIComponent(qpName), "=").concat(encodeURIComponent(queryDict[qpName])); }, '');
        if (searchPath.length > 0) {
            searchPath = "?".concat(searchPath.substring(1));
        }
        window.history.replaceState({}, document.title, document.location.pathname + searchPath + window.location.hash);
    }
};
var NavigatorHelper = {
    removeQueryParam: function (results, paramName) {
        paramName = paramName === undefined ? results.searchQueryParamName : paramName;
        try {
            if (window.history.replaceState && document.location.search.indexOf("".concat(paramName, "=")) > -1 && hasHistoryAPI(results)) {
                var queryDict = createQueryDict();
                if (paramName in queryDict) {
                    delete queryDict[paramName];
                    replaceState(queryDict);
                }
            }
        }
        catch (e) {
            // ccl
        }
    },
    buildQueryDict: function () {
        return createQueryDict();
    },
    hasHistoryAPI: function (results) {
        return hasHistoryAPI(results);
    },
    pushState: function (queryDict, pluginConfiguration, landingPageOptions) {
        if (!hasHistoryAPI(pluginConfiguration.results)) {
            return undefined;
        }
        var obj = buildState(queryDict, pluginConfiguration);
        if (landingPageOptions !== undefined) {
            obj.query = landingPageOptions.query;
            obj.requestOptions = landingPageOptions.requestOptions;
        }
        try {
            history.pushState(obj, obj.Page, obj.Url);
        }
        catch (ex) {
            Logger.warn(ex);
        }
        return obj.Url;
    },
    replaceState: function (queryDict, pluginConfiguration) {
        if (!hasHistoryAPI(pluginConfiguration.results)) {
            return;
        }
        var obj = buildState(queryDict, pluginConfiguration);
        try {
            history.replaceState(obj, obj.Page, obj.Url);
        }
        catch (ex) {
            Logger.warn(ex);
        }
    },
    redirectToSearchResultPage: function (selectedText, redirectUrl, pluginConfiguration, context) {
        var results = pluginConfiguration.results, allowCookies = pluginConfiguration.allowCookies;
        var paramName = results.searchQueryParamName;
        if (allowCookies) {
            context.createCookie('LastQuery', selectedText, 1);
        }
        if (results.semanticMode) {
            document.location.href = redirectUrl + buildSemanticPathPart(selectedText, pluginConfiguration.results);
        }
        else {
            var queryDict_2 = createQueryDictFromString(redirectUrl);
            delete queryDict_2[paramName];
            queryDict_2[paramName] = selectedText;
            redirectUrl = redirectUrl.split('?')[0];
            redirectUrl = "".concat(redirectUrl, "?").concat(Object.keys(queryDict_2).map(function (key) { return "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(queryDict_2[key])); }).join('&'));
            document.location.href = redirectUrl;
        }
    },
    handleRedirect: function (redirect, selectedText, escapedQuery, context) {
        var pluginConfiguration = context.pluginConfiguration;
        var _a = pluginConfiguration.queryTerm, highlight = _a.highlight, highlightOnRedirect = _a.highlightOnRedirect, scrollOnRedirect = _a.scrollOnRedirect;
        var redirectCallback = pluginConfiguration.callbacks.redirect;
        var hashPart = '';
        if (redirect.indexOf('#') !== -1) {
            var urlParts = redirect.split('#');
            redirect = urlParts[0];
            hashPart = "#".concat(urlParts[1]);
        }
        if (highlight && highlightOnRedirect) {
            if (redirect.indexOf('?') > -1) {
                redirect += '&';
            }
            else {
                redirect += '?';
            }
            var term = context.getIsZoovu() ? 'zSearchTerm' : 'ss360SearchTerm';
            var sourceParamName = context.getIsZoovu() ? 'zSource' : 'ss360Source';
            var sourceParam = scrollOnRedirect ? '' : "&".concat(sourceParamName, "=redirect");
            redirect += "".concat(term, "=").concat(escapedQuery).concat(sourceParam);
        }
        redirect = "".concat(redirect).concat(hashPart);
        // TODO: #scoped event name
        sxQuery(window).off('beforeunload.ss360Insights');
        if (context.hasInsights()) {
            context.getInsights().trackSerpClick(selectedText, undefined, undefined, undefined, undefined, undefined, redirect, 'redirect');
        }
        if (redirectCallback !== undefined && typeof redirectCallback === 'function') {
            redirectCallback.call(this, redirect);
        }
        else {
            window.location.href = redirect;
        }
    },
    getFirstResult: function (data) {
        var firstKey = data.getResultGroupNames()[0];
        return (data.getSuggestsArray(firstKey) || [])[0];
    },
    getFirstLink: function (data) {
        var firstResult = NavigatorHelper.getFirstResult(data);
        return firstResult !== undefined ? firstResult.getLink() : undefined;
    },
    redirectToFirst: function (data) {
        window.location.href = NavigatorHelper.getFirstLink(data);
    },
    getSemanticModeQuery: getSemanticModeQuery,
    pushSemanticState: pushSemanticState,
    getFilters: function (results, queryDict, ignoredQueryParams) {
        if (queryDict === undefined) {
            queryDict = createQueryDict();
        }
        if (ignoredQueryParams === undefined) {
            ignoredQueryParams = results.ignoredFilterQueryParams || [];
        }
        var result;
        if (queryDict['ss360Filter'] !== undefined && !results.nameParsing) {
            try {
                result = JSON.parse(queryDict['ss360Filter']);
            }
            catch (ex) {
                // ccl
            }
        }
        else if (results.nameParsing) {
            var filterKeys = Object.keys(queryDict).filter(function (key) { return key !== 'ss360Filter'
                && key !== results.searchQueryParamName && key !== 'ss360SearchTerm' && key !== 'zSearchTerm' && ignoredQueryParams.indexOf(key) === -1
                && key !== escapeQueryParam(results.sortingParamName) && key !== 'ss360-qa-flow'; });
            result = filterKeys.map(function (key) {
                var val = queryDict[key];
                var filterVal;
                var minMax = val.indexOf('-') !== -1 && val.split('-').length === 2 && val.indexOf(',') === -1 ? val.split('-') : undefined;
                if (minMax !== undefined && minMax.length === 2 && minMax[1] !== undefined) { // remove unit
                    minMax[1] = minMax[1].split('~')[0];
                }
                var min = minMax !== undefined && !Helper.isNaN(parseFloat(minMax[0])) ? parseFloat(minMax[0]) : undefined;
                var max = minMax !== undefined && !Helper.isNaN(parseFloat(minMax[1])) ? parseFloat(minMax[1]) : undefined;
                if (min !== undefined && max !== undefined) {
                    filterVal = {
                        min: min,
                        max: max
                    };
                }
                else if (val === '~true') {
                    filterVal = {
                        booleanValue: true
                    };
                }
                else if (val === '~false') {
                    filterVal = {
                        booleanValue: false
                    };
                }
                else {
                    filterVal = val.split(',').map(function (part) { return unescapeQueryParam(part); });
                    filterVal = {
                        values: filterVal.map(function (singleVal) { return ({ value: singleVal }); })
                    };
                }
                if (filterVal === undefined)
                    return undefined;
                var obj = {};
                obj = filterVal;
                obj.name = unescapeQueryParam(key);
                return obj;
            }).filter(function (param) { return param !== undefined; });
        }
        return result;
    },
    escapeQueryParam: escapeQueryParam,
    unescapeQueryParam: unescapeQueryParam,
    createQueryDictFromString: createQueryDictFromString,
    addQueryParam: function (key, value) {
        var queryDict = createQueryDict();
        if (queryDict[key] === value) {
            return;
        }
        queryDict[key] = value;
        replaceState(queryDict);
    }
};
export default NavigatorHelper;

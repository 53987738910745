'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import NavigatorHelper from '../utils/NavigatorHelper';
import Logger from '../../global/Logger';
import SearchResultType from '../enums/SearchResultType';
var Handlers = {
    onSearchButtonClick: function (event, unibox, callbacks) {
        try {
            var searchQuery = unibox.getText();
            var searchButton = sxQuery(event.target).get()[0];
            if ((searchButton.tagName || '').toLowerCase() === 'svg' && sxQuery(searchButton).parent().hasClass('ss360-custom-search__searchbutton')) {
                searchButton = sxQuery(searchButton).parent().get()[0];
            }
            // does the button reference a search field it should take the query from?
            var selectedSearchBox = void 0;
            var searchBoxReference = searchButton.getAttribute('ss360-search-box-id');
            if (searchBoxReference !== null && searchBoxReference !== undefined) {
                selectedSearchBox = sxQuery("#".concat(searchBoxReference));
            }
            if (selectedSearchBox !== undefined && selectedSearchBox.length > 0) {
                // @ts-ignore
                searchQuery = selectedSearchBox.val();
            }
            var enterCallback = callbacks.enter;
            if (enterCallback !== undefined) {
                enterCallback.call(searchButton, searchQuery, {
                    shouldPushState: true,
                    searchButton: searchButton,
                    sbRef: selectedSearchBox !== undefined && selectedSearchBox.length > 0 ? selectedSearchBox : undefined
                });
            }
        }
        catch (ex) {
            Logger.warn(ex);
        }
        event.preventDefault();
        event.stopPropagation();
    },
    onBodyKeyDown: function (e, areResultsVisible, fullScreenOpen, searchResultType, context) {
        if (e.keyCode === 27 || e.which === 27 || e.key === 'Escape' || e.code === 'Escape') {
            if (areResultsVisible || fullScreenOpen) {
                e.preventDefault();
                e.stopPropagation();
            }
            if ((searchResultType === SearchResultType.Fullscreen && fullScreenOpen) || searchResultType === SearchResultType.Layover) {
                context.core.closeLayer();
            }
        }
    },
    onBodyMouseDown: function (e, areResultsVisible, context) {
        if (e.which !== 1 || e.buttons !== 1)
            return; // only lmb
        if (!context.pluginConfiguration.results.hideLayerOnBodyClick || !areResultsVisible) {
            return;
        }
        var searchBoxSelector = context.pluginConfiguration.searchBox.selector;
        if (searchBoxSelector && sxQuery(searchBoxSelector).is(e.target)) { // don't hide on search box focus
            return;
        }
        var $target = sxQuery(e.target);
        if ($target.attr('id') === 'unibox-mobile-search-btn') { // don't hide on mobile suggestions submit
            return;
        }
        context.setIsClosing(true);
    },
    onLayerMouseUp: function (context) {
        context.setIsClosing(false);
    },
    onBodyMouseUp: function (context) {
        if (context.isClosing) {
            context.core.closeLayer();
        }
        context.setIsClosing(false);
    },
    popstate: function (e, searchResultType, cachedDomElement, context) {
        var pluginConfig = context.pluginConfiguration;
        var searchBoxSelector = pluginConfig.searchBox.selector;
        var qparam = pluginConfig.results.searchQueryParamName;
        var stateUrl = e.state !== undefined && e.state !== null && e.state.Url ? e.state.Url : window.location.href;
        var hasQuery = false;
        var semanticModeQuery;
        if (pluginConfig.results.semanticMode) {
            var embedConfig = pluginConfig.results.embedConfig || { contentBlock: undefined };
            semanticModeQuery = NavigatorHelper.getSemanticModeQuery(embedConfig.contentBlock, pluginConfig.results.semanticModeParamName, pluginConfig.results);
            hasQuery = semanticModeQuery !== undefined;
        }
        else {
            hasQuery = qparam && stateUrl.indexOf("".concat(qparam, "=")) !== -1;
        }
        var isLayover = searchResultType === 'layover';
        if (!hasQuery && isLayover) {
            context.core.closeLayer();
        }
        else if (!hasQuery) {
            context.getExistingLayer().fadeOut();
            if (cachedDomElement !== undefined) {
                var $contentBlock = sxQuery(pluginConfig.results.embedConfig.contentBlock, true);
                $contentBlock.append(cachedDomElement);
            }
        }
        else if (hasQuery) {
            var queryDict = NavigatorHelper.buildQueryDict();
            var searchQuery = pluginConfig.results.semanticMode ? semanticModeQuery : queryDict[qparam];
            var filters = NavigatorHelper.getFilters(pluginConfig.results, queryDict);
            context.core.showResults(searchQuery, {
                filters: filters,
                shouldPushState: false,
                submitSource: 'popstate'
            });
            context.getExistingCustomSearchBox().val(searchQuery);
            sxQuery(searchBoxSelector).val(searchQuery);
        }
        context.getExistingCustomSearchBox().val('');
        sxQuery(searchBoxSelector).val('');
        return hasQuery;
    },
    searchBoxBlur: function (event, selectedText, logAbandon, autoBlurTime, lbctGetter, context) {
        sxQuery('#ss360Darken-input').remove();
        if (!logAbandon || (new Date().getTime() - autoBlurTime <= 200)) {
            return;
        }
        var delay = 200;
        var searchButtonSelector = context.pluginConfiguration.searchBox.searchButton;
        // was search button clicked? increase the log delay
        if (event.relatedTarget && searchButtonSelector !== undefined) {
            var searchButtons = sxQuery(searchButtonSelector, true).get();
            var relatedSb = searchButtons.filter(function (sb) { return sb === event.relatedTarget; });
            if (relatedSb.length > 0) {
                delay = 1000;
            }
        }
        // delay logging
        var startTs = new Date().getTime();
        setTimeout(function () {
            var lbct = lbctGetter();
            if ((lbct !== -1)
                && (startTs < lbct && startTs + delay > lbct)) { // check whether search button was clicked in recent time
                return;
            }
            if (context.hasInsights()) {
                context.getInsights().trackSearchBoxAbandon(selectedText, sxQuery("#".concat(context.uniboxId, " .unibox-selectable")).length, event.target);
            }
        }, delay);
    },
    layoverResize: function (layer) {
        // update layover height to fixed value - ie11 fix
        var lCs = layer.find('.ss360-layer__content');
        lCs.each(function (elem) {
            var lC = sxQuery(elem);
            var bufferHeight = 0;
            var marginTop = 0;
            // const $banner = layer.find('.ss360-banner');
            // let bannerHeight = $banner.outerHeight() || 0;
            // if (bannerHeight > 0) {
            // 	// @ts-ignore
            // 	bannerHeight += parseInt($banner.css('marginBottom'), 10) + parseInt($banner.css('marginTop'), 10);
            // }
            var hasTopNavigation = layer.find('nav.ss360-nav--top').length > 0;
            var hasSearchField = layer.find('.ss360-custom-search').length > 0;
            var navigationHeight = layer.find('nav.ss360-nav--top').outerHeight() + (layer.find('.ss360-dropdown').length > 0 ? 24 : 0);
            if (hasTopNavigation && hasSearchField) {
                bufferHeight = 60 + navigationHeight + 10;
            }
            else if (hasTopNavigation) {
                bufferHeight = navigationHeight;
            }
            else if (hasSearchField) {
                bufferHeight = 65;
            }
            else {
                bufferHeight = 25;
                marginTop = 40;
            }
            var targetSize = lC.parents('.ss360-layer', true).height() - bufferHeight; // - bannerHeight;
            targetSize = Math.max(200, targetSize);
            if (targetSize) {
                lC.css('max-height', "".concat(targetSize, "px"));
            }
            if (marginTop) {
                lC.css('margin-top', "".concat(marginTop, "px"));
            }
        });
    },
    layoverScroll: function (e) {
        e.preventDefault();
        e.stopPropagation();
        // @ts-ignore
        e.target.scrollTop = 0;
    },
    queryCorrection: function (event, correctedQuery, pluginConfig) {
        try {
            pluginConfig.callbacks.enter(correctedQuery);
            sxQuery(pluginConfig.searchBox.selector).val(correctedQuery);
        }
        catch (ex) {
            Logger.warn(ex);
        }
        event.preventDefault();
        event.stopPropagation();
        return false;
    },
    queryOverride: function (event, correctedQuery, pluginConfig) {
        try {
            pluginConfig.callbacks.enter(correctedQuery, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true);
        }
        catch (ex) {
            Logger.warn(ex);
        }
        event.preventDefault();
        event.stopPropagation();
        return false;
    }
};
export default Handlers;

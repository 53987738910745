'use strict';
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Logger from '../../global/Logger';
import NavigationPosition from '../enums/NavigationPosition';
import SxQueryUtils from '../../sxQuery/SxQueryUtils';
import MediaQueryMatchType from '../../sxQuery/MediaQueryMatchType';
import LoaderType from '../enums/LoaderType';
import LayoutType from '../enums/LayoutType';
import DataPointDirection from '../enums/DataPointDirection';
import BlockEntryType from '../../unibox/enums/BlockEntryType';
import SuggestLayoutType from '../../unibox/enums/SuggestLayoutType';
import ImagePosition from '../../unibox/enums/ImagePosition';
import DefaultConfig from './DefaultConfig';
import NoResultsPageBlockType from '../enums/NoResultsPageBlockType';
import Helper from '../utils/Helper';
import SearchResultType from '../enums/SearchResultType';
var ConfigurationHelper = {
    getPart: function (object, key) {
        var parts = key.split('.');
        var target = object;
        for (var i = 0; i < parts.length; i++) {
            target = target[parts[i]];
            if (!target) {
                break;
            }
        }
        return target;
    },
    /**
     * Update the pluginSettings object
     *
     * @param {object} pluginSettings the object to be updated
     * @param {string} key the key of the target property
     * @param {*} value the value to be set
     */
    updateConfig: function (pluginSettings, key, value) {
        try {
            var parts = key.split('.');
            var propertyKey = parts.splice(parts.length - 1, 1)[0];
            var target = parts.length === 0 ? pluginSettings : ConfigurationHelper.getPart(pluginSettings, parts.join('.'));
            if (target !== undefined && target !== null) {
                target[propertyKey] = value;
            }
        }
        catch (err) {
            Logger.warn(err);
        }
    },
    assert: function (config, settings) {
        config = config || {};
        if (config.smart404 === undefined) {
            settings.smart404 = undefined;
        }
        settings.results.moreResultsPagingSize = settings.ecom || settings.ecomDev || settings.ecomStage ? settings.results.moreResultsPagingSize : Math.min(24, settings.results.moreResultsPagingSize);
        if (settings.results.sxMoreResultsPagingSize !== undefined) {
            settings.results.moreResultsPagingSize = settings.results.sxMoreResultsPagingSize;
        }
        // reverse for unibox
        settings.emptyQuerySuggests = settings.suggestions.emptyQuerySuggestions;
        if (settings.suggestions.show && settings.suggestions.num === 0) {
            settings.suggestions.show = false;
        }
        // don't allow left navigation on mobile devices
        if (settings.layout.navigation.position === NavigationPosition.Left && SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Max, 991)) {
            settings.layout.navigation.position = NavigationPosition.Top;
        }
        // auto skeleton loader only if explicitly set or defaultCss turned on
        if (settings.style.loaderType === LoaderType.Skeleton && !settings.style.defaultCss && (config.style || {}).loaderType !== LoaderType.Skeleton) {
            settings.style.loaderType = LoaderType.Circle;
        }
        var mobileLayoutType = settings.layout.mobile.type;
        var desktopLayoutType = settings.layout.desktop.type;
        if (mobileLayoutType === LayoutType.Mixed && (settings.layout.mobile.gridContentGroups || []).length === 0) {
            mobileLayoutType = LayoutType.List;
            settings.layout.mobile.type = LayoutType.List;
        }
        if (desktopLayoutType === LayoutType.Mixed && (settings.layout.desktop.gridContentGroups || []).length === 0) {
            desktopLayoutType = LayoutType.List;
            settings.layout.desktop.type = LayoutType.List;
        }
        if ((config.dataPoints === undefined || config.dataPoints.direction === undefined) && (mobileLayoutType === LayoutType.Grid || desktopLayoutType === LayoutType.Grid || mobileLayoutType === LayoutType.Mixed || desktopLayoutType === LayoutType.Mixed)) {
            settings.dataPoints.direction = DataPointDirection.Column;
        }
        if (config.results !== undefined && config.results.collapseDataPoints !== undefined && (config.dataPoints === undefined || config.dataPoints.collapseBy === undefined)) {
            settings.dataPoints.collapseBy = config.results.collapseDataPoints;
        }
        if (config.accessibility !== undefined && config.accessibility.resultTopHeadingLevel !== undefined) {
            config.accessibility.resultTopHeadingLevel = Math.max(1, parseInt(config.accessibility.resultTopHeadingLevel, 10) || 2);
        }
        // ensure the suggestion configurator doesn't disable search suggestions
        var hasQueryBasedSuggestionBlocks = false;
        try {
            if (settings.suggestions !== undefined && settings.suggestions.source !== undefined) {
                if (settings.suggestions.source.emptyState !== undefined) {
                    var hasBlocks = (settings.suggestions.source.emptyState.content || []).reduce(function (acc, entry) {
                        return acc || entry.type !== BlockEntryType.Empty;
                    }, false);
                    if (!hasBlocks) {
                        settings.suggestions.source.emptyState = __assign({}, DefaultConfig.suggestions.source.emptyState);
                    }
                }
                if (settings.suggestions.source.queryBased !== undefined) {
                    hasQueryBasedSuggestionBlocks = (settings.suggestions.source.queryBased.content || []).reduce(function (acc, entry) {
                        return acc || entry.type !== BlockEntryType.Empty;
                    }, false);
                    if (!hasQueryBasedSuggestionBlocks) {
                        settings.suggestions.source.queryBased = __assign({}, DefaultConfig.suggestions.source.queryBased);
                    }
                }
            }
        }
        catch (ex) {
            // ccl
        }
        // map empty query suggestions to new format
        try {
            if (settings.emptyQuerySuggests !== undefined && settings.emptyQuerySuggests.suggests !== undefined
                && Object.keys(settings.emptyQuerySuggests.suggests).length > 0 && ((config.suggestions || {}).source || {}).emptyState === undefined) {
                var mappedSuggestions = Object.keys(settings.emptyQuerySuggests.suggests).reduce(function (acc, key) {
                    var suggests = settings.emptyQuerySuggests.suggests[key];
                    suggests.forEach(function (suggest) {
                        acc.push({
                            id: "dataSet_".concat(Math.round(Math.random() * 100000)),
                            image: suggest.image,
                            title: suggest.name,
                            link: suggest.link,
                            snippet: suggest.content,
                            contentGroup: key
                        });
                    });
                    return acc;
                }, []);
                settings.suggestions.source.emptyState = {
                    layoutType: 1,
                    content: [
                        {
                            type: BlockEntryType.DataSet,
                            content: mappedSuggestions
                        }
                    ]
                };
            }
        }
        catch (err) {
            // ccl
        }
        // fallbacks
        try {
            if (config.suggestions === undefined || config.suggestions.source === undefined || config.suggestions.source.queryBased === undefined || !hasQueryBasedSuggestionBlocks) {
                settings.suggestions.source.queryBased.content[0].layout = {
                    type: SuggestLayoutType.List,
                    imagePosition: settings.suggestions.showImages ? ImagePosition.Left : ImagePosition.None,
                    showTitle: true,
                    showSnippet: false,
                    showUrl: false,
                    cta: settings.suggestions.groupCta !== undefined && settings.suggestions.groupCta.show ? settings.suggestions.groupCta.label : undefined
                };
            }
        }
        catch (err) {
            // ccl
        }
        if (config.results !== undefined && config.results.noResultsText !== undefined) {
            settings.noResultsPage = {
                content: [{
                        type: NoResultsPageBlockType.CustomHtml,
                        value: config.results.noResultsText
                    }]
            };
        }
        else if (config.noResultsPage === undefined && settings.results !== undefined && Helper.getIntegrationType(settings.results) !== SearchResultType.Embed) {
            settings.noResultsPage.content = settings.noResultsPage.content.filter(function (entry) { return entry.type !== NoResultsPageBlockType.SearchBox; });
        }
        if (settings.userGroup !== undefined && settings.tracking !== undefined && settings.tracking.userGroup === undefined) {
            settings.tracking.userGroup = settings.userGroup;
        }
        if (settings.queryTag !== undefined && settings.tracking !== undefined && settings.tracking.queryTag === undefined) {
            settings.tracking.queryTag = settings.queryTag;
        }
        if (settings.results.placeholderImage === '_collapse') {
            settings.results.placeholderImage = null;
        }
    }
};
export default ConfigurationHelper;

'use strict';
import MediaQueryMatchType from '../../sxQuery/MediaQueryMatchType';
import sxQuery from '../../sxQuery/sxQuery';
import SxQueryUtils from '../../sxQuery/SxQueryUtils';
import LayoutType from '../enums/LayoutType';
var shouldApplyGrid = function (layoutConfig) { return 'msGridColumn' in document.documentElement.style
    && (layoutConfig.mobile.type === LayoutType.Grid || layoutConfig.desktop.type === LayoutType.Grid); };
export default {
    objectFit: function (node, backgroundSize) {
        if ('objectFit' in document.documentElement.style) {
            return;
        }
        sxQuery(node).get().forEach(function (el) {
            var $el = sxQuery(el);
            var $imgEl = $el.find('img');
            var imageSource = $imgEl.attr('src');
            if (imageSource === null && $imgEl.hasClass('ss360-suggests__image--lazy')) {
                return;
            }
            if (imageSource === undefined) {
                return;
            }
            $imgEl.hide();
            $el.addClass('ss360-suggests__image-wrap--object-fit-polyfill');
            if (backgroundSize !== undefined) {
                el.style.backgroundSize = backgroundSize;
            }
            el.style.backgroundImage = "url(".concat(imageSource, ")");
            el.style.backgroundPosition = 'center center';
        });
    },
    shouldApplyGrid: shouldApplyGrid,
    grid: function (context) {
        var layoutConfig = context.pluginConfiguration.layout;
        if (shouldApplyGrid(layoutConfig)) {
            var breakpoints = [767, 991, 1200, 999999];
            var activeBreakpointIdx = 0;
            for (activeBreakpointIdx = 0; activeBreakpointIdx < breakpoints.length; activeBreakpointIdx++) {
                if (SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Max, breakpoints[activeBreakpointIdx])) {
                    break;
                }
            }
            if (activeBreakpointIdx < 2 && layoutConfig.mobile.type !== 'grid') {
                return; // no need to apply
            }
            if (activeBreakpointIdx > 1 && layoutConfig.desktop.type !== 'grid') {
                return; // no need to apply
            }
            var columnCount_1;
            if (activeBreakpointIdx === 0) {
                columnCount_1 = layoutConfig.mobile.gridColsSm;
            }
            else if (activeBreakpointIdx === 1) {
                columnCount_1 = layoutConfig.mobile.gridColsMd;
            }
            else if (activeBreakpointIdx === 2) {
                columnCount_1 = layoutConfig.desktop.gridColsLg;
            }
            else {
                columnCount_1 = layoutConfig.desktop.gridColsXl;
            }
            context.getExistingLayer().find('.ss360-grid').each(function (grid) {
                var items = sxQuery(grid).find('.ss360-suggests');
                var activeRow = 0;
                for (var i = 0; i < items.length; i++) {
                    var mod = i % columnCount_1;
                    if (mod === 0) {
                        activeRow++;
                    }
                    var $item = sxQuery(items.get()[i]);
                    if ((mod + 1 !== columnCount_1) && columnCount_1 !== 1) { // add padding right except of the last column
                        $item.css('padding-right', '0.5em');
                    }
                    if (mod !== 0 && columnCount_1 !== 1) { // add padding left except of the first column
                        $item.css('padding-left', '0.5em');
                    }
                    // skip the - prefix as the style property is named 'msGridColumn'/'msGridRow'
                    $item.css('ms-grid-column', mod + 1);
                    $item.css('ms-grid-row', activeRow);
                }
            });
        }
    },
    focusWithin: function (selector, fallbackClassName) {
        try {
            sxQuery(':focus-within');
        }
        catch (err) { // no support
            var $el = sxQuery(selector);
            $el.each(function (node) {
                var $innerEl = sxQuery(node);
                $innerEl.find('input, button, *[tabindex="1"], a')
                    .off('focusout.ss360polyfill')
                    .on('focusout.ss360polyfill', function () {
                    $innerEl.removeClass(fallbackClassName);
                })
                    .off('focus.ss360polyfill')
                    .on('focus.ss360polyfill', function () {
                    $innerEl.addClass(fallbackClassName);
                });
            });
        }
    }
};

'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import Logger from '../../global/Logger';
import Icons from './Icons';
import SearchButtonIcon from '../enums/SearchButtonIcon';
var applySearchBoxStyles = function (searchResultType, searchBoxConfig, styleConfig) {
    var sbs = styleConfig.searchBox;
    if (sbs === undefined) {
        return;
    }
    var searchBoxSelector = searchBoxConfig.selector;
    var searchButtonSelector = searchBoxConfig.searchButton;
    var searchBox = sxQuery(searchBoxSelector);
    var sbut = sxQuery(searchButtonSelector);
    applySearchBoxTextStyles(sbs.text, searchBox, sbut);
    applySearchBoxBorderStyles(sbs.border, searchBox, sbut);
    applyPadding(searchBox, sbs.padding);
    applyPadding(sbut, sbs.padding);
    var iconPaddingLeft = sbs.padding ? sbs.padding : '5px';
    var defaultColor = '#666666';
    for (var i = 0; i < searchBox.length; i++) {
        addSearchBoxIcon(sxQuery(searchBox.get()[i]), sbs.icon, iconPaddingLeft, defaultColor, searchResultType);
    }
    if (sbs.button !== undefined && sbut.length > 0) {
        var sbutStyle = sbs.button;
        applyStyle(sbut, 'cursor', 'pointer');
        if (sbutStyle.icon === 'magnifier') {
            sbutStyle.icon = {
                image: SearchButtonIcon.Magnifier,
                color: sbutStyle.color
            };
        }
        if (sbutStyle.text !== undefined && sbutStyle.text.length > 0) {
            sbut.val(sbutStyle.text);
            sbut.html(sbutStyle.text);
            applyColor(sbut, sbutStyle.textColor);
        }
        else if (!sbut.hasClass('ss360-custom-search__searchbutton') && (sbutStyle.icon || {}).image === 'magnifier') {
            sbut.val(' ');
            sbut.html(' ');
        }
        addSearchButtonIcon(sbut, sbutStyle.icon, iconPaddingLeft, (sbutStyle.icon || {}).color || sbutStyle.color || defaultColor, sbutStyle.iconPadding);
    }
    var backgroundColor = sbs.background ? sbs.background.color : undefined;
    var searchButtonBackgroundColor = (sbs.button !== undefined && sbs.button.backgroundColor !== undefined ? sbs.button.backgroundColor : undefined) || styleConfig.accentColor;
    applyBackground(searchBox, backgroundColor);
    applyBackground(sbut, searchButtonBackgroundColor);
};
var applySearchSuggestionStyles = function (styleConfiguration, uniboxId) {
    var sbs = styleConfiguration.suggestions;
    if (sbs === undefined) {
        return;
    }
    var sugBox = sxQuery("#".concat(uniboxId));
    applyPadding(sugBox, sbs.padding);
    applyStyle(sugBox, 'margin-top', sbs.distanceFromTop);
    if (sbs.text !== undefined) {
        applyColor(sugBox, sbs.text.color);
    }
    if (sbs.background !== undefined) {
        applyBackground(sugBox, sbs.background.color);
    }
    if (sbs.border !== undefined) {
        applyBorderRadius(sugBox, sbs.border.radius);
        applyBorderColor(sugBox, sbs.border.color);
    }
};
var applySearchBoxTextStyles = function (style, sb, sbut) {
    if (style === undefined) {
        return;
    }
    applyColor(sb, style.color);
    applyColor(sbut, style.color);
    applySize(sb, style.size);
    applySize(sbut, style.size);
};
var applySearchBoxBorderStyles = function (style, sb, sbut) {
    if (style === undefined) {
        return;
    }
    applyBorderRadius(sb, style.radius);
    applyBorderRadius(sbut, style.radius);
    applyBorderColor(sb, style.color);
    applyBorderColor(sbut, style.color);
};
var applyColor = function (elem, color) {
    applyStyle(elem, 'color', color);
};
var applySize = function (elem, size) {
    applyStyle(elem, 'font-size', size);
};
var applyBorderRadius = function (elem, radius) {
    if (radius === undefined) {
        return;
    }
    applyStyle(elem, 'border', '1px solid');
    applyStyle(elem, 'border-radius', radius);
};
var applyBorderColor = function (elem, color) {
    applyStyle(elem, 'border-color', color);
};
var applyPadding = function (elem, padding) {
    applyStyle(elem, 'padding', padding);
};
var applyBackground = function (elem, color) {
    applyStyle(elem, 'background-color', color);
};
var applyStyle = function (elem, key, value) {
    if (value !== undefined) {
        elem.css(key, value);
    }
};
var addSearchBoxIcon = function (sb, icon, iconPaddingLeft, defaultColor, searchResultType) {
    if (icon === undefined || icon.image !== 'magnifier') {
        return;
    }
    var isFullScreenField = searchResultType === 'fullscreen' && sb.attr('id') === 'ss360-query';
    var sbHeight = isFullScreenField ? 54 : sb.outerHeight();
    var positionTop = 0;
    var size = 0;
    size = (sbHeight - 18); // set to css value because the input is not fully visible at this point
    positionTop = (sbHeight - size) / 2;
    var sIcon = Icons.getBase64EncodedIcon(Icons.MAGNIFIER, icon.color || defaultColor);
    sb.css('background-image', "url('".concat(sIcon, "')"));
    sb.css('background-repeat', 'no-repeat');
    sb.css('background-position-x', iconPaddingLeft);
    sb.css('background-position-y', "".concat(positionTop, "px"));
    sb.css('background-size', "".concat(size, "px ").concat(size, "px"));
    sb.css('padding-left', "".concat(size + 1 * (parseInt(iconPaddingLeft.replace('px', ''), 10)) + 2, "px"));
};
var addSearchButtonIcon = function (sbut, icon, iconPaddingLeft, color, iconPadding) {
    if (icon === undefined || icon.image !== 'magnifier') {
        return;
    }
    var yPadding = 9;
    if (iconPadding !== undefined) {
        yPadding = parseInt(iconPadding, 10);
    }
    var sbutIc = Icons.getBase64EncodedIcon(Icons.MAGNIFIER, color);
    sbut.each(function (but) {
        var $but = sxQuery(but);
        var size = ($but.outerHeight() - 2 * yPadding);
        var isButton = but.nodeName === 'BUTTON';
        if (isButton) {
            var sbutStyle = "width:".concat(size, "px; height:").concat(size, "px");
            $but.html("<img role=\"presentation\" alt=\"\" style=\"".concat(sbutStyle, "\" src=\"").concat(sbutIc, "\"/>"));
        }
        else {
            var paddingTop = ($but.outerHeight() - size) / 2;
            $but.css('background', "url('".concat(sbutIc, "') no-repeat ").concat(iconPadding !== undefined ? iconPadding : iconPaddingLeft, " ").concat(paddingTop, "px"));
            $but.css('background-size', "".concat(size, "px"));
        }
        $but.css('min-width', "".concat($but.outerHeight(), "px"));
    });
};
var StyleApplier = {
    apply: function (searchResultType, searchBoxConfiguration, styleConfiguration) {
        try {
            applySearchBoxStyles(searchResultType, searchBoxConfiguration, styleConfiguration);
        }
        catch (e) {
            Logger.warn(e);
        }
        // suggest box styles
        try {
            applySearchSuggestionStyles(styleConfiguration);
        }
        catch (e) {
            Logger.warn(e);
        }
    },
    applyOnSuggestions: function (styleConfiguration, uniboxId) {
        try {
            applySearchSuggestionStyles(styleConfiguration, uniboxId);
        }
        catch (e) {
            Logger.warn(e);
        }
    }
};
export default StyleApplier;

'use strict';
import StringHelper from '../../global/StringHelper';
import MediaQueryMatchType from '../../sxQuery/MediaQueryMatchType';
import sxQuery from '../../sxQuery/sxQuery';
import SxQueryUtils from '../../sxQuery/SxQueryUtils';
import LayoutType from '../enums/LayoutType';
var lastToggledState;
var lastToggledGroup;
var COOKIE_NAME = '-toggled-view-state';
export default {
    COOKIE_NAME: COOKIE_NAME,
    render: function (context, contentGroups, query) {
        if (contentGroups === void 0) { contentGroups = []; }
        if (query === void 0) { query = ''; }
        context.getExistingLayer().find('.ss360-list-grid-toggle').remove();
        var $layerContent = context.getExistingLayer().find('.ss360-layer__content');
        var $sorting = context.getExistingLayer().find('.ss360-sorting');
        var $heading = $layerContent.find('.ss360-layer__heading');
        var $fieldset = sxQuery('<fieldset class="ss360-list-grid-toggle"></fieldset>');
        $fieldset.append('<legend class="ss360-sr-only">Change display type</legend>');
        var accentColor = context.pluginConfiguration.style.accentColor;
        var inactiveColor = '#4A4F62';
        var randomId = Math.round(Math.random() * 10000);
        var $listInput = sxQuery("<input id=\"ss360-list-grid-toggle--list-".concat(randomId, "\" class=\"ss360-list-grid-toggle__toggle ss360-sr-only\" type=\"radio\" name=\"ss360-list-grid\" value=\"list\" tabindex=\"-1\">"));
        var $gridInput = sxQuery("<input id=\"ss360-list-grid-toggle--grid-".concat(randomId, "\" class=\"ss360-list-grid-toggle__toggle ss360-sr-only\" type=\"radio\" name=\"ss360-list-grid\" value=\"grid\" tabindex=\"-1\">"));
        var $listLabel = sxQuery("<label for=\"ss360-list-grid-toggle--list-".concat(randomId, "\" title=\"List\" class=\"ss360-list-grid-toggle__label\" tabindex=\"0\"><span class=\"ss360-sr-only\">List</span></label>"));
        var $gridLabel = sxQuery("<label for=\"ss360-list-grid-toggle--grid-".concat(randomId, "\" title=\"Grid\" class=\"ss360-list-grid-toggle__label\" tabindex=\"0\"><span class=\"ss360-sr-only\">Grid</span></label>"));
        $listLabel.append("<i role=\"presentation\" class=\"ss360-list-grid-toggle__icon ss360-list-grid-toggle__icon--list\"><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" fill=\"none\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.4 16.8a.6.6 0 0 1 .6-.6h10.7a.6.6 0 0 1 0 1.1H9a.6.6 0 0 1-.6-.5Zm0-4.8a.6.6 0 0 1 .6-.6h10.7a.6.6 0 1 1 0 1.2H9a.6.6 0 0 1-.6-.6Zm0-4.7a.6.6 0 0 1 .6-.6h10.7a.6.6 0 0 1 0 1.2H9a.6.6 0 0 1-.6-.6ZM5 8.4a1.2 1.2 0 1 0 0-2.3 1.2 1.2 0 0 0 0 2.3Zm0 4.8a1.2 1.2 0 1 0 0-2.4 1.2 1.2 0 0 0 0 2.4Zm0 4.7a1.2 1.2 0 1 0 0-2.3 1.2 1.2 0 0 0 0 2.3Z\" fill=\"#4A4F62\"/></svg></i>");
        $gridLabel.append("<i role=\"presentation\" class=\"ss360-list-grid-toggle__icon ss360-list-grid-toggle__icon--grid\"><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" fill=\"none\"><path d=\"M6.3 7.1a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6Zm5.7 0a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6Zm5.7 0a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6ZM6.3 12.8a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6Zm5.7 0a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6Zm5.7 0a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6ZM6.3 18.5a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6Zm5.7 0a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6Zm5.7 0a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6Z\" stroke=\"#4A4F62\" stroke-width=\"1.6\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></svg></i>");
        $fieldset.append($listInput);
        $fieldset.append($listLabel);
        $fieldset.append($gridInput);
        $fieldset.append($gridLabel);
        var layout = context.pluginConfiguration.layout;
        var activeLayoutConfiguration = SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Min, 992) ? layout.desktop : layout.mobile;
        var defaultLayoutType = activeLayoutConfiguration.type;
        var isMixed = defaultLayoutType === LayoutType.Mixed;
        var activeLayoutType = defaultLayoutType;
        if (context.pluginConfiguration.allowCookies && context.readCookie(COOKIE_NAME) !== null && !isMixed) {
            activeLayoutType = context.readCookie(COOKIE_NAME);
        }
        else if (lastToggledState !== undefined && !isMixed) {
            activeLayoutType = lastToggledState;
        }
        var updateColors = function (type) {
            if (type === 'grid') {
                $gridLabel.find('svg path').attr('stroke', accentColor);
                $listLabel.find('svg path').attr('fill', inactiveColor);
            }
            else {
                $gridLabel.find('svg path').attr('stroke', inactiveColor);
                $listLabel.find('svg path').attr('fill', accentColor);
            }
        };
        var updateViewState = function (type) {
            if (type === LayoutType.Grid) {
                $gridInput.attr('checked', 'checked');
                $gridInput.get(0).checked = true;
                $listInput.removeAttribute('checked');
                $listInput.get(0).checked = false;
                $gridLabel.addClass('ss360-list-grid-toggle__label--active');
                $listLabel.removeClass('ss360-list-grid-toggle__label--active');
                updateColors('grid');
            }
            else {
                $listInput.attr('checked', 'checked');
                $listInput.get(0).checked = true;
                $gridInput.removeAttribute('checked');
                $gridInput.get(0).checked = false;
                $listLabel.addClass('ss360-list-grid-toggle__label--active');
                $gridLabel.removeClass('ss360-list-grid-toggle__label--active');
                updateColors('list');
            }
        };
        var getGroupLayout = function (resultGroupName) {
            if (!isMixed) {
                return defaultLayoutType;
            }
            if (context.pluginConfiguration.allowCookies) {
                var val = context.readCookie("".concat(COOKIE_NAME, "--").concat(resultGroupName));
                if (val !== null) {
                    return val === 'grid' ? LayoutType.Grid : LayoutType.List;
                }
            }
            return (activeLayoutConfiguration.gridContentGroups || []).indexOf(resultGroupName) !== -1 ? LayoutType.Grid : LayoutType.List;
        };
        updateViewState(activeLayoutType);
        var $groupTarget = $layerContent;
        var cookiePostfix = '';
        var toggleState = function (type) {
            if (type === 'grid') {
                $groupTarget.find('.ss360-list').addClass('ss360-grid ss360-grid--sm ss360-grid--lg');
                $gridLabel.addClass('ss360-list-grid-toggle__label--active');
                $listLabel.removeClass('ss360-list-grid-toggle__label--active');
                $gridInput.attr('checked', 'checked');
                $listInput.removeAttribute('checked');
            }
            else {
                $groupTarget.find('.ss360-list').removeClass('ss360-grid ss360-grid--sm ss360-grid--lg');
                $listLabel.addClass('ss360-list-grid-toggle__label--active');
                $gridLabel.removeClass('ss360-list-grid-toggle__label--active');
                $gridInput.attr('checked', 'checked');
                $listInput.removeAttribute('checked');
            }
            updateColors(type);
            if (context.pluginConfiguration.allowCookies) {
                context.createCookie("".concat(COOKIE_NAME).concat(cookiePostfix), type, 7);
            }
            else {
                lastToggledState = type;
            }
        };
        $gridInput.on('change', function () {
            toggleState('grid');
        });
        $listInput.on('change', function () {
            toggleState('list');
        });
        $listLabel.on('keydown', function (e) {
            var keyCode = e.keyCode || e.which;
            if (keyCode === 32 || keyCode === 13) {
                e.stopPropagation();
                e.preventDefault();
                toggleState('list');
            }
            else if (keyCode >= 37 && keyCode <= 40) {
                e.stopPropagation();
                e.preventDefault();
                $gridLabel.focus();
                toggleState('grid');
            }
        });
        $gridLabel.on('keydown', function (e) {
            var keyCode = e.keyCode || e.which;
            if (keyCode === 32 || keyCode === 13) {
                e.stopPropagation();
                e.preventDefault();
                toggleState('grid');
            }
            else if (keyCode >= 37 && keyCode <= 40) {
                e.stopPropagation();
                e.preventDefault();
                $listLabel.focus();
                toggleState('list');
            }
        });
        if (activeLayoutType !== defaultLayoutType && !isMixed) { // make sure the view is properly switched based on the last selected option
            toggleState(activeLayoutType);
        }
        else if (isMixed) {
            var allResultsFirst_1 = context.pluginConfiguration.layout.navigation.allResultsFirst;
            contentGroups.sort(function (a, b) {
                if (a === b) {
                    return 0;
                }
                if (a === '*') {
                    return allResultsFirst_1 ? -1 : 1;
                }
                if (b === '*') {
                    return allResultsFirst_1 ? 1 : -1;
                }
                return 0;
            });
            contentGroups.forEach(function (group) {
                var layout = getGroupLayout(group);
                var groupName = group !== '*' ? group : 'ss360_all_results';
                $groupTarget = $layerContent.find(".ss360-group-".concat(StringHelper.getSafeKey(groupName)));
                toggleState(layout);
            });
            var lastOpenTab = context.readLastOpenTab();
            var activeGroup = contentGroups[0];
            if (lastOpenTab !== null && lastOpenTab.split('<#>')[0] === query) {
                activeGroup = lastOpenTab.split('<#>')[1];
            }
            else if (lastOpenTab !== null && lastToggledGroup !== undefined) {
                activeGroup = lastToggledGroup;
            }
            if (activeGroup === 'ss360_all_results') {
                activeGroup = '*';
            }
            if (contentGroups.indexOf(activeGroup) === -1) {
                var safeKeyMap = contentGroups.reduce(function (acc, contentGroup) {
                    acc[StringHelper.getSafeKey(contentGroup)] = contentGroup;
                    return acc;
                }, {});
                activeGroup = safeKeyMap[activeGroup] || activeGroup;
            }
            var updateActiveGroup_1 = function (group) {
                $groupTarget = $layerContent.find(".ss360-group-".concat(StringHelper.getSafeKey(group === '*' ? 'ss360_all_results' : group)));
                cookiePostfix = "--".concat(group);
                lastToggledGroup = group;
                updateViewState(getGroupLayout(group));
            };
            updateActiveGroup_1(activeGroup);
            context.subscribe('navigationChange', 'listGridToggle', function (data) {
                updateActiveGroup_1(data);
            });
        }
        var hidden = [];
        if (layout.mobile.gridColsSm === 1) {
            hidden.push('sm');
        }
        if (layout.mobile.gridColsMd === 1) {
            hidden.push('md');
        }
        if (layout.desktop.gridColsLg === 1) {
            hidden.push('lg');
        }
        if (layout.desktop.gridColsXl === 1) {
            hidden.push('xl');
        }
        hidden.forEach(function (val) {
            $fieldset.addClass("ss360-list-grid-toggle--hide-".concat(val));
        });
        if ($sorting.length > 0) {
            $sorting.addClass('ss360-sorting--has-list-grid-switch');
            $sorting.append($fieldset);
        }
        else if ($heading.length === 0 || !$heading.get(0).nextElementSibling) {
            $layerContent.prepend($fieldset);
        }
        else {
            $layerContent.get(0).insertBefore($fieldset.get(0), $heading.get(0).nextElementSibling);
        }
    }
};

'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import SxQueryUtils from '../../sxQuery/SxQueryUtils';
import SearchSuggest from '../model/SearchSuggest';
import SuggestionRenderer from './SuggestionRenderer';
var bindVariantEvents = function (node, suggestRenderSettings, changeCallback, context) {
    var currentImage;
    var image = node.find('.ss360-suggests__image');
    // @ts-ignore
    var originalImage = image.attr('src') || image.data('ss360Src');
    var $variantsWrap = node.find('.ss360-suggests__variants');
    if ($variantsWrap.length === 0) {
        return;
    }
    var isBindingEvents = false;
    var lastScrollLeft;
    var bindArrowEvents = function () {
        if (isBindingEvents)
            return;
        isBindingEvents = true;
        SxQueryUtils.requestAnimationFrame(function () {
            try {
                var parentWidth_1 = $variantsWrap.outerWidth();
                var variantsWrap_1 = $variantsWrap.get()[0];
                var wrapScrollWidth_1 = variantsWrap_1.scrollWidth;
                var $prevArrowWrap_1 = $variantsWrap.find('.ss360-suggests__variant-arrow-wrap--prev');
                var $nextArrowWrap_1 = $variantsWrap.find('.ss360-suggests__variant-arrow-wrap--next');
                if (parentWidth_1 + $prevArrowWrap_1.outerWidth() + $nextArrowWrap_1.outerWidth() < wrapScrollWidth_1) {
                    var scrollMovement_1 = 3 * $variantsWrap.find('.ss360-suggests__variant-button').outerWidth();
                    var isUpdating_1 = false;
                    var onScrollChanged_1 = function (scrollLeft) {
                        if (isUpdating_1)
                            return;
                        isUpdating_1 = true;
                        SxQueryUtils.requestAnimationFrame(function () {
                            if (scrollLeft === 0) {
                                $prevArrowWrap_1.hide();
                            }
                            else {
                                $prevArrowWrap_1.css('display', 'block');
                            }
                            if (scrollLeft + parentWidth_1 + $nextArrowWrap_1.outerWidth() + $prevArrowWrap_1.outerWidth() >= wrapScrollWidth_1) {
                                $nextArrowWrap_1.hide();
                            }
                            else {
                                $nextArrowWrap_1.css('display', 'block');
                            }
                            isUpdating_1 = false;
                        });
                        lastScrollLeft = scrollLeft;
                    };
                    onScrollChanged_1(variantsWrap_1.scrollLeft);
                    $variantsWrap.off('scroll.variants').on('scroll.variants', function () {
                        onScrollChanged_1(variantsWrap_1.scrollLeft);
                    });
                    $prevArrowWrap_1.off('click.variants').on('click.variants', function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        variantsWrap_1.scrollLeft = Math.max(variantsWrap_1.scrollLeft - scrollMovement_1, 0);
                    });
                    $nextArrowWrap_1.off('click.variants').on('click.variants', function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        var newScroll = variantsWrap_1.scrollLeft + scrollMovement_1;
                        var maxScroll = variantsWrap_1.wrapScrollWidth - parentWidth_1;
                        if (newScroll > maxScroll || (maxScroll - newScroll) < (scrollMovement_1 / 2)) { // move completely to the end if almost there, give some offset to compensate for the sticky arrows
                            newScroll = maxScroll + (scrollMovement_1 / 2);
                        }
                        variantsWrap_1.scrollLeft = newScroll;
                    });
                }
            }
            catch (err) {
                console.error(err);
            }
            isBindingEvents = false;
        });
    };
    bindArrowEvents();
    sxQuery(window).on('resize.variants', function () {
        bindArrowEvents();
    });
    $variantsWrap.find('.ss360-suggests__variant-image').each(function (el) {
        var $el = sxQuery(el);
        $el.on('load.variants', function () {
            var groupParent = node.parents('.ss360-grid', true);
            groupParent.addClass('ss360-grid--has-variants');
            bindArrowEvents();
            $el.off('load.variants');
        });
    });
    node.find('.ss360-suggests__variant-button').each(function (el) {
        var $el = sxQuery(el);
        var $variantImage = $el.find('.ss360-suggests__variant-image');
        // @ts-ignore
        var variantImage = $variantImage.attr('src') || $variantImage.data('ss360Src');
        if (variantImage === null || variantImage === undefined) {
            return;
        }
        $el.off('mouseover.variants,focus.variants,mouseleave.variants,focusout.variants,click.variants').on('mouseover.variants,focus.variants', function () {
            if (currentImage !== variantImage) {
                setTimeout(function () {
                    currentImage = variantImage;
                    image.attr('src', variantImage);
                }, 0);
            }
        }).on('mouseleave.variants,focusout.variants', function () {
            if (originalImage !== undefined && originalImage !== null) {
                currentImage = undefined;
                image.attr('src', originalImage);
            }
        });
        $el.on('click.variants', function (e) {
            var data = suggestRenderSettings.suggest.plain;
            var identifier = $el.data('productIdentifier');
            // @ts-ignore
            var link = $el.data('link');
            if (link !== undefined && SxQueryUtils.linkOpensInNewTab(e)) {
                window.open(link, '_blank');
            }
            if (data instanceof Array) {
                // match by identifier, title as fallback, open link in new tab if neither matches any variant
                var idx = data.reduce(function (acc, entry, entryIdx) { return (entry.identifier === identifier ? entryIdx : acc); }, -1);
                if (idx === -1) {
                    idx = data.reduce(function (acc, entry, entryIdx) { return (entry.name === identifier ? entryIdx : acc); }, -1);
                }
                if (idx === -1) {
                    if (link !== undefined) {
                        window.open(link, '_blank');
                    }
                    return;
                }
                // create new suggestions with a different main variant
                var entry = data.splice(idx, 1)[0];
                data.splice(0, 0, entry);
                suggestRenderSettings.suggest = new SearchSuggest(data);
                suggestRenderSettings.preventLazyLoad = true;
                if (context.pluginConfiguration.callbacks.singleResultPreRenderCallback) {
                    try {
                        context.pluginConfiguration.callbacks.singleResultPreRenderCallback(suggestRenderSettings.suggest.suggest, suggestRenderSettings.suggest.variants);
                    }
                    catch (err) {
                        console.error(err);
                    }
                }
                var $newSuggest = sxQuery(SuggestionRenderer.renderSS360Suggest(suggestRenderSettings));
                // keep variants in the same order
                var $newSuggestVariants = $newSuggest.find('.ss360-suggests__variants');
                $newSuggestVariants.children().remove();
                $newSuggestVariants.append($variantsWrap.children());
                // only hold image size as long as necessary, prevent layout jumps
                var $newSuggestImage_1 = $newSuggest.find('.ss360-suggests__image');
                try {
                    $newSuggestImage_1.css('width', "".concat(Math.round(image.get()[0].width), "px"));
                    $newSuggestImage_1.css('height', "".concat(Math.round(image.get()[0].height), "px"));
                }
                catch (err) {
                    // ccl
                }
                $newSuggestImage_1.on('load', function () {
                    $newSuggestImage_1.attr('style', null);
                });
                // replace content
                node.children().remove();
                node.append($newSuggest.children());
                // update ss360 identifier
                if (suggestRenderSettings.suggest.getIdentifier() !== undefined) {
                    node.data('productIdentifier', suggestRenderSettings.suggest.getIdentifier());
                }
                if (lastScrollLeft !== undefined) {
                    $newSuggestVariants.css('scroll-behavior', 'initial');
                    $newSuggestVariants.get()[0].scrollLeft = lastScrollLeft;
                    $newSuggestVariants.css('scroll-behavior', null);
                }
                // set checked property
                $newSuggestVariants.find('.ss360-suggests__variant-button').each(function (vb) {
                    var $vb = sxQuery(vb);
                    if ($vb.data('productIdentifier') === identifier) {
                        $vb.attr('aria-checked', 'true');
                    }
                    else {
                        $vb.attr('aria-checked', 'false');
                    }
                });
                // rebind variants component
                bindVariantEvents(node, suggestRenderSettings, changeCallback, context);
                changeCallback();
            }
            else if (link !== undefined) {
                window.open(link, '_blank');
            }
        });
    });
};
var bindImageCarouselEvents = function (node, allImages) {
    var $carousel = node.find('.ss360-suggests__carousel');
    if ($carousel.length === 0) {
        return;
    }
    var width = $carousel.outerWidth();
    var height = $carousel.outerHeight();
    var activeIndex = 0;
    var isLoaded = false;
    var navButtons = node.find('.ss360-suggests__carousel-nav-entry');
    var firstImage = $carousel.find('.ss360-suggests__image:first');
    var loadedImages = [];
    var preloadImages = function () {
        // @ts-ignore
        if (SxQueryUtils.getConnectionEffectiveType() === undefined || SxQueryUtils.getConnectionEffectiveType() === '4g') { // only load on slow connections to ensure smoother transitions
            return;
        }
        if (SxQueryUtils.saveData()) { // don't preload if save data flag is on 
            return;
        }
        var prev = activeIndex - 1 < 0 ? allImages.length - 1 : activeIndex - 1;
        var next = activeIndex + 1 < allImages.length ? activeIndex + 1 : 0;
        var prevImg = allImages[prev];
        var nextImg = allImages[next];
        if (loadedImages.indexOf(prevImg) === -1) {
            loadedImages.push(prevImg);
            var img = new Image();
            img.src = prevImg;
        }
        if (loadedImages.indexOf(nextImg) === -1) {
            loadedImages.push(nextImg);
            var img = new Image();
            img.src = nextImg;
        }
    };
    var setDimensions = function () {
        width = $carousel.outerWidth();
        height = $carousel.outerHeight();
        if (width !== 0 && height !== 0) { // freeze dimensions
            $carousel.css('width', "".concat(width, "px"));
            $carousel.css('height', "".concat(height, "px"));
        }
    };
    firstImage.on('load.carousel', function () {
        firstImage.off('load.carousel');
        preloadImages();
        setTimeout(function () {
            isLoaded = true;
            setDimensions();
        }, 0);
    });
    $carousel.find('.ss360-suggests__image').on('load.carousel', function (e) {
        var $img = sxQuery(e.target);
        $img.off('load.carousel');
        // @ts-ignore
        var src = $img.attr('src');
        if (loadedImages.indexOf(src) === -1) {
            loadedImages.push(src);
        }
    });
    if (width !== 0 && height !== 0) { // freeze dimensions
        $carousel.css('width', "".concat(width, "px"));
        $carousel.css('height', "".concat(height, "px"));
    }
    var isResizing = false;
    sxQuery(window).on('resize.carousel', function () {
        if (isResizing || !isLoaded)
            return;
        isResizing = true;
        SxQueryUtils.requestAnimationFrame(function () {
            $carousel.css('width', null);
            $carousel.css('height', null);
            SxQueryUtils.requestAnimationFrame(function () {
                width = $carousel.outerWidth();
                height = $carousel.outerHeight();
                if (width !== 0 && height !== 0) { // freeze dimensions
                    $carousel.css('width', "".concat(width, "px"));
                    $carousel.css('height', "".concat(height, "px"));
                }
                isResizing = false;
            });
        });
    });
    var moveTo = function (selectedButton, index) {
        if (width === 0 || height === 0) {
            setDimensions();
        }
        if (index === activeIndex) {
            return;
        }
        navButtons.removeClass('ss360-suggests__carousel-nav-entry--active');
        navButtons.attr('aria-selected', 'false');
        selectedButton.addClass('ss360-suggests__carousel-nav-entry--active');
        selectedButton.attr('aria-selected', 'true');
        var preventSmoothScrol = Math.abs(index - activeIndex) !== 1; // only smooth scroll to the neighboring image
        if (preventSmoothScrol) {
            $carousel.css('scroll-behavior', 'initial');
        }
        $carousel.get()[0].scrollLeft = width * index;
        if (preventSmoothScrol) {
            $carousel.css('scroll-behavior', null);
        }
        activeIndex = index;
        preloadImages();
    };
    navButtons.on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var selectedButton = sxQuery(e.target);
        // @ts-ignore
        var selectedIndex = parseInt(selectedButton.data('idx'), 10);
        moveTo(selectedButton, selectedIndex);
    });
    var moveBy = function (by) {
        var selectedIndex = activeIndex + by;
        if (selectedIndex < 0) {
            selectedIndex = allImages.length - 1;
        }
        if (selectedIndex > allImages.length - 1) {
            selectedIndex = 0;
        }
        var selectedButton = node.find(".ss360-suggests__carousel-nav-li:nth-child(".concat(selectedIndex + 1, ") .ss360-suggests__carousel-nav-entry"));
        moveTo(selectedButton, selectedIndex);
    };
    node.find('.ss360-suggests__carousel-pager--prev').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        moveBy(-1);
    });
    node.find('.ss360-suggests__carousel-pager--next').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        moveBy(1);
    });
};
export default {
    bindVariantEvents: bindVariantEvents,
    bindImageCarouselEvents: bindImageCarouselEvents
};

'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import Logger from '../../global/Logger';
import CallbackEnhanceType from '../enums/CallbackEnhanceType';
import ResultIntegrationType from '../enums/ResultIntegrationType';
import SearchResultType from '../enums/SearchResultType';
var colorToRgb = function (hexOrRgb) {
    try {
        hexOrRgb = hexOrRgb.trim();
    }
    catch (ex) {
        // ccl
    }
    if ((hexOrRgb.indexOf('rgba(') === 0 || (hexOrRgb.indexOf('rgb(')) === 0 && hexOrRgb.indexOf(')') === hexOrRgb.length - 1)) {
        var rgbaParts = hexOrRgb.substring(0, hexOrRgb.length - 1).replace(/rgba?\(/, '').split(',').map(function (part) { return part.trim(); });
        if (rgbaParts.length >= 3) {
            return {
                r: parseInt(rgbaParts[0], 10),
                g: parseInt(rgbaParts[1], 10),
                b: parseInt(rgbaParts[2], 10)
            };
        }
    }
    if (hexOrRgb.length === 4) {
        hexOrRgb = "#".concat(Array(3).join(hexOrRgb[1])).concat(Array(3).join(hexOrRgb[2])).concat(Array(3).join(hexOrRgb[3]));
    }
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexOrRgb);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};
var darkenColorHex = function (hex, percent) {
    try {
        if (hex.length === 4) {
            hex = "#".concat(Array(3).join(hex[1])).concat(Array(3).join(hex[2])).concat(Array(3).join(hex[3]));
        }
        var rgb = Helper.colorToRgb(hex);
        if (rgb === null) {
            return rgb;
        }
        var r = rgb.r, g = rgb.g, b = rgb.b;
        r = Math.round(Math.max(0, Math.min((r * ((100 - percent) / 100)), 255)));
        g = Math.round(Math.max(0, Math.min((g * ((100 - percent) / 100)), 255)));
        b = Math.round(Math.max(0, Math.min((b * ((100 - percent) / 100)), 255)));
        var rString = r.toString(16);
        var gString = g.toString(16);
        var bString = b.toString(16);
        if (rString.length === 1)
            rString = "0".concat(rString);
        if (gString.length === 1)
            gString = "0".concat(gString);
        if (bString.length === 1)
            bString = "0".concat(bString);
        return "#".concat(rString).concat(gString).concat(bString);
    }
    catch (err) {
        return hex;
    }
};
var shouldSearchPost = function (context, url) {
    if (!context.isEcom()) {
        return false;
    }
    return url.length > 4000;
};
var convertToPost = function (url) {
    var urlParts = url.split('?');
    var urlBase = urlParts[0];
    var queryParams = [];
    var data = urlParts[1].split('&').reduce(function (acc, entry) {
        var kv = entry.split('=');
        var key = decodeURIComponent(kv[0]);
        var val = decodeURIComponent(kv[1]);
        try {
            acc[key] = JSON.parse(val);
        }
        catch (err) {
            acc[key] = val;
        }
        if (key === 'query') {
            acc[key] = (acc[key] || '').toString();
        }
        if (key === 'site' || key === 'projectId' || key === 'cache' || key === 'caching' || key === 'internal' || key === 'userGroup') {
            queryParams.push(entry);
        }
        return acc;
    }, {});
    return {
        url: "".concat(urlBase).concat(queryParams.length > 0 ? '?' : '').concat(queryParams.join('&')),
        data: data
    };
};
var lightenColorHex = function (hex, percent) { return darkenColorHex(hex, percent * -1); };
var deepCopyArray = function (arr) {
    return arr.map(function (val) {
        if (val instanceof Function) {
            return val;
        }
        else if (val instanceof Array) {
            return deepCopyArray(val);
        }
        else if (val instanceof Object) {
            return deepCopyObject(val);
        }
        return val;
    });
};
var deepCopyObject = function (obj, target) {
    if (target === void 0) { target = {}; }
    Object.keys(obj).forEach(function (key) {
        var val = obj[key];
        if (val === undefined) {
            return;
        }
        if (val instanceof Function) {
            target[key] = val;
        }
        else if (val instanceof Array) {
            target[key] = deepCopyArray(val);
        }
        else if (val instanceof Object) {
            target[key] = deepCopyObject(val);
        }
        else {
            target[key] = val;
        }
    });
    return target;
};
var Helper = {
    getInitializationErrors: function (pluginConfig, is404, isCategorySearch, context) {
        var res = [];
        if (document.querySelectorAll('script[src*=sitesearch360-v]:not([type="module"])').length > 1 || document.querySelectorAll('script[src*=sitesearch360-v][type="module"]').length > 1) {
            res.push('There is more than one sitesearch360 script on this page. Please remove one.');
        }
        var searchBoxSelector = pluginConfig.searchBox.selector;
        var suggestionTrigger = pluginConfig.suggestions.trigger;
        if (!is404 && !isCategorySearch && !context.pluginConfiguration.searchBox._preventBind
            && pluginConfig.results.layoverTrigger === undefined && (suggestionTrigger === undefined || sxQuery(suggestionTrigger).length === 0) && sxQuery(searchBoxSelector).length === 0
            && Object.keys(pluginConfig.tracking.external || {}).length === 0) {
            res.push("There is no input element for the searchBox.selector \"".concat(searchBoxSelector, "\". Please update your ").concat(context.getIsZoovu() ? 'zoovuSearchConfig' : 'ss360Config', " object."));
        }
        return res;
    },
    enhanceCallback: function (callback, expected, enhanceType, context) {
        if (callback === undefined) {
            return expected;
        }
        if (enhanceType === CallbackEnhanceType.EnterResult || enhanceType === CallbackEnhanceType.Type) {
            return callback;
        }
        if (callback !== expected) {
            if (enhanceType === CallbackEnhanceType.Enter) {
                return function (text) {
                    try {
                        callback.call(this, text);
                    }
                    catch (ex) {
                        Logger.warn(ex);
                    }
                };
            }
            if (enhanceType === CallbackEnhanceType.Focus) {
                return function (event, selectedText) {
                    context.core.focus(event, selectedText);
                    try {
                        callback.call(this, event, selectedText);
                    }
                    catch (ex) {
                        Logger.warn(ex);
                    }
                };
            }
            if (enhanceType === CallbackEnhanceType.Blur) {
                return function (event, selectedText) {
                    context.core.blur(event, selectedText);
                    try {
                        callback.call(this, event, selectedText);
                    }
                    catch (ex) {
                        Logger.warn(ex);
                    }
                };
            }
        }
        return callback;
    },
    copyObject: function (obj) {
        return JSON.parse(JSON.stringify(obj));
    },
    deepCopyObject: deepCopyObject,
    getTotalCount: function (data, limitPerGroup, pluginConfig) {
        if (data.groupedResultsAvailable !== undefined) {
            return data.groupedResultsAvailable;
        }
        var groupResults = pluginConfig.results.group;
        var _a = pluginConfig.contentGroups, ignoreOther = _a.ignoreOther, exclude = _a.exclude;
        var limit = pluginConfig.results.num;
        var totalResults = data.totalResults;
        var limitThroughInclude = pluginConfig.results.limitPerGroup && groupResults && (pluginConfig.contentGroups.include || []).length === 1;
        if (!limitPerGroup && groupResults && !limitThroughInclude) {
            totalResults = data.suggests.reduce(function (acc, resultGroup) { return acc + resultGroup.suggests.length; }, 0);
        }
        else if (data.totalResultsPerContentGroup && Object.keys(data.totalResultsPerContentGroup).length > 0) {
            totalResults = Object.keys(data.totalResultsPerContentGroup).reduce(function (acc, key) {
                if ((key === '_' && ignoreOther && !limitThroughInclude && pluginConfig.results.group) || (key !== '_' && exclude !== undefined && exclude.indexOf(key) !== -1)) {
                    return acc;
                }
                if (key === '*' && Object.keys(data.suggests).length <= 1) {
                    return acc;
                }
                return acc + Math.min(limit, data.totalResultsPerContentGroup[key]);
            }, 0);
            if (totalResults === 0 && data.suggests.length === 1 && data.suggests[0] !== undefined && data.suggests[0].name === '*') {
                totalResults = (data.suggests[0].suggests || []).length;
            }
        }
        else if (!groupResults) {
            totalResults = Math.min(totalResults, limit);
        }
        if (totalResults === 0) {
            totalResults = ((((data.searchResults || []).filter(function (group) { return group.type === 'all' && group.placements !== undefined; }))[0] || {}).placements || []).length;
        }
        return totalResults;
    },
    isNaN: function (number) {
        // eslint-disable-next-line no-restricted-properties
        var checker = ('isNaN' in window.Number && (typeof window.Number.isNaN === 'function')) ? window.Number.isNaN : window.isNaN;
        return checker(number);
    },
    colorToRgb: colorToRgb,
    getPositionInNodeList: function (nodeList, node) {
        for (var i = 0; i < nodeList.length; i++) {
            if (nodeList[i] === node) {
                return i + 1;
            }
        }
        return -1;
    },
    getIntegrationType: function (results) {
        var integrationType = results.integrationType;
        if (results.fullScreenConfig !== undefined && results.fullScreenConfig.trigger !== undefined && (integrationType === undefined || integrationType === ResultIntegrationType.Fullscreen)) {
            return SearchResultType.Fullscreen;
        }
        if (results.embedConfig !== undefined && (integrationType === undefined || integrationType === ResultIntegrationType.Embed)) {
            var embedConfig = results.embedConfig;
            var willRequireRedirect = embedConfig.url !== undefined && (document.location.href.indexOf(embedConfig.url) === -1 || (embedConfig.url === '/' && window.location.pathname !== '/'));
            if (willRequireRedirect) { // keep embed if we need to redirect to a search result page
                return SearchResultType.Embed;
            }
            // fallback to layover if 'contentBlock' not present on the page
            return sxQuery(embedConfig.contentBlock, true).length > 0 ? SearchResultType.Embed : SearchResultType.Layover;
        }
        return SearchResultType.Layover;
    },
    updatePaginationProgress: function (node, visible, total) {
        var $bar = node.find('.ss360-pagination__progress-bar--active');
        var num = Math.min(100, Math.max(Math.ceil(visible / total * 100), 5));
        $bar.css('width', "".concat(num, "%"));
    },
    getColorContrast: function (colorA, colorB) {
        var rgbA = colorToRgb(colorA);
        var rgbB = colorToRgb(colorB);
        if (rgbA === null || rgbB === null) {
            return 0;
        }
        var lA = 0.2126 * rgbA.r + 0.7152 * rgbA.g + 0.0722 * rgbA.b;
        var lB = 0.2126 * rgbB.r + 0.7152 * rgbB.g + 0.0722 * rgbB.b;
        var l1 = lA < lB ? lA : lB;
        var l2 = lA < lB ? lB : lA;
    },
    darkenColorHex: darkenColorHex,
    lightenColorHex: lightenColorHex,
    shouldSearchPost: shouldSearchPost,
    convertToPost: convertToPost
};
export default Helper;

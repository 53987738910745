'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import StringHelper from '../../global/StringHelper';
import NavigatorHelper from '../utils/NavigatorHelper';
import UiHelper from '../ui/UiHelper';
import ImageHandler from '../utils/ImageHandler';
import SearchResponse from '../model/SearchResponse';
import SxQueryUtils from '../../sxQuery/SxQueryUtils';
import Helper from '../utils/Helper';
var Results = {
    fetch: function (url, context, callback, failCallback, siteId, noResultsLoadedCallback) {
        var sanitizeResults = context.pluginConfiguration.results.sanitizeResults;
        var ignoreShopSysHeader = context.pluginConfiguration.ignoreShopSysHeader;
        var dataCallback = function (data) {
            if (data && data.suggests && Object.keys(data.suggests).length === 1 && data.suggests['*'] !== undefined
                && (data.searchResults || []).reduce(function (acc, searchResult) { return acc || (searchResult.type === 'all' && (searchResult.placements || []).length > 0); }, false)) {
                delete data.suggests['*'];
            }
            var searchResponse = new SearchResponse(data, sanitizeResults);
            var retry = noResultsLoadedCallback !== undefined && NavigatorHelper.getFirstResult(searchResponse) === undefined;
            var _noResultsGroups = context.pluginConfiguration.results._noResultsGroups;
            if (!retry && _noResultsGroups.length > 0 && noResultsLoadedCallback !== undefined) {
                retry = true;
                for (var i = 0; i < _noResultsGroups.length; i++) {
                    if ((searchResponse.getSuggestsArray(_noResultsGroups[i]) || []).length > 0) {
                        retry = false;
                        break;
                    }
                }
            }
            if (retry) {
                url = noResultsLoadedCallback(data);
                if (url !== undefined) {
                    var innerCallback = function (data2) {
                        searchResponse = new SearchResponse(data2, sanitizeResults);
                        if (callback !== undefined) {
                            callback(searchResponse);
                        }
                    };
                    if (Helper.shouldSearchPost(context, url)) {
                        var postOptions = Helper.convertToPost(url);
                        SxQueryUtils.post(postOptions.url, postOptions.data, innerCallback, 'application/json', !ignoreShopSysHeader, failCallback, context.pluginConfiguration.additionalHeaderParams);
                    }
                    else {
                        SxQueryUtils.get(url, innerCallback, failCallback, undefined, !ignoreShopSysHeader, context.pluginConfiguration.additionalHeaderParams);
                    }
                }
                else {
                    callback(searchResponse);
                }
            }
            else if (callback !== undefined) {
                callback(searchResponse);
            }
        };
        if (Helper.shouldSearchPost(context, url)) {
            var postOptions = Helper.convertToPost(url);
            SxQueryUtils.post(postOptions.url, postOptions.data, dataCallback, 'application/json', !ignoreShopSysHeader, failCallback, context.pluginConfiguration.additionalHeaderParams);
        }
        else {
            SxQueryUtils.get(url, dataCallback, failCallback, undefined, !ignoreShopSysHeader, context.pluginConfiguration.additionalHeaderParams);
        }
    },
    getOrFetch: function (url, context, callback, failCallback, siteId, noResultsLoadedCallback) {
        // TODO: cast to api response
        var cachedResult = context.readObject(context.getIsZoovu() ? 'zoovu_last_query_result' : 'ss360_last_query_result');
        if (cachedResult !== undefined && cachedResult.timestampMs !== undefined && cachedResult.siteId !== undefined
            && cachedResult.locationHref === window.location.href && (cachedResult.timestampMs + 5 * 60 * 1000) > new Date().getTime() && cachedResult.siteId === siteId) { // window location must match and the result must not be older than 5 minutes
            if (cachedResult.suggests !== undefined && cachedResult.suggests['*'] !== undefined) {
                delete cachedResult.suggests['*'];
            }
            callback(new SearchResponse(cachedResult, context.pluginConfiguration.results.sanitizeResults));
        }
        else {
            Results.fetch(url, context, callback, failCallback, siteId, noResultsLoadedCallback);
        }
    },
    prefetchAndRender: function (s, rest, uiBuilder, callback, context) {
        var safeKey = StringHelper.getSafeKey(s.contentGroup);
        var resultTarget = context.getExistingLayer().find(".ss360-group-".concat(safeKey, " ul.ss360-list"));
        var groupParent = context.getExistingLayer().find(".ss360-group-".concat(safeKey));
        var skippedResults = 0;
        if (groupParent.data('skippedResults') !== undefined) {
            skippedResults += parseInt(groupParent.data('skippedResults'), 10);
        }
        var queryUrl = context.core.buildQueryUrl({
            limit: s.limit,
            include: s.contentGroup && (s.groupResults === undefined || s.groupResults) ? [s.contentGroup] : context.pluginConfiguration.contentGroups.include,
            exclude: s.contentGroup && (s.groupResults === undefined || s.groupResults) ? [] : context.pluginConfiguration.contentGroups.exclude,
            tracking: false,
            query: s.query,
            offset: s.offset + skippedResults,
            limitPerGroup: false,
            isPlain: false,
            filters: s.filters,
            group: s.groupResults !== undefined ? s.groupResults : undefined,
            sort: s.sort
        });
        Results.fetch(queryUrl, context, function (data) {
            var results = data.getSuggestsArray(s.contentGroup);
            var hiddenParts = UiHelper.getHiddenParts(context.pluginConfiguration.layout);
            var callbacks = context.pluginConfiguration.callbacks;
            var wereResultsRendered = false;
            if (results !== undefined) {
                results.forEach(function (suggest, idx) {
                    if (UiHelper.hasVisibleContent(suggest, hiddenParts)) {
                        var $item = uiBuilder.buildSuggestItem(suggest, s.query, s.contentGroup, rest <= idx, undefined, [], data.getQueryTags());
                        if (callbacks.resultLine !== undefined) {
                            try {
                                callbacks.resultLine(suggest.suggest, $item.get()[0]);
                            }
                            catch (ex) {
                                console.warn(ex);
                                // ccl
                            }
                        }
                        resultTarget.append($item);
                        wereResultsRendered = true;
                    }
                    else {
                        skippedResults++;
                    }
                });
                if (skippedResults !== 0) {
                    groupParent.data('skippedResults', skippedResults);
                }
                if (!wereResultsRendered && s.offset + skippedResults < context.pluginConfiguration.results.num) { // we try to fetch and render the next portion of results
                    Results.prefetchAndRender(s, rest, uiBuilder, callback, context);
                    return;
                }
                if (context.pluginConfiguration.callbacks.resultsPreloaded !== undefined) {
                    context.pluginConfiguration.callbacks.resultsPreloaded(data.plain);
                }
            }
            if (callback !== undefined && typeof callback === 'function') {
                callback(results);
            }
            if (context.pluginConfiguration.callbacks.preloadedResultsRendered !== undefined) {
                context.pluginConfiguration.callbacks.preloadedResultsRendered(data.plain);
            }
        });
    },
    wasBackPressed: function (query, context) {
        return context.isUseCookiesForOffsets() ? context.readCookie('-query--c') === query : window.location.search.indexOf("".concat(context.getOffsetQueryParam(), "=")) !== -1;
    },
    handleBackPress: function (navigation, query, searchResultType, context) {
        var queryDict = NavigatorHelper.buildQueryDict();
        var pageSize = context.pluginConfiguration.results.moreResultsPagingSize;
        var offset = parseInt(context.isUseCookiesForOffsets() ? context.readCookie('-offset--c') : queryDict[context.getOffsetQueryParam()], 10);
        var cg = context.isUseCookiesForOffsets() ? context.readCookie('-cg--c') : queryDict[context.getContentGroupQueryParam()];
        if (cg === null || cg === undefined) {
            cg = '_';
        }
        var cgKey = StringHelper.getSafeKey(cg);
        var groupWrapper = cg ? context.getExistingLayer().find(".ss360-group-".concat(cgKey)) : context.getExistingLayer().find('.ss360-group-_:first');
        var performScroll = function () {
            // switch content group if necessary
            navigation.focusTab(cgKey);
            // perform the scroll
            var $visibleSuggests = groupWrapper.find('.ss360-suggests:not(.ss360-suggests--hidden)');
            var relevantResult = sxQuery($visibleSuggests.get()[offset]);
            navigation.scrollTo(relevantResult, searchResultType, context.pluginConfiguration.results.navigationBackScrollOffset);
            setTimeout(function () {
                relevantResult.find('.ss360-suggests__link').focus();
            }, 5);
            if (context.pluginConfiguration.results.pageDescriptionLabel) {
                groupWrapper.find('.ss360-pagination__visible').text('Intl' in window ? new Intl.NumberFormat().format($visibleSuggests.length) : $visibleSuggests.length.toString());
                var $total = groupWrapper.find('.ss360-pagination__total');
                if ($total.length > 0) {
                    Helper.updatePaginationProgress(groupWrapper.find('.ss360-pagination__progress'), $visibleSuggests.length, parseInt($total.data('num').toString(), 10));
                }
            }
        };
        var filterOptions = NavigatorHelper.getFilters(context.pluginConfiguration.results);
        if (offset < groupWrapper.find('.ss360-suggests:not(.ss360-suggests--hidden)').length) { // the relevant result is visible
            performScroll();
        }
        else if (offset < groupWrapper.find('.ss360-suggests').length) {
            var newOffset = groupWrapper.find('.ss360-suggests').length;
            var callback = function () {
                if (groupWrapper.find('.ss360-suggests--hidden').length === 0) { // the relevant result is not visible but is loaded
                    groupWrapper.find('.ss360-more-results').remove();
                }
                ImageHandler(context);
            };
            context.core.prefetchResults({
                offset: newOffset,
                contentGroup: cg,
                query: query,
                callback: callback,
                filters: filterOptions
            }); // prefetch more results
            var pagesToReveal = Math.ceil(offset / pageSize) - 1;
            var hidden = groupWrapper.find('.ss360-suggests.ss360-suggests--hidden');
            var resultsToReveal = pagesToReveal * pageSize;
            for (var i = 0; i < resultsToReveal && i < hidden.length; i++) {
                sxQuery(hidden.get(i)).removeClass('ss360-suggests--hidden');
            }
            performScroll();
        }
        else { // the relevant result has to be fetched
            UiHelper.showLoadingAnimation();
            var toPreloadCount = (offset + 1) - groupWrapper.find('.ss360-suggests').length;
            var pagesToPreload_1 = Math.ceil(toPreloadCount / pageSize) + 1;
            var overrides = {
                num: offset + pagesToPreload_1 * pageSize,
                pageSize: pagesToPreload_1 * pageSize
            };
            groupWrapper.find('.ss360-suggests').removeClass('ss360-suggests--hidden');
            var loadedResultCount_1 = groupWrapper.find('.ss360-suggests').length;
            var callback = function () {
                var expectedResultCount = pageSize * pagesToPreload_1 + loadedResultCount_1;
                var allExpectedResultsLoaded = groupWrapper.find('.ss360-suggests').length >= expectedResultCount;
                var allSuggests = groupWrapper.find('.ss360-suggests');
                for (var i = 0; i < allSuggests.length; i++) {
                    var $suggest = sxQuery(allSuggests.get()[i]);
                    if (!allExpectedResultsLoaded || i < (allSuggests.length - pageSize)) {
                        $suggest.removeClass('ss360-suggests--hidden');
                    }
                    else {
                        $suggest.addClass('ss360-suggests--hidden');
                    }
                }
                if (groupWrapper.find('.ss360-suggests--hidden').length === 0) {
                    groupWrapper.find('.ss360-more-results').remove();
                }
                UiHelper.hideLoadingAnimation();
                performScroll();
                ImageHandler(context);
            };
            context.core.prefetchResults({
                offset: loadedResultCount_1,
                contentGroup: cg,
                query: query,
                callback: callback,
                overrides: overrides,
                filters: filterOptions
            });
        }
        // cleanup
        if (!context.isUseCookiesForOffsets()) {
            delete queryDict[context.getContentGroupQueryParam()];
            delete queryDict[context.getOffsetQueryParam()];
            delete queryDict[context.getPageLinkQueryParam()];
            NavigatorHelper.replaceState(queryDict, context.pluginConfiguration);
        }
        else {
            context.createCookie('-cg--c', '', 1 / 24);
            context.createCookie('-offset--c', '-1', 1 / 24);
            context.createCookie('-query--c', '', 1 / 24);
        }
    }
};
export default Results;

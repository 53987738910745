'use strict';
var ErrorScreenType;
(function (ErrorScreenType) {
    ErrorScreenType["SiteId"] = "siteId";
    ErrorScreenType["Offline"] = "offline";
    ErrorScreenType["Generic"] = "generic";
    ErrorScreenType["Blocked"] = "blocked";
    ErrorScreenType["IpBlocked"] = "ipBlocked";
})(ErrorScreenType || (ErrorScreenType = {}));
export default ErrorScreenType;

'use strict';
import Logger from '../../global/Logger';
import sxQuery from '../../sxQuery/sxQuery';
import NavigatorHelper from '../utils/NavigatorHelper';
var CategorySearch = {
    checkAndHandle: function (context) {
        var pluginConfiguration = context.pluginConfiguration;
        var categorySearch = pluginConfiguration.results.categorySearch;
        if (categorySearch !== undefined && categorySearch.active !== false) {
            try {
                var matchesCategoryPage = false;
                if (categorySearch.pageCssIdentifier !== undefined) {
                    matchesCategoryPage = document.querySelector(categorySearch.pageCssIdentifier) !== null;
                }
                var match = [];
                if (!matchesCategoryPage && categorySearch.urlPattern !== undefined) {
                    var regex = new RegExp(categorySearch.urlPattern);
                    match = window.location.href.match(regex);
                    matchesCategoryPage = match !== null && match[1] !== undefined;
                }
                if (!matchesCategoryPage) {
                    return false;
                }
                var filters = void 0;
                if (pluginConfiguration.filters.enabled) {
                    filters = NavigatorHelper.getFilters(pluginConfiguration.results, undefined, categorySearch.ignoredQueryParams);
                    if (filters.length === 0) {
                        filters = undefined;
                    }
                }
                var sort = void 0;
                var sortParamName = NavigatorHelper.escapeQueryParam(pluginConfiguration.results.sortingParamName);
                var queryDict = NavigatorHelper.buildQueryDict();
                if (queryDict[sortParamName] !== undefined) {
                    sort = NavigatorHelper.unescapeQueryParam(queryDict[sortParamName]);
                }
                var query_1 = match[1];
                var domCategory = false;
                if (categorySearch.breadcrumbElementSelector !== undefined) {
                    query_1 = sxQuery(categorySearch.breadcrumbElementSelector).get().map(function (el) { return sxQuery(el).text(); }).join('#');
                    domCategory = true;
                }
                else if (categorySearch.breadcrumbSelector !== undefined) {
                    // @ts-ignore
                    query_1 = sxQuery(categorySearch.breadcrumbSelector).text();
                    domCategory = true;
                }
                else if (categorySearch.spaceCharacter !== undefined) {
                    query_1 = query_1.replace(new RegExp(categorySearch.spaceCharacter, 'g'), ' ');
                }
                if (query_1 === undefined) {
                    return false;
                }
                if (!domCategory && query_1 && categorySearch.queryPathSeparator !== undefined) {
                    query_1 = query_1.split(categorySearch.queryPathSeparator).join('#');
                }
                if (categorySearch.replacementPatterns !== undefined) {
                    Object.keys(categorySearch.replacementPatterns).forEach(function (pattern) {
                        var replacement = categorySearch.replacementPatterns[pattern];
                        query_1 = query_1.replace(new RegExp(pattern, 'g'), replacement);
                    });
                }
                query_1 = query_1.trim();
                var searchQuery = query_1.toLowerCase() === (categorySearch.allIdentifier || '').toLowerCase() ? '_#' : "_#".concat(query_1);
                context.core.showResults(searchQuery, {
                    shouldPushState: false,
                    submitSource: 'category-search',
                    filters: filters,
                    sort: sort
                });
                return true;
            }
            catch (err) {
                Logger.error(err);
            }
        }
        return false;
    }
};
export default CategorySearch;

export default {
    createElement: function (data) {
        var el = document.createElement(data.tag);
        var classParts = (data.classList || []).filter(function (c) { return c !== undefined && c !== null && c.trim().length > 0; });
        el.className = classParts.join(' ');
        Object.keys(data.data || {}).forEach(function (key) {
            var val = data.data[key];
            if (val === undefined || val === null || val.trim().length === 0) {
                return;
            }
            el.dataset[key] = val;
        });
        Object.keys(data.style || {}).forEach(function (key) {
            var val = data.style[key];
            if (val === undefined || val === null || val.trim().length === 0) {
                return;
            }
            el.style[key] = val;
        });
        Object.keys(data.attrs || {}).forEach(function (key) {
            var val = data.attrs[key];
            if (val === undefined || val === null) {
                return;
            }
            el.setAttribute(key, val);
        });
        if (data.html) {
            if (data.html.indexOf('<') === -1 && data.html.indexOf('>') === -1 && data.html.indexOf('&') === -1 && data.textContent === undefined) {
                data.textContent = data.html;
            }
            else {
                el.innerHTML = data.html;
            }
        }
        if (data.textContent) {
            el.textContent = data.textContent;
        }
        if (data.children) {
            data.children.filter(function (child) { return child !== undefined; }).forEach(function (child) { return el.appendChild(child); });
        }
        return el;
    }
};

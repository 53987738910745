'use strict';
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var getBaseUrl = function (url) {
    if (url.indexOf('?') === -1) {
        return "".concat(url, "?");
    }
    if (url[url.length - 1] !== '&' && url[url.length - 1] !== '?') {
        return "".concat(url, "&");
    }
    return url;
};
var getDomain = function (url) {
    return url.split('/').splice(0, 3).join('/');
};
var UrlBuilder = /** @class */ (function () {
    function UrlBuilder(apiConfig, configuration) {
        this.apiConfig = apiConfig;
        this.searchBaseUrl = getBaseUrl(apiConfig.baseUrl);
        this.suggestBaseUrl = getBaseUrl(apiConfig.suggestBaseUrl);
        this.pluginConfiguration = configuration;
    }
    UrlBuilder.prototype.buildQueryUrl = function (params) {
        var limit = params.limit, sort = params.sort, include = params.include, exclude = params.exclude, tracking = params.tracking, query = params.query, offset = params.offset, limitPerGroup = params.limitPerGroup, groupResults = params.groupResults, filters = params.filters, isPlain = params.isPlain, includeQueries = params.includeQueries, sortComplex = params.sortComplex, addQueries = params.addQueries, excludeQueries = params.excludeQueries, ignoreOther = params.ignoreOther, disableAutoCorrect = params.disableAutoCorrect, sessionId = params.sessionId, additionalRequestParams = params.additionalRequestParams, activeGuidedQuestions = params.activeGuidedQuestions, presetFilters = params.presetFilters;
        var queryUrl = "".concat(this.searchBaseUrl, "site=").concat(this.pluginConfiguration.siteId, "&includeContent=true&limit=").concat(limit);
        if (sort !== undefined) {
            queryUrl += "&sort=".concat(encodeURIComponent(sort));
        }
        if (groupResults === false) {
            queryUrl += '&groupResults=false';
        }
        if (limitPerGroup) {
            queryUrl += '&limitPerGroup=true';
        }
        var results = this.pluginConfiguration.results;
        if (results.highlightQueryTerms === true) {
            queryUrl += '&highlightQueryTerms=true';
        }
        if (include !== undefined) {
            queryUrl += "&includeContentGroups=".concat(encodeURIComponent(JSON.stringify(include)));
        }
        if (exclude !== undefined || ignoreOther) {
            var excludeCopy = __spreadArray([], (exclude || []), true);
            if (ignoreOther && excludeCopy.indexOf('_') === -1) {
                excludeCopy.push('_');
            }
            queryUrl += "&excludeContentGroups=".concat(encodeURIComponent(JSON.stringify(excludeCopy)));
        }
        if (filters !== undefined) {
            queryUrl += "&filters=".concat(encodeURIComponent(JSON.stringify(filters)));
        }
        if (presetFilters && presetFilters.length > 0) {
            queryUrl += "&preSetFilters=".concat(encodeURIComponent(JSON.stringify(presetFilters)));
        }
        queryUrl += "&log=".concat(tracking);
        if (this.pluginConfiguration.filters.enabled) {
            queryUrl += '&filterOptions=true';
        }
        queryUrl += "&offset=".concat(offset);
        queryUrl += "&query=".concat(encodeURIComponent(query));
        var cacheSettings = this.pluginConfiguration._cacheQueries;
        if (cacheSettings !== undefined && (cacheSettings === true || cacheSettings === false)) {
            queryUrl += "&cache=".concat(cacheSettings, "&caching=").concat(cacheSettings);
        }
        if (!isPlain) {
            queryUrl += '&plain=false';
        }
        if (results.nameParsing && filters !== undefined) {
            queryUrl += '&nameParsing=true';
        }
        if (results.fuzziness !== undefined || params.fuzziness !== undefined) {
            queryUrl += "&fuzziness=".concat(results.fuzziness || params.fuzziness);
        }
        if (includeQueries !== undefined && includeQueries.length > 0) {
            queryUrl += "&includeQueries=".concat(encodeURIComponent(JSON.stringify(includeQueries)));
        }
        if (addQueries !== undefined && addQueries.length > 0) {
            queryUrl += "&addQueries=".concat(encodeURIComponent(JSON.stringify(addQueries)));
        }
        if (excludeQueries !== undefined && excludeQueries.length > 0) {
            queryUrl += "&excludeQueries=".concat(encodeURIComponent(JSON.stringify(excludeQueries)));
        }
        if (sortComplex !== undefined) {
            queryUrl += "&sortComplex=".concat(encodeURIComponent(JSON.stringify(sortComplex)));
        }
        if (disableAutoCorrect) {
            queryUrl += '&autocorrect=false';
        }
        if (this.pluginConfiguration.catalogId !== undefined) {
            queryUrl += "&catalogId=".concat(encodeURIComponent(this.pluginConfiguration.catalogId));
        }
        if (this.pluginConfiguration.userGroup !== undefined) {
            queryUrl += "&userGroup=".concat(encodeURIComponent(this.pluginConfiguration.userGroup));
        }
        if (this.pluginConfiguration.queryTag !== undefined) {
            queryUrl += "&queryTag=".concat(encodeURIComponent(this.pluginConfiguration.queryTag));
        }
        if (this.pluginConfiguration.similarContent.active && this.pluginConfiguration.similarContent.includeTag !== undefined) {
            queryUrl += "&includeTags=".concat(encodeURIComponent(JSON.stringify([this.pluginConfiguration.similarContent.includeTag])));
        }
        if (sessionId) {
            queryUrl += "&sessionId=".concat(encodeURIComponent(sessionId));
        }
        if (additionalRequestParams) {
            Object.keys(additionalRequestParams).forEach(function (key) {
                queryUrl = "".concat(queryUrl, "&").concat(encodeURIComponent(key), "=").concat(encodeURIComponent(additionalRequestParams[key]));
            });
        }
        if (activeGuidedQuestions && activeGuidedQuestions.length > 0) {
            queryUrl = "".concat(queryUrl, "&activeGuidedQuestions=").concat(encodeURIComponent(JSON.stringify(activeGuidedQuestions)));
        }
        queryUrl += '&kvtable=false';
        queryUrl += '&complexFilterFormat=true';
        return queryUrl;
    };
    UrlBuilder.prototype.buildSuggestionUrl = function (tracking) {
        var suggestions = this.pluginConfiguration.suggestions;
        if (!suggestions.show) {
            return '';
        }
        var queryUrl = "".concat(this.suggestBaseUrl, "log=").concat(tracking);
        if (suggestions.equalSearch) {
            queryUrl = queryUrl.replace('/sites/suggest', '/sites');
            queryUrl += '&includeContent=true';
        }
        if (this.pluginConfiguration.results.group === false) {
            queryUrl += '&groupResults=false';
        }
        var cacheSettings = this.pluginConfiguration._cacheQueries;
        if (cacheSettings !== undefined && (cacheSettings === true || cacheSettings === false)) {
            queryUrl += "&cache=".concat(cacheSettings, "&caching=").concat(cacheSettings);
        }
        var fuzziness = suggestions.fuzziness;
        if (fuzziness !== undefined) {
            queryUrl += "&fuzziness=".concat(fuzziness);
        }
        return queryUrl;
    };
    UrlBuilder.prototype.buildProductDataUrl = function (identifier, siteId) {
        return "".concat(getDomain(this.apiConfig.baseUrl), "/product-service?identifier=").concat(encodeURIComponent(identifier), "&projectId=").concat(encodeURIComponent(siteId));
    };
    UrlBuilder.prototype.setSearchBase = function (url) {
        this.searchBaseUrl = getBaseUrl(url);
    };
    UrlBuilder.prototype.setSuggestBase = function (url) {
        this.suggestBaseUrl = getBaseUrl(url);
    };
    return UrlBuilder;
}());
export default UrlBuilder;

'use strict';
import MediaQueryMatchType from '../../sxQuery/MediaQueryMatchType';
import sxQuery from '../../sxQuery/sxQuery';
import SxQueryUtils from '../../sxQuery/SxQueryUtils';
import DefaultConfig from '../configuration/DefaultConfig';
import SearchResultType from '../enums/SearchResultType';
import Helper from '../utils/Helper';
var MENU_BAR_MARKUP = '<ul class="ss360-nav__menu" role="menubar"></ul>';
var PRE_MARKUP = '<div class="ss360-nav__pre ss360-tc-b" role="presentation"></div>';
var POST_MARKUP = '<div class="ss360-nav__post ss360-tc-b" role="presentation"></div>';
var getDropdownMarkup = function (id) { return "<div class=\"ss360-nav__select-wrapper ss360-tc-bg\"><label class=\"ss360-nav__select-label ss360-tc-bg\" for=\"".concat(id, "\"><span></span></label><select class=\"ss360-nav__select\" id=\"").concat(id, "\"></select></div>"); };
var Navigation = /** @class */ (function () {
    function Navigation(data, context) {
        this.context = context;
        var pluginConfiguration = context.pluginConfiguration;
        var navigation = pluginConfiguration.layout.navigation;
        this.position = navigation.position;
        var maxTabs = this.position === 'top' ? 5 : 10;
        this._isTabbed = navigation.type === 'tabs';
        this.flatTabs = navigation.flatTabs;
        var forceTabs = navigation.forceTabs;
        this.entryCount = Object.keys(data.suggests).length;
        this._isDropdown = this._isTabbed && ((!forceTabs && this.entryCount > maxTabs) || (!context.pluginConfiguration.layout.navigation.preventDropdown && SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Max, 991)));
        if (navigation.fallbackToScroll && this._isDropdown && SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Min, 992)) {
            this._isTabbed = false;
            this._isDropdown = false;
        }
        if (navigation.flatTabs) {
            this._isDropdown = false;
        }
        this.forceFlex = !this._isDropdown && this.position === 'left';
        this.pluginConfiguration = pluginConfiguration;
        this.nav = null;
        this.headings = {};
        this.keyToName = {};
        this.keys = [];
        this.data = data;
    }
    Navigation.prototype.getNav = function () { return this.nav; };
    Navigation.prototype.shouldForceFlex = function () { return this.forceFlex; };
    Navigation.prototype.getPosition = function () { return this.position; };
    Navigation.prototype.isTabbed = function () { return this._isTabbed; };
    Navigation.prototype.isDropdown = function () { return this._isDropdown; };
    Navigation.prototype.show = function () {
        if (this.nav !== null) {
            this.nav.show();
        }
    };
    Navigation.prototype.hide = function () {
        if (this.nav !== null) {
            this.nav.hide();
        }
    };
    Navigation.prototype.setHeading = function (heading) {
        if (!this.pluginConfiguration.layout.navigation.forceCaption) {
            this.context.getExistingHeading().find('a').html(heading);
        }
    };
    Navigation.prototype.bindClickHandler = function (navEntry, safeKey, heading, searchResultType, searchQuery, contentGroupName) {
        var _this = this;
        navEntry.find('button').on('click', function () {
            var group = _this.context.getExistingLayer().find(".ss360-group-".concat(safeKey));
            var $toFocus = group.find('.ss360-suggests:first .ss360-suggests__link');
            // toggle tab if tabbed
            if (_this._isTabbed) {
                if (sxQuery(navEntry).hasClass('ss360-nav__menu-item--active')) { // same tab
                    return;
                }
                var layer = _this.context.getExistingLayer();
                layer.find('.ss360-group').removeClass('ss360-group--active');
                layer.find('nav li').removeClass('ss360-nav__menu-item--active');
                group.addClass('ss360-group--active');
                navEntry.addClass('ss360-nav__menu-item--active');
                if (_this.flatTabs) {
                    layer.find('.ss360-nav__entry').removeClass(_this._getFlatTabActiveClassName());
                    navEntry.find('.ss360-nav__entry').addClass(_this._getFlatTabActiveClassName());
                }
                _this.setHeading(heading);
            }
            if (!_this._isTabbed) {
                _this.scrollTo(group, searchResultType);
            }
            else {
                _this.persistOpenTab(searchQuery, safeKey);
            }
            _this._addClassNameToLayer(safeKey);
            _this.callNavigationClickCallback(contentGroupName);
            // focus first result
            if (_this.pluginConfiguration.results.focusResultBlock) {
                setTimeout(function () {
                    $toFocus.focus();
                }, 5);
            }
        });
    };
    Navigation.prototype._getFlatTabActiveClassName = function () {
        if (this.sufficientFlatTabContrast === undefined) {
            if (this.pluginConfiguration.style.accentColor === DefaultConfig.style.accentColor) {
                this.sufficientFlatTabContrast = true;
            }
            else {
                var lightAccent = Helper.lightenColorHex(this.pluginConfiguration.style.accentColor, 70);
                // @ts-ignore
                var contrast = Helper.getColorContrast(Helper.darkenColorHex(this.pluginConfiguration.style.accentColor, 16), lightAccent);
                // @ts-ignore
                var blackColorContrast = Helper.getColorContrast(lightAccent, '#4a4f62');
                // @ts-ignore
                var whiteColorContrast = Helper.getColorContrast(lightAccent, '#fefefe');
                this.sufficientFlatTabContrast = contrast > 0.7 || (blackColorContrast < contrast && whiteColorContrast < contrast);
                if (!this.sufficientFlatTabContrast) {
                    this.contrastingFlatTabColor = blackColorContrast > whiteColorContrast ? 'black' : 'white';
                }
            }
        }
        return this.sufficientFlatTabContrast ? 'ss360-acl-bg ss360-acl-b ss360-acd-c' : "ss360-acl-bg ss360-acl-b ss360-c-".concat(this.contrastingFlatTabColor);
    };
    Navigation.prototype._addClassNameToLayer = function (safeKey) {
        var $layer = this.context.getExistingLayer();
        if ($layer !== undefined) {
            this.keys.forEach(function (key) {
                $layer.removeClass("ss360-layer--group-".concat(key));
            });
            if (safeKey !== undefined) {
                $layer.addClass("ss360-layer--group-".concat(safeKey));
            }
        }
    };
    Navigation.prototype._buildNav = function (position) {
        if (position !== 'left' && position !== 'top') { // invalid position
            return null;
        }
        // class name
        var className = "ss360-nav ss360-nav--".concat(position);
        if (position === 'top') {
            className = "".concat(className, " ss360-n-section");
        }
        if (this._isTabbed) {
            if (this.flatTabs) {
                className += ' ss360-nav--flat';
            }
            else {
                className += ' ss360-nav--tabs ss360-flex ss360-flex--wrap';
                if (position === 'left') {
                    className += ' ss360-flex--column';
                }
            }
        }
        if (this._isDropdown) {
            className += ' ss360-dropdown ss360-flex--align-center ss360-flex--justify-center';
        }
        var navigationConfig = this.context.pluginConfiguration.layout.navigation;
        var markup = "<nav role=\"navigation\" class=\"".concat(className, "\">");
        if (!this._isDropdown) {
            markup += (this._isTabbed && !navigationConfig.flatTabs ? PRE_MARKUP : '') + MENU_BAR_MARKUP + (this._isTabbed && !navigationConfig.flatTabs ? POST_MARKUP : '');
        }
        else {
            markup += getDropdownMarkup(this.context.navDropdownId);
        }
        markup += '</nav>';
        var $node = sxQuery(markup);
        $node.attr('aria-label', navigationConfig.navigationLabel);
        return $node;
    };
    Navigation.prototype.build = function (layer, layerContent) {
        if (this.entryCount < 2)
            return;
        this.nav = this._buildNav(this.position);
        if (this.nav === null) {
            return;
        }
        if (!this._isDropdown && this.position !== 'top') {
            layerContent.addClass('ss360-layer__content--right');
        }
        if (this._isTabbed) {
            layerContent.addClass('ss360-layer__content--tabs');
        }
        if (this._isDropdown) {
            this.bindDropdown();
        }
        if (!this.forceFlex) {
            if (SxQueryUtils.indexInNodeList(layerContent.get(0), layer.children().get()) === -1 || this.nav.length > 1) {
                layer.append(this.nav);
            }
            else {
                var layerNode = layer.get(0);
                layerNode.insertBefore(this.nav.get(0), layerContent.get(0));
            }
        }
        else {
            var flexWrapper = this.context.getExistingFlexWrapper();
            if (flexWrapper.length === 0) {
                flexWrapper = sxQuery("<div class=\"ss360-flex ss360-flex--column-sm ss360-flex--align-stretch ss360-layer__flex-wrapper\" id=\"".concat(this.context.flexWrapperId, "\"></div>"));
                layer.append(flexWrapper);
            }
            flexWrapper.append(this.nav);
        }
    };
    // eslint-disable-next-line class-methods-use-this
    Navigation.prototype.scrollTo = function (el, searchResultType, offset) {
        if (offset === void 0) { offset = 0; }
        var animationSpeed = this.pluginConfiguration.style.animationSpeed;
        var layer = this.context.getExistingLayer();
        // scroll to first result if not tabbed
        if (searchResultType === SearchResultType.Layover) {
            var top = layer.find('.ss360-layer__content').scrollTop() + el.position().top;
            layer.find('.ss360-layer__content').animateScrollTop(top, animationSpeed);
        }
        if (searchResultType === 'fullscreen') {
            this.context.getExistingSearchConsole().animateScrollTop(el.offset().top, animationSpeed);
        }
        if (searchResultType === 'embed') {
            sxQuery('html, body').animateScrollTop(el.offset().top + offset, animationSpeed);
        }
    };
    Navigation.prototype.focusTab = function (contentGroupKey) {
        var _this = this;
        if (this.nav === null || !this._isTabbed) {
            return;
        }
        var targetGroup = this.context.getExistingLayer().find(".ss360-group-".concat(contentGroupKey));
        if (targetGroup.length === 0) {
            return;
        }
        if (this._isDropdown) {
            var navSelect = this.context.getExistingLayer().find('.ss360-nav__select');
            // @ts-ignore
            this.context.getExistingLayer().find('.ss360-nav__select-label').text(navSelect.find("option[value='".concat(contentGroupKey, "']")).text());
            navSelect.val(contentGroupKey);
        }
        else {
            this.context.getExistingLayer().find('.ss360-nav--tabs li').removeClass('ss360-nav__menu-item--active');
            this.context.getExistingLayer().find(".ss360-nav--tabs li[data-cgkey='".concat(contentGroupKey, "'")).addClass('ss360-nav__menu-item--active');
            if (this.flatTabs) {
                var navElement = this.context.getExistingLayer().find('.ss360-nav');
                if (navElement.length) {
                    var liElements = navElement.find('.ss360-nav__menu .ss360-nav__menu-item');
                    liElements.each(function (domElem) {
                        var element = sxQuery(domElem);
                        element.removeClass('ss360-nav__menu-item--active');
                        var button = element.find('.ss360-nav__entry');
                        if (button.length) {
                            button.removeClass(_this._getFlatTabActiveClassName());
                        }
                        if (element.attr('data-cgkey') === contentGroupKey) {
                            element.addClass('ss360-nav__menu-item--active');
                            if (button.length) {
                                button.addClass(_this._getFlatTabActiveClassName());
                            }
                        }
                    });
                }
            }
        }
        this.context.getExistingLayer().find('.ss360-group').removeClass('ss360-group--active');
        targetGroup.addClass('ss360-group--active');
        this.setHeading(this.headings[contentGroupKey]);
    };
    Navigation.prototype.addEntry = function (viewKey, safeKey, query, resultCount, idx, searchResultType, entryIdx, total, originalKey) {
        if (this.nav === null || viewKey === undefined || viewKey.length === 0) {
            return;
        }
        var aid = Math.round(10000 * Math.random());
        var navigation = this.pluginConfiguration.layout.navigation;
        // heading
        // @ts-ignore
        var headingTemplate = navigation.tabTitle || this.context.getExistingHeading().find('a').text();
        var rawHeadingString = safeKey !== 'ss360_all_results' ? headingTemplate : (navigation.allResultsTabTitle || '');
        var heading = rawHeadingString.replace(/#COUNT#/g, resultCount.toString()).replace(/#NAME#/g, viewKey).replace(/#QUERY#/g, query);
        this.headings[safeKey] = heading;
        this.keyToName[safeKey] = viewKey;
        this.keys.push(safeKey);
        var navEntry = this.createNavigationEntry(aid, viewKey, safeKey, navigation.showGroupResultCount, resultCount, entryIdx, total);
        // is current entry active?
        if (idx === 0 && this._isTabbed) {
            if (this._isDropdown) {
                this.nav.find('label').text(navEntry.text());
            }
            else {
                navEntry.addClass('ss360-nav__menu-item--active');
                if (this.flatTabs) {
                    navEntry.find('.ss360-nav__entry').addClass(this._getFlatTabActiveClassName());
                }
            }
            this.setHeading(heading);
        }
        if (!this._isDropdown) {
            this.bindClickHandler(navEntry, safeKey, heading, searchResultType, query, originalKey);
        }
        this.nav.find(this._isDropdown ? 'select' : 'ul').append(navEntry);
    };
    Navigation.prototype.onResultsRendered = function (isPopState) {
        var _this = this;
        if (isPopState === void 0) { isPopState = false; }
        if (this._isTabbed) {
            if (this.keys.length === 1) { // ensure the first tab is focused
                this.focusTab(this.keys[0]);
                this._addClassNameToLayer(undefined);
            }
            else {
                var lastOpenTab = this.context.readLastOpenTab();
                var navigation = this.pluginConfiguration.layout.navigation;
                var query = this.data.query;
                if (lastOpenTab !== null && lastOpenTab !== undefined && (lastOpenTab.split('<#>')[0] === query || isPopState || navigation.keepOpenTab)) { // should we reopen a certain tab, e.g. after a page refresh? in case of a popstate we also keep the current tab open
                    var groupName = lastOpenTab.split('<#>')[1];
                    this.focusTab(groupName);
                    this._addClassNameToLayer(this.keys.length > 0 ? groupName : undefined);
                }
                else {
                    this._addClassNameToLayer(this.keys.length > 0 ? this.keys[0] : undefined);
                }
            }
            if (this.nav !== null && !this._isDropdown) {
                SxQueryUtils.requestAnimationFrame(function () {
                    var firstOffsetTop = _this.nav.find('.ss360-nav__menu-item:first').offset().top;
                    var lastOffsetTop = _this.nav.find('.ss360-nav__menu-item:last-of-type').offset().top;
                    if (Math.abs(Math.floor(firstOffsetTop) - Math.floor(lastOffsetTop)) > 3) { // some offset because of border
                        _this.nav.find('.ss360-nav__pre, .ss360-nav__post').hide();
                    }
                });
            }
        }
    };
    Navigation.prototype.persistOpenTab = function (searchQuery, safeKey) {
        var cookieValue = "".concat(searchQuery, "<#>").concat(safeKey);
        if (this.pluginConfiguration.allowCookies) { // store open tab
            this.context.createCookie('-open-tab', cookieValue, 1 / 24);
        }
        this.context.setLastOpenTab(cookieValue);
    };
    Navigation.prototype.callNavigationClickCallback = function (contentGroupName) {
        var navigationClick = this.pluginConfiguration.callbacks.navigationClick;
        if (navigationClick !== undefined && (typeof navigationClick) === 'function') {
            navigationClick(contentGroupName);
        }
        this.context.notify('navigationChange', contentGroupName);
    };
    Navigation.prototype.bindDropdown = function () {
        var _this = this;
        var $select = this.nav.find('select');
        var $label = this.nav.find('label');
        $select.on('focus', function () {
            $label.addClass('ss360-nav__select-label--focus');
        });
        $select.on('focusout', function () {
            $label.removeClass('ss360-nav__select-label--focus ss360-nav__select-label--open');
        });
        $select.on('change', function (e) {
            var text = sxQuery(e.target.options[e.target.selectedIndex]).text();
            $label.text(text);
        });
        $select.on('click', function () {
            if (sxQuery($label).hasClass('ss360-nav__select-label--open')) {
                $label.removeClass('ss360-nav__select-label--open');
            }
            else {
                $label.addClass('ss360-nav__select-label--open');
            }
        });
        $select.on('change', function (e) {
            var val = e.target.value;
            var group = _this.context.getExistingLayer().find(".ss360-group-".concat(val));
            _this.context.getExistingLayer().find('nav li').removeClass('ss360-nav__menu-item--active');
            _this.context.getExistingLayer().find('.ss360-group').removeClass('ss360-group--active');
            group.addClass('ss360-group--active');
            _this.setHeading(_this.headings[val]);
            _this.persistOpenTab(_this.data.query, val);
            _this.callNavigationClickCallback(_this.keyToName[val]);
        });
    };
    Navigation.prototype.createNavigationEntry = function (aid, viewKey, safeKey, shouldShowCount, count, idx, total) {
        if (this._isDropdown) {
            var selectOptionText = viewKey + (shouldShowCount ? (" (".concat(count, ")")) : '');
            return sxQuery("<option class=\"ss360-nav__select-option\" value=\"".concat(safeKey, "\">").concat(selectOptionText, "</option>"));
        }
        var entry = sxQuery("<li class=\"ss360-nav__menu-item ss360-tc-b ss360-n-li\" data-cgkey=\"".concat(safeKey, "\" role=\"none\"></li>"));
        var btn = sxQuery("<button type=\"button\" id=\"navelement".concat(aid, "\" class=\"ss360-nav__entry ss360-n-button ss360-tc-b ss360-ac-bg--hover\" role=\"menuitem\">").concat(viewKey, "</button>"));
        if (this.flatTabs) {
            entry.removeClass("ss360-tc-b");
            btn.removeClass("ss360-ac-bg--hover ss360-tc-b");
            btn.addClass("ss360-acl-b--hover ss360-acl-bg--hover");
            this._getFlatTabActiveClassName();
            if (this.contrastingFlatTabColor !== undefined) {
                btn.addClass("ss360-c-".concat(this.contrastingFlatTabColor, "--hover"));
            }
        }
        if (!this._isTabbed) {
            btn.addClass('ss360-tc-c ss360-ac-bg--hover ss360-ac-b--hover');
        }
        entry.append(btn);
        if (shouldShowCount) {
            entry.find('button').append("<span class=\"ss360-nav__result-count\">".concat(count, "</span>"));
        }
        if (idx === 0) {
            btn.addClass('ss360-nav__entry--first');
        }
        if (idx === total - 1) {
            btn.addClass('ss360-nav__entry--last');
        }
        return entry;
    };
    return Navigation;
}());
export default Navigation;

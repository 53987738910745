'use strict';
import { ECOM_DEV_SEARCH_BASE, ECOM_SEARCH_BASE, ECOM_STAGE_SEARCH_BASE, ECOM_ZOOVU_SEARCH_BASE, SEARCH_BASE } from './api/ApiConfig';
import UrlBuilder from './api/UrlBuilder';
import sxQuery from '../sxQuery/sxQuery';
import Reporter from './reporter/Reporter';
import SS360Insights from '../insights/insights';
import Logger from '../global/Logger';
import StringHelper from '../global/StringHelper';
import SxQueryUtils from '../sxQuery/SxQueryUtils';
import Helper from './utils/Helper';
var Context = /** @class */ (function () {
    function Context(urlBuilder, configuration, core, layer, isDefaultInstance) {
        if (isDefaultInstance === void 0) { isDefaultInstance = true; }
        this.filterViewGroupCache = {
            groupByKey: {},
            activeGroups: [],
            layer: undefined,
            searchQueries: {},
            expandedGroups: {},
            lastInteractedGroupKey: undefined,
            viewGroupByKey: {}
        };
        this.urlBuilder = urlBuilder;
        this.pluginConfiguration = configuration;
        this._isSmart404 = false;
        this.core = core;
        this.layer = layer;
        this.reporter = new Reporter(this.pluginConfiguration);
        this.isClosing = false;
        this.configDefaults = {};
        this.areConfigDefaults = true;
        this.listeners = {};
        this.isDefaultInstance = isDefaultInstance;
        this.alias = StringHelper.getSafeKey(configuration.alias || Math.round(Math.random() * 1000).toString());
        this.layerId = this.generateId('ss360-layer');
        this.consoleId = this.generateId('ss360-search-console');
        this.filteredResultsId = this.generateId('ss360-filtered-results');
        this.filterLayerId = this.generateId('ss360-filter');
        this.customSearchBoxId = this.generateId('ss360-custom-searchbox');
        this.errorLayerId = this.generateId('ss360-error-layer');
        this.customSearchId = this.generateId('ss360-custom-search');
        this.customSearchButtonId = this.generateId('ss360-custom-searchbutton');
        this.resultsId = this.generateId('ss360-results');
        this.layer404Id = this.generateId('ss360-404-layer');
        this.noResultsLayerId = this.generateId('ss360-no-results');
        this.flexWrapperId = this.generateId('ss360-flex-wrapper');
        this.sortingId = this.generateId('ss360-sorting');
        this.searchResultHeadingId = this.generateId('ss360-search-result-heading');
        this.navDropdownId = this.generateId('ss360-nav-dropdown');
        this.queryCorrectionId = this.generateId('ss360-query-correction');
        this.uniboxId = this.generateId('unibox-suggest-box');
        this.uniboxDarkenLayerId = this.generateId('unibox-darken-layer');
        this.specialUniboxId = this.generateId('unibox-suggest-box-special');
        this.specialSearchBoxId = this.generateId('unibox-special-searchbox');
        this.uniboxControlsId = this.generateId('unibox-controls-description');
        this.uniboxStatusMessageId = this.generateId('unibox-status-message');
        this.uniboxInvisibleId = this.generateId('unibox-invisible');
        this.specialUniboxWrapId = this.generateId('unibox-special');
        this.specialUniboxBackgroundId = this.generateId('unibox-special-transition-background');
        this.specialUniboxHiddenContentId = this.generateId('unibox-special-hidden-content');
        this.isZoovu = false;
    }
    Context.prototype.generateId = function (prefix) {
        return (this.isDefaultInstance ? prefix : "".concat(prefix, "--").concat(StringHelper.getSafeKey(this.alias)));
    };
    Context.prototype.getSearchResultType = function () {
        return Helper.getIntegrationType(this.pluginConfiguration.results);
    };
    Context.prototype.setIsSmart404 = function (val) { this._isSmart404 = val; };
    Context.prototype.isSmart404 = function () { return this._isSmart404; };
    Context.prototype.setIsClosing = function (val) {
        this.isClosing = val;
    };
    Context.prototype.hasInsights = function () {
        return this.insights !== undefined;
    };
    Context.prototype.createInsights = function (isLandingPageSearch) {
        var _this = this;
        if (isLandingPageSearch === void 0) { isLandingPageSearch = false; }
        var cookiePostFix = this.isDefaultInstance ? '' : "--".concat(this.alias);
        this.insights = new SS360Insights(this.pluginConfiguration.siteId, this.pluginConfiguration.allowCookies, this.pluginConfiguration.tracking, cookiePostFix, this.getIsZoovu() || this.pluginConfiguration.tracking.addZoovuCid);
        if (isLandingPageSearch && this.pluginConfiguration.landingPageAnalyticsQuery) {
            this.insights.trackedQuery = this.pluginConfiguration.landingPageAnalyticsQuery;
        }
        if (this.isDefaultInstance && !this.getIsZoovu()) {
            window.SS360Insights = this.insights;
        }
        else if (this.isDefaultInstance) {
            window.ZoovuSearchInsights = this.insights;
        }
        var external = this.pluginConfiguration.tracking.external;
        if (external !== undefined) {
            if (external.checkout !== undefined || external.productDetailPage !== undefined) {
                this.insights.setHasCheckoutTracking(true);
            }
            // @ts-ignore
            import(/* webpackChunkName: "externalTracker" */ '../insights/externalTracker').then(function (_a) {
                var ExternalTracker = _a.default;
                if (_this.externalTracker !== undefined) {
                    _this.externalTracker.unbindAll();
                }
                _this.externalTracker = new ExternalTracker(_this.insights, _this.pluginConfiguration.tracking.external);
            });
        }
    };
    Context.prototype.getInsights = function () {
        return this.insights;
    };
    Context.prototype.disableInsights = function () {
        if (this.isDefaultInstance) {
            delete window.SS360Insights;
            delete window.ZoovuSearchInsights;
        }
        this.insights = undefined;
        if (this.externalTracker !== undefined) {
            try {
                this.externalTracker.unbindAll();
            }
            catch (err) {
                // ccl
            }
        }
    };
    Context.prototype.notify = function (event, value) {
        var listeners = this.listeners;
        if (listeners[event] !== undefined) {
            listeners[event].forEach(function (listener) {
                try {
                    listener.handler(value);
                }
                catch (ex) {
                    Logger.warn(ex);
                }
            });
        }
    };
    Context.prototype.subscribe = function (event, listenerKey, handler) {
        var listener = { key: listenerKey, handler: handler };
        var listeners = this.listeners;
        var listenerFound = false;
        if (listeners[event] === undefined) {
            listeners[event] = [];
        }
        else {
            listeners[event].forEach(function (existingListener) {
                if (existingListener.key === listenerKey) {
                    existingListener.handler = handler;
                    listenerFound = true;
                }
            });
        }
        if (!listenerFound) {
            listeners[event].push(listener);
        }
    };
    Context.prototype.getExistingLayer = function () {
        return sxQuery("#".concat(this.layerId));
    };
    Context.prototype.getExistingSearchConsole = function () {
        return sxQuery("#".concat(this.consoleId));
    };
    Context.prototype.getExistingFilteredResultsLayer = function () {
        return sxQuery("#".concat(this.filteredResultsId));
    };
    Context.prototype.getExistingFilterLayer = function () {
        return sxQuery("#".concat(this.filterLayerId));
    };
    Context.prototype.getExistingCustomSearchBox = function () {
        return sxQuery("#".concat(this.customSearchBoxId));
    };
    Context.prototype.getExistingCustomSearch = function () {
        return sxQuery("#".concat(this.customSearchId));
    };
    Context.prototype.getExistingLayer404 = function () {
        return sxQuery("#".concat(this.layer404Id));
    };
    Context.prototype.getExistingNoResultsLayer = function () {
        return sxQuery("#".concat(this.noResultsLayerId));
    };
    Context.prototype.getExistingFlexWrapper = function () {
        return sxQuery("#".concat(this.flexWrapperId));
    };
    Context.prototype.getExistingSorting = function () {
        return sxQuery("#".concat(this.sortingId));
    };
    Context.prototype.getExistingHeading = function () {
        return sxQuery("#".concat(this.searchResultHeadingId));
    };
    Context.prototype.createCookie = function (baseKey, value, days) {
        var prefix = this.getIsZoovu() ? 'zoovu' : 'ss360';
        var cookieKey = this.generateId("".concat(prefix).concat(baseKey));
        SxQueryUtils.createCookie(cookieKey, value, days);
    };
    Context.prototype.readCookie = function (baseKey) {
        var prefixes = this.getIsZoovu() ? ['zoovu', 'ss360'] : ['ss360', 'zoovu'];
        for (var i = 0; i < prefixes.length; i++) {
            var cookieKey = this.generateId("".concat(prefixes[i]).concat(baseKey));
            var value = SxQueryUtils.readCookie(cookieKey);
            if (value !== null) {
                return value;
            }
        }
        return null;
    };
    Context.prototype.storeObject = function (baseKey, value) {
        var key = this.generateId(baseKey);
        SxQueryUtils._storeObject(key, value);
    };
    Context.prototype.readObject = function (baseKey, defaultValue) {
        var key = this.generateId(baseKey);
        return SxQueryUtils._readObject(key, defaultValue);
    };
    Context.prototype.getInsightsEventKey = function () {
        return this.generateId('ss360Insights');
    };
    Context.prototype.getLastOpenTab = function () {
        return this.lastOpenTab;
    };
    Context.prototype.setLastOpenTab = function (tab) {
        this.lastOpenTab = tab;
    };
    Context.prototype.readLastOpenTab = function () {
        var lastOpenTab = this.readCookie('-open-tab');
        if (lastOpenTab === null && !this.pluginConfiguration.allowCookies) {
            lastOpenTab = this.getLastOpenTab();
        }
        return lastOpenTab;
    };
    Context.prototype.setIsZoovu = function (isZoovu) {
        this.isZoovu = isZoovu;
    };
    Context.prototype.getIsZoovu = function () {
        return this.isZoovu;
    };
    Context.prototype.isUseCookiesForOffsets = function () {
        return this.pluginConfiguration.allowCookies && window.location.search.indexOf("".concat(this.getPageLinkQueryParam(), "=1")) === -1;
    };
    Context.prototype.isEcom = function () {
        return this.pluginConfiguration.ecom || this.pluginConfiguration.ecomDev || this.pluginConfiguration.ecomStage;
    };
    Context.prototype.getPageLinkQueryParam = function () {
        return this.isEcom() ? 'searchPageLink' : 'ss360PageLink';
    };
    Context.prototype.getOffsetQueryParam = function () {
        return this.isEcom() ? 'searchOffset' : 'ss360Offset';
    };
    Context.prototype.getContentGroupQueryParam = function () {
        return this.isEcom() ? 'searchContentGroup' : 'ss360ContentGroup';
    };
    Context.prototype.getSearchBase = function () {
        var baseUrl = SEARCH_BASE;
        if (this.pluginConfiguration.baseUrl !== undefined) {
            try {
                return this.pluginConfiguration.baseUrl.match(/^(https?:\/\/[^\/]+)/)[0];
            }
            catch (err) {
                // ccl
            }
        }
        if (this.pluginConfiguration.ecom) {
            baseUrl = this.getIsZoovu() ? ECOM_ZOOVU_SEARCH_BASE : ECOM_SEARCH_BASE;
        }
        else if (this.pluginConfiguration.ecomDev) {
            baseUrl = ECOM_DEV_SEARCH_BASE;
        }
        else if (this.pluginConfiguration.ecomStage) {
            baseUrl = ECOM_STAGE_SEARCH_BASE;
        }
        return baseUrl;
    };
    Context.prototype.getLastRequestedGuidedQuestionTicketId = function () {
        return this.lastRequestedGuidedQuestionTicketId;
    };
    Context.prototype.setLastRequestedGuidedQuestionTicketId = function (ticketId) {
        this.lastRequestedGuidedQuestionTicketId = ticketId;
    };
    Context.prototype.getFilterViewGroupCache = function () {
        return this.filterViewGroupCache;
    };
    return Context;
}());
export function createContext(apiConfig, configuration, core, layer, isDefaultInstance) {
    if (isDefaultInstance === void 0) { isDefaultInstance = true; }
    return new Context(new UrlBuilder(apiConfig, configuration), configuration, core, layer, isDefaultInstance);
}
export default Context;

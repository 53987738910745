'use strict';
var ApiConfig = /** @class */ (function () {
    function ApiConfig(base, ecom, siteId, isFullUrl, suggestBaseUrl) {
        if (ecom === void 0) { ecom = false; }
        if (isFullUrl === void 0) { isFullUrl = false; }
        if (!ecom) {
            this.baseUrl = isFullUrl ? base : "".concat(base, "/sites");
            this.suggestBaseUrl = isFullUrl && suggestBaseUrl !== undefined ? suggestBaseUrl : "".concat(base, "/sites/suggest");
        }
        else {
            this.baseUrl = isFullUrl ? base : "".concat(base, "/search?projectId=").concat(siteId);
            this.suggestBaseUrl = isFullUrl && suggestBaseUrl !== undefined ? suggestBaseUrl : "".concat(base, "/search/suggestions?projectId=").concat(siteId);
        }
    }
    return ApiConfig;
}());
export default ApiConfig;
export var SEARCH_BASE = 'https://global.sitesearch360.com';
export var ECOM_SEARCH_BASE = 'https://ecom.sitesearch360.com';
export var ECOM_ZOOVU_SEARCH_BASE = 'https://api.search.zoovu.com';
export var ECOM_DEV_SEARCH_BASE = 'https://dev-api-v3.semknox.com';
export var ECOM_STAGE_SEARCH_BASE = 'https://stage-api-v3.semknox.com';

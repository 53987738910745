'use strict';
var DataSourceType;
(function (DataSourceType) {
    DataSourceType["UrlParam"] = "urlParam";
    DataSourceType["UrlRegex"] = "urlRegex";
    DataSourceType["DomElement"] = "domElement";
    DataSourceType["Getter"] = "getter";
    DataSourceType["DomObject"] = "domJson";
    DataSourceType["WindowObject"] = "windowJson";
    DataSourceType["Static"] = "static";
})(DataSourceType || (DataSourceType = {}));
export default DataSourceType;

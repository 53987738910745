'use strict';
import Logger from '../../global/Logger';
import TrackingProvider from '../enums/TrackingProvider';
var setPageGtm = function (currentUrl) {
    // @ts-ignore
    if (window.dataLayer) {
        // @ts-ignore
        window.dataLayer.push({
            event: 'VirtualPageview',
            category: 'search',
            virtualPageURL: currentUrl,
            virtualPageTitle: document.title
        });
    }
};
var Reporter = /** @class */ (function () {
    function Reporter(config) {
        this.config = config;
    }
    Reporter.prototype.setPage = function (currentUrl) {
        var providers = this.config.tracking.providers;
        // now inform tracking systems
        if (providers.indexOf(TrackingProvider.GA) > -1) {
            this.setPageGa(currentUrl);
        }
        if (providers.indexOf(TrackingProvider.GTM) > -1) {
            setPageGtm(currentUrl);
        }
    };
    Reporter.prototype.getGa = function () {
        if (this.config.tracking.gaAlias !== undefined) {
            // @ts-ignore
            return window[this.config.tracking.gaAlias];
        }
        // @ts-ignore
        return window.ga;
    };
    // Google Analytics
    Reporter.prototype.setPageGa = function (currentUrl) {
        var gaTracker = this.getGa();
        if (gaTracker) {
            gaTracker('set', 'page', currentUrl);
        }
    };
    Reporter.prototype.shouldTrack = function (context) {
        if (this.shouldTrackCache === undefined) {
            this.shouldTrackCache = this.config.tracking.logQueries && context.readCookie('-tracking') !== '0';
        }
        return this.shouldTrackCache;
    };
    Reporter.prototype.reportSerp = function (query, context) {
        if (!this.shouldTrack(context)) {
            return;
        }
        var shouldReport = true;
        var trackingConfig = this.config.tracking;
        var searchCallback = trackingConfig.searchCallback;
        if (searchCallback !== undefined && typeof searchCallback === 'function') {
            try {
                shouldReport = searchCallback.call(this, query) !== false;
            }
            catch (err) {
                Logger.warn(err);
            }
        }
        if (!shouldReport) {
            return;
        }
        // build URL
        var queryParamName = this.config.results.searchQueryParamName;
        var urlBase = window.location.href.split(window.location.origin)[1] || '';
        if (urlBase === '/') {
            urlBase = '';
        }
        if (queryParamName === undefined || queryParamName === '') {
            queryParamName = 'ss360Query';
        }
        try {
            urlBase = urlBase.replace(new RegExp("".concat(queryParamName, "=[^&]+"), 'g'), '').replace(/\?&/g, '?').replace(/&&/g, '&');
        }
        catch (ex) {
            // ccl
        }
        if (urlBase.indexOf('?') === urlBase.length - 1) {
            urlBase = urlBase.substring(0, urlBase.length - 1);
        }
        var queryChar = urlBase.indexOf('?') === -1 ? '?' : '&';
        var currentUrl = trackingConfig.ignoreQueryParam ? urlBase : "".concat(urlBase).concat(queryChar).concat(queryParamName, "=").concat(encodeURI(query).toLowerCase());
        this.setPage(currentUrl);
        var gaTracker = this.getGa();
        if (trackingConfig.providers.indexOf(TrackingProvider.GA) > -1 && (!!gaTracker)) {
            gaTracker('send', 'pageview');
            gaTracker(function (tracker) {
                if (tracker === undefined) {
                    gaTracker.getAll().forEach(function (tr) {
                        tr.set('page', currentUrl);
                        tr.send('pageview');
                    });
                }
            });
        }
    };
    return Reporter;
}());
export default Reporter;

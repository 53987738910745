'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import UiHelper from '../ui/UiHelper';
var Layer = /** @class */ (function () {
    function Layer() {
    }
    Layer.prototype.get = function (uiBuilder) {
        if (this.layer === undefined || !this.layer.isMounted()) {
            if (uiBuilder !== undefined) {
                sxQuery("#".concat(uiBuilder.context.layerId)).remove();
                this.layer = uiBuilder.buildLayer();
                var htmlBody = sxQuery('body');
                htmlBody.append(this.layer);
            }
            else {
                this.layer = sxQuery("#".concat(uiBuilder.context.layerId));
            }
        }
        return this.layer;
    };
    Layer.prototype.resetClassName = function () {
        if (this.layer !== undefined) {
            this.layer.removeClass('ss360-flex ss360-flex--wrap ss360-skeleton--filter-left');
        }
    };
    Layer.prototype.empty = function () {
        this.resetClassName();
        if (this.layer !== undefined) {
            this.layer.find('.ss360-nav').remove();
            this.layer.find('.ss360-group').remove();
            this.layer.find('.ss360-query-correction').remove();
            this.layer.find('.ss360-no-results').remove();
            this.layer.find('.ss360-sorting').remove();
            this.layer.find('.ss360-skeleton-part').remove();
            this.layer.find('.ss360-error').remove();
        }
    };
    Layer.prototype.getCustomSearchBox = function () {
        return this.layer !== undefined ? this.layer.find('.ss360-custom-search__searchbox:not(.ss360-no-results__search-box)') : sxQuery([]);
    };
    Layer.prototype.getLayerContent = function () {
        return this.layer !== undefined ? this.layer.find('.ss360-layer__content') : sxQuery([]);
    };
    Layer.prototype.getOrResetLayerContent = function (searchResultType, layoutConfig, headingId) {
        var layerContent = this.getLayerContent();
        if (layerContent.length === 0) {
            layerContent = UiHelper.createLayerContent(searchResultType, layoutConfig, headingId);
        }
        else {
            this.resetLayerContent();
        }
        return layerContent;
    };
    Layer.prototype.resetLayerContent = function () {
        var content = this.getLayerContent();
        content.each(function (node) {
            node.className = 'ss360-layer__content ss360-n-section';
        });
        if (this.layer !== undefined) {
            this.layer.find('.ss360-banner').remove();
        }
    };
    Layer.prototype.getHeadlineNode = function () {
        return this.layer !== undefined ? this.layer.find('.ss360-layer__heading') : sxQuery([]);
    };
    return Layer;
}());
export default Layer;

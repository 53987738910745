'use strict';
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import sxQuery from '../sxQuery/sxQuery';
import StringHelper from './StringHelper';
import Logger from './Logger';
var BLOCK_START_RE = /{{#[^{}#]+}}/g;
var INVERTED_BLOCK_START_RE = new RegExp(/{{!#[^{}!#]+}}/g);
var BLOCK_CONTENT_RE = new RegExp(/{{#([^{}]+)}}/);
var INVERTED_BLOCK_CONTENT_RE = new RegExp(/{{!#([^{}]+)}}/);
var INLINE_BLOCK_RE = new RegExp(/{{#[^{}#]+#}}/);
var INLINE_BLOCK_CONTENT_RE = new RegExp(/{{#([^{}#]+)#}}/);
var INVERTED_INLINE_BLOCK_RE = new RegExp(/{{!#[^{}#!]+#!}}/);
var INVERTED_INLINE_BLOCK_CONTENT_RE = new RegExp(/{{!#([^{}#!]+)#!}}/);
var ESCAPED_VARIABLE_CONTENT_RE = /{{([^{}]+)}}/;
var UNESCAPED_VARIABLE_CONTENT_RE = /{{{([^{}]+)}}}/;
var ESCAPED_VARIABLE_RE = /{{([^{}]+)}}/g;
var UNESCAPED_VARIABLE_RE = /{{{([^{}]+)}}}/g;
var interpolate = function (templateString, context) {
    templateString = replaceBlocks(templateString, context);
    templateString = replaceInlineBlocks(templateString, context);
    templateString = replaceVariables(templateString, context, undefined);
    return templateString;
};
var replaceBlocks = function (templateString, context) {
    templateString = replaceBlock(templateString, context, BLOCK_START_RE, BLOCK_CONTENT_RE, false);
    templateString = replaceBlock(templateString, context, INVERTED_BLOCK_START_RE, INVERTED_BLOCK_CONTENT_RE, true);
    return templateString;
};
var replaceBlock = function (templateString, context, re, keyRe, isInverted) {
    var blockStarts = templateString.match(re);
    if (blockStarts !== null) {
        for (var i = 0; i < blockStarts.length; i++) {
            try {
                var match = blockStarts[i];
                var blockStart = templateString.indexOf(match);
                if (blockStart !== -1) { // this was a nested block
                    var blockKey = match.match(keyRe)[1];
                    var blockEndTemplate = "{{/".concat(blockKey, "}}");
                    var blockEnd = blockStart + templateString.substring(blockStart).indexOf(blockEndTemplate) + blockEndTemplate.length;
                    var fullBlock = templateString.substring(blockStart, blockEnd);
                    var block = fullBlock.replace(match, '').replace(blockEndTemplate, '');
                    var innerContext = getContextPart(context, blockKey);
                    var shouldDoReplacements = isInverted ? isFalsy(innerContext) : !isFalsy(innerContext);
                    if (shouldDoReplacements) {
                        var content = '';
                        if ((innerContext instanceof Array) && !isInverted) { // not inverted, as this is an empty list
                            var isPlainArray = block.indexOf('@value') !== -1 // check for @value, just to make sure you don't break anything (and make it possibly quicker)
                                && innerContext.reduce(function (acc, prop) {
                                    if (!acc)
                                        return acc;
                                    var type = (typeof prop);
                                    return type === 'string' || type === 'number';
                                }, true);
                            if (isPlainArray) {
                                innerContext = innerContext.map(function (prop) { return ({ '@value': prop }); });
                            }
                            for (var j = 0; j < innerContext.length; j++) {
                                content += interpolate(block, innerContext[j]);
                            }
                        }
                        else if ((innerContext instanceof Object)) {
                            content = interpolate(block, innerContext);
                        }
                        else {
                            content = interpolate(block, context);
                        }
                        templateString = templateString.replace(fullBlock, content);
                    }
                    else {
                        templateString = templateString.replace(fullBlock, '');
                    }
                }
            }
            catch (err) {
                Logger.warn(err);
            }
        }
    }
    return templateString;
};
var replaceInlineBlocks = function (templateString, context) {
    templateString = replaceInlineBlock(templateString, context, INLINE_BLOCK_RE, INLINE_BLOCK_CONTENT_RE, false);
    templateString = replaceInlineBlock(templateString, context, INVERTED_INLINE_BLOCK_RE, INVERTED_INLINE_BLOCK_CONTENT_RE, true);
    return templateString;
};
var replaceInlineBlock = function (templateString, context, re, innerRe, isInverted) {
    var matcherRe;
    try {
        matcherRe = new RegExp(re, 'g');
    }
    catch (ex) { // IE11
        // eslint-disable-next-line prefer-template
        matcherRe = re + 'g';
    }
    var matches = templateString.match(matcherRe);
    if (matches !== null) {
        for (var i = 0; i < matches.length; i++) {
            try {
                var match = matches[i];
                var inner = match.match(innerRe)[1].split(':');
                var pathPart = inner.splice(0, 1)[0].trim();
                var valuePart = inner.join(':').trim();
                var contextPart = getContextPart(context, pathPart);
                var value = (isInverted ? isFalsy(contextPart) : !isFalsy(contextPart)) ? valuePart : '';
                templateString = templateString.replace(match, value);
            }
            catch (err) {
                Logger.warn(err);
            }
        }
    }
    return templateString;
};
var isFalsy = function (condition) { return condition === undefined || condition === '' || condition === false || condition === null || condition === 'FALSE' || condition === 'false' || ((condition instanceof Array) && condition.length === 0); };
var getContextPart = function (context, pathString) {
    var pathParts = pathString.split('.');
    var tempContext = context;
    for (var i = 0; i < pathParts.length; i++) {
        var pathPart = pathParts[i].trim();
        var idx = pathPart[pathPart.length - 1] === ']' && pathPart.indexOf('[') !== -1 ? parseInt((pathPart.match(/\[(\d)\]/) || [])[1], 10) : undefined;
        if (idx !== undefined) {
            pathPart = pathPart.split('[')[0];
        }
        tempContext = (tempContext instanceof Object) ? tempContext[pathPart] : undefined;
        if (tempContext === undefined) {
            return undefined;
        }
        if (idx !== undefined && (tempContext instanceof Array)) {
            tempContext = tempContext[idx];
        }
        else if (idx !== undefined) {
            return undefined;
        }
    }
    return tempContext;
};
var replaceVariables = function (templateString, context, escaped) {
    if (escaped === undefined) {
        templateString = replaceVariables(templateString, context, false); // unescaped first!
        templateString = replaceVariables(templateString, context, true);
        return templateString;
    }
    var re = escaped ? ESCAPED_VARIABLE_RE : UNESCAPED_VARIABLE_RE;
    var matchingParts = templateString.match(re);
    if (matchingParts !== null) {
        for (var i = 0; i < matchingParts.length; i++) {
            var matchingPart = matchingParts[i];
            templateString = templateString.split(matchingPart).join(getStringValue(matchingPart.match(escaped ? ESCAPED_VARIABLE_CONTENT_RE : UNESCAPED_VARIABLE_CONTENT_RE)[1], context, escaped));
        }
    }
    return templateString;
};
var getStringValue = function (path, context, escape) {
    var value = getContextPart(context, path);
    value = value !== undefined && !(value instanceof Object) ? value : '';
    if (escape) {
        value = StringHelper.escapeHtml(value);
    }
    return value;
};
var TemplateRenderer = /** @class */ (function () {
    function TemplateRenderer(templateConfig, defaultCss) {
        if (defaultCss === void 0) { defaultCss = true; }
        this.templateConfig = templateConfig;
        this.templateString = templateConfig.template; // TODO: maybe the rendering could be speeded up by precompiling/presplitting the template
        this.preRenderCallback = templateConfig.preRenderCallback;
        this.templateBuiltCallback = templateConfig.templateBuiltCallback;
        this.postRenderCallback = templateConfig.postRenderCallback;
        this.variableReplacementPattern = templateConfig.variableReplacementPattern;
        this.dataPointDefaults = templateConfig.dataPointDefaults || {};
        this.GlobalStore = {};
        this.defaultCss = defaultCss;
    }
    TemplateRenderer.prototype.render = function (suggest, contentGroup, isHidden, isSuggestion, isRelatedContent) {
        var _this = this;
        if (isHidden === void 0) { isHidden = false; }
        if (isSuggestion === void 0) { isSuggestion = false; }
        if (isRelatedContent === void 0) { isRelatedContent = false; }
        var itemTemplate = this.templateString;
        var suggestObj = __assign(__assign({}, suggest.suggest), { variants: suggest.variants });
        var preRenderCallback = this.templateConfig.preRenderCallback;
        try {
            if (preRenderCallback !== undefined) {
                preRenderCallback(suggestObj, this.GlobalStore, contentGroup);
            }
        }
        catch (err) {
            Logger.warn(err);
        }
        // data point hash
        suggestObj.dataPointHash = suggest.getDataPoints().reduce(function (acc, dataPoint) {
            if (dataPoint.key === undefined || dataPoint.key === null || dataPoint.value === undefined || dataPoint.value === null)
                return acc;
            var hashKey = dataPoint.key.split(' ').map(function (keyPart, idx) {
                if (idx === 0 && keyPart.length > 0) {
                    return keyPart.toLowerCase();
                }
                if (keyPart.length > 0) {
                    return "".concat(keyPart[0].toUpperCase()).concat(keyPart.substring(1).toLowerCase());
                }
                return '';
            }).join('');
            if (acc[hashKey] === undefined) {
                acc[hashKey] = [];
            }
            acc[hashKey].push(dataPoint.value);
            return acc;
        }, {});
        suggestObj.isResultTypeCustom = suggest.getType() === 'custom';
        suggestObj.image = suggest.getImage(true);
        suggestObj.fallbackImage = suggest.getImage(false);
        Object.keys(this.dataPointDefaults).forEach(function (key) {
            if (suggestObj.dataPointHash[key] === undefined) {
                suggestObj.dataPointHash[key] = [_this.dataPointDefaults[key]];
            }
        });
        var identifierAttr = suggest.getIdentifier() !== undefined ? " data-product-identifier=\"".concat(suggest.getIdentifier(), "\"") : '';
        var inner = interpolate(itemTemplate, suggestObj);
        if (isSuggestion) {
            var dataTag = '';
            var link = suggestObj.link || suggest.getLink();
            var name = suggestObj.name || suggest.getName();
            if (link !== undefined) {
                dataTag += "data-href=\"".concat(link, "\"");
            }
            if (name !== undefined) {
                dataTag += " data-content=\"".concat(name, "\"");
            }
            itemTemplate = "<article class=\"unibox__selectable\" ".concat(dataTag).concat(identifierAttr, ">").concat(inner, "</article>");
        }
        else if (!isRelatedContent) {
            var styleAttr = !isHidden || this.defaultCss ? '' : ' style="display:none;"';
            itemTemplate = "<li class=\"ss360-suggests".concat(isHidden ? ' ss360-suggests--hidden' : '', "\"").concat(styleAttr).concat(identifierAttr, ">").concat(inner, "</li>");
        }
        else {
            itemTemplate = "<article class=\"ss360-related-content__item\">".concat(inner, "</article>");
        }
        if (this.templateBuiltCallback !== undefined) {
            try {
                var result = this.templateBuiltCallback(itemTemplate, suggestObj, this.GlobalStore, contentGroup);
                if (result !== undefined) {
                    itemTemplate = result;
                }
            }
            catch (err) {
                Logger.warn(err);
            }
        }
        if (this.variableReplacementPattern !== undefined) {
            try {
                itemTemplate = itemTemplate.replace(new RegExp(this.variableReplacementPattern, 'g'), '');
            }
            catch (err) {
                // ccl
            }
        }
        var item = sxQuery(itemTemplate);
        if (this.postRenderCallback !== undefined) {
            try {
                this.postRenderCallback(item, suggestObj, this.GlobalStore, contentGroup);
            }
            catch (err) {
                Logger.warn(err);
            }
        }
        return item;
    };
    return TemplateRenderer;
}());
export default TemplateRenderer;

import SearchSuggest from "../model/SearchSuggest";
import UiHelper from "../ui/UiHelper";
import sxQuery from "../../sxQuery/sxQuery";
import StringHelper from "../../global/StringHelper";
import Icons from "./Icons";
import Helper from "../utils/Helper";
import SxQueryUtils from "../../sxQuery/SxQueryUtils";
import MediaQueryMatchType from "../../sxQuery/MediaQueryMatchType";
import { ZoeApiConfigurationRegion } from "../interface/ZoeApiConfigurationRegion";
import NavigatorHelper from "../utils/NavigatorHelper";
var MAX_PRODUCTS = 4;
var Comparison = /** @class */ (function () {
    function Comparison(context) {
        this.products = [];
        this.dev = false;
        this.logQueries = true;
        this.removeListeners = [];
        this.disableStateChangeListeners = [];
        this.isCollapsed = false;
        this.isRendered = false;
        this.storeKey = 'ss360-comparison';
        this.queryParamName = 'ss360Comparison';
        this.lastQuery = '';
        this.id = context.generateId('ss360-comparison-bar');
        this.context = context;
        var _a = context.pluginConfiguration, siteId = _a.siteId, ecomDev = _a.ecomDev, comparison = _a.comparison;
        this.logQueries = context.getInsights() !== undefined;
        this.siteId = siteId;
        this.dev = ecomDev;
        this.configuration = comparison;
        if (comparison._storePrefix) {
            this.storeKey = "ss360-comparison_".concat(comparison._storePrefix);
        }
        this.products = (context.readObject(this.storeKey) || []).map(function (val) { return new SearchSuggest(val); });
        this.lastQuery = context.pluginConfiguration.allowCookies ? context.readCookie("".concat(this.storeKey, "_lastQuery")) || '' : '';
        this.isCollapsed = this.context.readCookie('comparison-collapsed') === '1';
        if (this.context.getIsZoovu()) {
            this.queryParamName = 'zComparison';
        }
        var queryDict = NavigatorHelper.buildQueryDict();
        if (queryDict[this.queryParamName] !== undefined) {
            var identifiers = queryDict[this.queryParamName].split(',');
            if (identifiers.length > 1) {
                this.render(identifiers);
            }
        }
    }
    Comparison.prototype.show = function () {
        if (this.isRendered) {
            return;
        }
        this.renderBar();
        this.isRendered = true;
        return;
    };
    Comparison.prototype.addProduct = function (product, query) {
        if (this.isInComparison(product.getIdentifier())) {
            return;
        }
        if (this.context.pluginConfiguration.allowCookies) {
            this.lastQuery = query;
            this.context.createCookie("".concat(this.storeKey, "_lastQuery"), query);
        }
        var wasDisabled = this.isComparisonDisabled();
        this.products.push(product);
        this.saveProducts();
        this.renderBar();
        if (!wasDisabled && this.products.length >= MAX_PRODUCTS) {
            this._notifyDisableStateChanged(true);
        }
    };
    Comparison.prototype.remove = function (identifier) {
        var wasDisabled = this.isComparisonDisabled();
        this.products = this.products.filter(function (p) { return p.getIdentifier() !== identifier; });
        this.saveProducts();
        this.renderBar();
        this._notifyRemove(identifier);
        if (wasDisabled) {
            this._notifyDisableStateChanged(false);
        }
        if (this.products.length === 1) {
            this.remove(this.products[0].getIdentifier());
        }
    };
    Comparison.prototype.isComparisonDisabled = function () {
        return this.products.length >= MAX_PRODUCTS;
    };
    Comparison.prototype.isInComparison = function (identifier) {
        for (var i = 0; i < this.products.length; i++) {
            if (this.products[i].getIdentifier() === identifier) {
                return true;
            }
        }
        return false;
    };
    Comparison.prototype.saveProducts = function () {
        this.context.storeObject(this.storeKey, this.products.map(function (p) { return p.plain; }));
    };
    Comparison.prototype.onRemove = function (removeListener) {
        this.removeListeners.push(removeListener);
    };
    Comparison.prototype.removeAll = function () {
        var wasDisabled = this.isComparisonDisabled();
        this.products = [];
        this.saveProducts();
        this.renderBar();
        this._notifyRemove('*');
        if (wasDisabled) {
            this._notifyDisableStateChanged(false);
        }
    };
    Comparison.prototype.onDisableChange = function (listener) {
        this.disableStateChangeListeners.push(listener);
    };
    Comparison.prototype._notifyRemove = function (identifier) {
        this.removeListeners.forEach(function (listener) { return listener(identifier); });
    };
    Comparison.prototype._notifyDisableStateChanged = function (state) {
        this.disableStateChangeListeners.forEach(function (listener) { return listener(state); });
    };
    Comparison.prototype.renderBar = function () {
        var _this = this;
        sxQuery("#".concat(this.id)).remove();
        if (this.products.length <= 1) {
            return;
        }
        var minWidth = undefined;
        var wrap = sxQuery("<section class=\"ss360-n-section ss360-comparison-bar\" id=\"".concat(this.id, "\" role=\"section\" aria-label=\"Compared Products\"></section>"));
        var header = sxQuery('<div class="ss360-comparison-bar__header ss360-ac-bg"></div>');
        header.append("<span class=\"ss360-comparison-bar__title\">\n            <span class=\"ss360-comparison-bar__title-part ss360-comparison-bar__title-part--title\">".concat(this.configuration.comparisonLabel, "</span>\n            <span class=\"ss360-comparison-bar__title-part ss360-comparison-bar__title-part--separator\">:&nbsp;</span>\n            <span class=\"ss360-comparison-bar__title-part ss360-comparison-bar__title-part--count\">").concat(this.configuration.comparisonCount.replace(/#COUNT#/g, "".concat(this.products.length)), "</span>\n        </span>"));
        var collapseButton = sxQuery("<button type=\"button\" class=\"ss360-n-button ss360-comparison-bar__collapse\">".concat(Icons.getSvgIcon(Icons.SIMPLE_ARROW, '#fefefe', undefined, 24, 24), "</button>"));
        collapseButton.attr('aria-label', this.isCollapsed ? 'Expand' : 'Collapse');
        header.append(collapseButton);
        var updateCollapsedState = function () {
            if (_this.isCollapsed && !SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Max, 767)) {
                minWidth = Math.round(wrap.width());
            }
            else {
                minWidth = undefined;
            }
            if (minWidth !== undefined) {
                wrap.css('minWidth', "".concat(minWidth, "px"));
            }
            else {
                wrap.css('minWidth', null);
            }
            var collapsedClassName = 'ss360-comparison-bar--collapsed';
            var collapsedButtonClassName = 'ss360-comparison-bar__collapse--collapsed';
            if (_this.isCollapsed) {
                wrap.addClass(collapsedClassName);
                collapseButton.addClass(collapsedButtonClassName);
            }
            else {
                wrap.removeClass(collapsedClassName);
                collapseButton.removeClass(collapsedButtonClassName);
            }
        };
        var storeCollapsedState = function () {
            _this.context.createCookie('comparison-collapsed', _this.isCollapsed ? '1' : '0');
        };
        updateCollapsedState();
        header.on('click', function () {
            _this.isCollapsed = !_this.isCollapsed;
            updateCollapsedState();
            storeCollapsedState();
        });
        collapseButton.on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            _this.isCollapsed = !_this.isCollapsed;
            updateCollapsedState();
            storeCollapsedState();
        });
        wrap.append(header);
        var list = sxQuery('<ul class="ss360-comparison-bar__list"></ul>');
        this.products.forEach(function (product) {
            // TODO: make them clickable, add delete button
            var $li = sxQuery('<li class="ss360-comparison-bar__list-item"></li>');
            var $deleteButton = sxQuery("<button class=\"ss360-n-button ss360-comparison-bar__delete-item\" aria-label=\"Remove\" type=\"button\">".concat(Icons.getSvgIcon(Icons.CROSS, '#5F7287'), "</button>"));
            $deleteButton.on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                _this.remove(product.getIdentifier());
            });
            var $a = sxQuery("<a href=\"".concat(product.getLink(), "\" target=\"_blank\" class=\"ss360-comparison-bar__item\"></a>"));
            $a.append("<img class=\"ss360-comparison-bar__item-image\" src=\"".concat(product.getImage(true), "\" loading=\"lazy\">"));
            $a.append("<span class=\"ss360-comparison-bar__item-name\">".concat(StringHelper.escapeHtml(product.getName()), "</span>"));
            $li.append($a);
            $li.append($deleteButton);
            list.append($li);
        });
        wrap.append(list);
        var buttonsWrap = sxQuery('<div class="ss360-comparison-bar__actions"></div>');
        if (this.products.length > 4) {
            buttonsWrap.append("<span class=\"ss360-comparison-bar__limit-warning\">".concat(this.configuration.tooManyProductsLabel, "</span>"));
        }
        var compareButton = sxQuery("<button class=\"ss360-n-button ss360-comparison-bar__button ss360-comparison-bar__button--compare ss360-ac-bg ss360-ac-b ss360-acd-bg--hover\" type=\"button\">".concat(this.configuration.buttonLabel, "</button>"));
        compareButton.on('click', function () {
            _this.render();
        });
        buttonsWrap.append(compareButton);
        wrap.append(buttonsWrap);
        sxQuery('body').append(wrap);
    };
    Comparison.prototype.render = function (identifiers) {
        var _this = this;
        if (identifiers === undefined || identifiers.length === 0) {
            identifiers = this.products.map(function (p) { return p.getIdentifier(); });
        }
        UiHelper.renderZoeScript(false, this.context.isEcom() && this.context.pluginConfiguration.ecomDev);
        var configuration = this.configuration;
        sxQuery('zoovu-comparison').remove();
        sxQuery('zoovu-comparison-styles').remove();
        var element = document.createElement('zoovu-comparison');
        element.setAttribute('project-id', this.siteId);
        element.setAttribute('skus', identifiers.join(','));
        element.setAttribute('show-cta', "".concat(configuration.showCta));
        element.setAttribute('table-style', "".concat(configuration.tableStyle));
        if (this.dev) {
            element.setAttribute('dev', 'true');
        }
        if (!this.logQueries) {
            element.setAttribute('prevent-tracking', 'true');
        }
        element.addEventListener('close', function () {
            document.body.removeChild(element);
            NavigatorHelper.removeQueryParam(_this.context.pluginConfiguration.results, _this.queryParamName);
        });
        element.addEventListener('remove-product', function (event) {
            _this.remove(event.detail.identifier);
        });
        if (configuration.ctaText) {
            element.setAttribute('cta-text', configuration.ctaText);
        }
        if (configuration.heading) {
            element.setAttribute('heading', configuration.heading);
        }
        if (configuration.ctaCallback) {
            element.addEventListener('cta-click', function (event) {
                configuration.ctaCallback(event.detail);
            });
        }
        if (this.context.pluginConfiguration.userGroup) {
            element.setAttribute('user-group', this.context.pluginConfiguration.userGroup);
        }
        if (configuration.showDifferencesLabel) {
            element.setAttribute('show-differences-label', configuration.showDifferencesLabel);
        }
        if (configuration.zoeApiKey) {
            element.setAttribute('zoe-api-key', configuration.zoeApiKey);
            element.setAttribute('zoe-locale', configuration.zoeLocale || 'en-US');
            element.setAttribute('zoe-region', configuration.zoeRegion || ZoeApiConfigurationRegion.ORCA);
        }
        if (configuration.context) {
            element.setAttribute('context', configuration.context);
        }
        document.body.appendChild(element);
        var s = document.createElement('style');
        s.setAttribute('id', 'zoovu-comparison-styles');
        var accentColor = this.context.pluginConfiguration.style.accentColor;
        s.innerText = "zoovu-comparison {--product-title-color:".concat(accentColor, ";--cta-background-color:").concat(accentColor, ";--cta-hover-background-color:").concat(Helper.darkenColorHex(accentColor, 16), ";}zoovu-comparison::part(toggle){--toggle-track-active-color:").concat(accentColor, ";}");
        document.body.appendChild(s);
        NavigatorHelper.addQueryParam(this.queryParamName, this.products.map(function (p) { return p.getIdentifier(); }).join(','));
    };
    Comparison.prototype.destroy = function () {
        sxQuery("#".concat(this.id)).remove();
    };
    return Comparison;
}());
export default Comparison;

'use strict';
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import StringHelper from '../../global/StringHelper';
import sxQuery from '../../sxQuery/sxQuery';
import UiHelper from './UiHelper';
import Icons from '../components/Icons';
import SuggestType from '../enums/SuggestType';
import LayoutType from '../enums/LayoutType';
import DomElementBuilder from '../../sxQuery/DomElementBuilder';
var renderTitle = function (suggest, link, hiddenParts, headingId, linksOpenNewTab, headingLevel, resultsConfiguration) {
    if (hiddenParts.title === 'all') {
        return {
            title: undefined
        };
    }
    var cleanName = suggest.getName() || '';
    if (cleanName.indexOf(' ') !== -1) { // &nbsp;
        cleanName = cleanName.split(' ').join(' ');
    }
    var variantsCount = suggest.variants.length;
    var variantImageCount = suggest.getUniqueVariantImageCount();
    var header = DomElementBuilder.createElement({
        tag: 'header',
        classList: ['ss360-n-section', 'ss360-suggests__header', variantImageCount <= 1 && variantsCount > 0 ? 'ss360-suggests__header--has-variants-label' : undefined]
    });
    var heading = DomElementBuilder.createElement({
        tag: 'span',
        classList: ['ss360-suggests__link-wrap'],
        attrs: {
            role: 'heading',
            id: headingId,
            title: cleanName,
            'aria-level': "".concat(headingLevel)
        },
        children: [
            DomElementBuilder.createElement({
                tag: 'a',
                classList: ['ss360-suggests__link', 'ss360-ac-c'],
                attrs: {
                    target: linksOpenNewTab ? '_blank' : undefined,
                    href: link !== undefined && link !== null && link.length > 0 ? link : undefined
                },
                html: cleanName
            })
        ]
    });
    header.appendChild(heading);
    var variantsLabel;
    if (variantImageCount <= 1 && variantsCount > 0) {
        var label = variantsCount === 1 ? resultsConfiguration.variantsCountLabelSingular : resultsConfiguration.variantsCountLabel;
        variantsLabel = DomElementBuilder.createElement({
            tag: 'span',
            classList: ['ss360-suggests__variants-count'],
            html: label.replace(/#COUNT#/g, variantsCount.toString())
        });
    }
    return {
        title: header,
        variantsLabel: variantsLabel
    };
};
var cleanTrackingLink = function (link) {
    var domains = ['ssp-dev.search.zoovu.com', 'ssp-stage.search.zoovu.com', 'ssp.search.zoovu.com'];
    var contains = false;
    domains.forEach(function (domain) {
        contains = contains || link.indexOf(domain) !== -1;
    });
    if (!contains) {
        return link;
    }
    var queryPart = link.split('?')[1];
    if (queryPart === undefined) {
        return link;
    }
    var params = queryPart.split('&');
    for (var i = 0; i < params.length; i++) {
        var kv = params[i].split('=');
        if (kv.length === 2 && kv[0] === 'r') {
            return decodeURIComponent(kv[1]);
        }
    }
    return link;
};
var renderLink = function (suggest, stripHttp, hiddenParts, fullLink, linksOpenNewTab) {
    if (hiddenParts.url === 'all') {
        return undefined;
    }
    var link = suggest.getLink(); // get from suggest to ignore appended query params
    if (link && link.indexOf('ssp') !== -1) {
        link = cleanTrackingLink(link);
    }
    try {
        link = decodeURI(link);
    }
    catch (err) {
        console.error("Malformed URL ".concat(link));
    }
    if (stripHttp && link) {
        if (link.toLowerCase().indexOf('https://') === 0) {
            link = link.substring(8);
        }
        else if (link.toLowerCase().indexOf('http://') === 0) {
            link = link.substring(7);
        }
    }
    return DomElementBuilder.createElement({
        tag: 'a',
        classList: ['ss360-suggests__url'],
        attrs: {
            tabindex: '-1',
            href: fullLink,
            'aria-hidden': 'true',
            target: linksOpenNewTab ? '_blank' : undefined
        },
        textContent: link
    });
};
var renderImage = function (suggest, hiddenParts, resultSettings, groupHasImages, link, lazyLoadImages) {
    if (lazyLoadImages === void 0) { lazyLoadImages = true; }
    var hasImage = suggest.hasImage();
    if (hiddenParts.images === 'all' || !groupHasImages || (!hasImage && resultSettings.placeholderImage === null)) {
        return undefined;
    }
    var image = suggest.getImage(true) || '';
    var createPagerButton = function (modifier, label) {
        var button = DomElementBuilder.createElement({
            tag: 'button',
            classList: ['ss360-n-button', 'ss360-suggests__carousel-pager', "ss360-suggests__carousel-pager--".concat(modifier)],
            attrs: {
                type: 'button',
                title: label
            }
        });
        var icon = Icons.getSvgIconNode(Icons.SIMPLE_ARROW, '#4a4f62', 'ss360-suggests__carousel-pager-icon');
        button.appendChild(icon);
        return button;
    };
    // Wrapper
    var hasVariants = resultSettings.showVariants && suggest.getUniqueVariantImageCount() > 1;
    var images = [];
    if (hasImage) {
        images.push(image);
    }
    else if (resultSettings.placeholderImage !== undefined) {
        images.push(resultSettings.placeholderImage);
    }
    if (resultSettings.showAlternativeImages) {
        suggest.getImages().forEach(function (img) {
            if (images.indexOf(img) === -1) {
                images.push(img);
            }
        });
    }
    var hasMultipleImages = images.length > 1;
    var wrap = DomElementBuilder.createElement({
        tag: 'div',
        classList: ['ss360-suggests__image-container', hasVariants ? 'ss360-suggests__image-container--has-variants' : undefined,
            hasMultipleImages ? 'ss360-suggests__image-container--has-multiple-images' : undefined, images.length === 0 ? ' ss360-suggests__image-container--empty' : undefined],
    });
    var imageTarget = wrap;
    var carouselWrap;
    if (hasMultipleImages) {
        carouselWrap = DomElementBuilder.createElement({
            tag: 'div',
            classList: ['ss360-suggests__carousel-wrap']
        });
        carouselWrap.appendChild(createPagerButton('prev', 'Show PreviousImage'));
        imageTarget = DomElementBuilder.createElement({
            tag: 'div',
            classList: ['ss360-suggests__carousel']
        });
        carouselWrap.appendChild(imageTarget);
        wrap.appendChild(carouselWrap);
    }
    if (!hasImage && images.length === 0) {
        images.push(null);
    }
    var isDocIcon = UiHelper.isDocIcon(image);
    var isPdfIcon = isDocIcon && image.indexOf('pdf.svg') !== -1;
    var isYoutubeVideo = suggest.getType() === 'YOUTUBE_VIDEO';
    images.forEach(function (image, idx) {
        var a = DomElementBuilder.createElement({
            tag: 'a',
            classList: ['ss360-suggests__image-wrap', !hasImage && image === null && idx === 0 ? 'ss360-suggests__image-wrap--empty' : undefined,
                isYoutubeVideo && idx === 0 ? 'ss360-suggests__image-wrap--video' : undefined],
            attrs: {
                'aria-hidden': 'true',
                'tabindex': '-1',
                href: link || '#',
                target: resultSettings.linksOpenNewTab || suggest.getType() === SuggestType.YOUTUBE_VIDEO ? '_blank' : undefined
            }
        });
        if (image !== null) {
            var img = DomElementBuilder.createElement({
                tag: 'img',
                attrs: {
                    loading: lazyLoadImages ? 'lazy' : undefined,
                    src: image,
                    alt: '',
                    'aria-hidden': 'true',
                    role: 'presentation',
                    'aria-label': suggest.getName()
                },
                data: {
                    fallbackSrc: suggest.isResizedImage() && idx === 0 ? suggest.getOriginalImage() : undefined
                },
                classList: ['ss360-suggests__image', isDocIcon && idx === 0 ? 'ss360-suggests__image--docs' : undefined,
                    lazyLoadImages ? 'ss360-suggests__image--lazy ss360-shimmer' : undefined, isPdfIcon && idx === 0 ? 'ss360-suggests__image--pdf' : undefined]
            });
            a.appendChild(img);
        }
        if (image !== null && isYoutubeVideo) {
            a.appendChild(DomElementBuilder.createElement({
                tag: 'i',
                attrs: {
                    role: 'presentation',
                    'aria-hidden': 'true'
                },
                classList: ['ss360-icon', 'ss360-icon--play']
            }));
        }
        if (hasMultipleImages) {
            var imageWrap = DomElementBuilder.createElement({
                tag: 'div',
                classList: ['ss360-suggests__carousel-entry']
            });
            imageWrap.appendChild(a);
            imageTarget.appendChild(imageWrap);
        }
        else {
            imageTarget.appendChild(a);
        }
    });
    if (hasMultipleImages && carouselWrap !== undefined) {
        carouselWrap.appendChild(createPagerButton('next', 'Show Next Image'));
        var carouselNav = DomElementBuilder.createElement({
            tag: 'nav',
            classList: ['ss360-suggests__carousel-nav', 'ss360-n-section'],
            attrs: {
                role: 'navigation',
                'aria-label': 'Browse through images'
            }
        });
        var carouselUl_1 = DomElementBuilder.createElement({
            tag: 'ul',
            classList: ['ss360-suggests__carousel-nav-ul'],
            attrs: {
                role: 'menubar'
            }
        });
        images.forEach(function (img, idx) {
            carouselUl_1.appendChild(DomElementBuilder.createElement({
                tag: 'li',
                classList: ['ss360-n-li', 'ss360-suggests__carousel-nav-li'],
                attrs: {
                    role: 'none'
                },
                children: [
                    DomElementBuilder.createElement({
                        tag: 'button',
                        classList: ['ss360-suggests__carousel-nav-entry', 'ss360-n-button', idx === 0 ? ' ss360-suggests__carousel-nav-entry--active' : undefined],
                        attrs: {
                            type: 'button',
                            role: 'menuitem',
                            'aria-label': "".concat(idx + 1, ". image"),
                            'aria-selected': "".concat(idx === 0)
                        },
                        data: {
                            idx: "".concat(idx)
                        }
                    })
                ]
            }));
        });
        carouselNav.appendChild(carouselUl_1);
        carouselWrap.appendChild(carouselNav);
    }
    if (hasVariants) {
        var variantUl_1 = DomElementBuilder.createElement({
            tag: 'ul',
            classList: ['ss360-suggests__variants']
        });
        var createVariantWrap = function (modifier, label) {
            var icon = Icons.getSvgIconNode(Icons.SIMPLE_ARROW, '#4a4f62', 'ss360-suggests__variant-arrow-icon');
            return DomElementBuilder.createElement({
                tag: 'li',
                classList: ['ss360-suggests__variant-arrow-wrap', "ss360-suggests__variant-arrow-wrap--".concat(modifier)],
                children: [
                    DomElementBuilder.createElement({
                        tag: 'button',
                        classList: ['ss360-n-button', 'ss360-suggests__variant-arrow', "ss360-suggests__variant-arrow--".concat(modifier)],
                        attrs: {
                            type: 'button',
                            'aria-label': label
                        },
                        children: [icon]
                    })
                ]
            });
        };
        variantUl_1.appendChild(createVariantWrap('prev', 'Scroll Left'));
        suggest.getAllVariantImages().forEach(function (entry, idx) {
            var img = DomElementBuilder.createElement({
                tag: 'img',
                classList: ['ss360-suggests__variant-image', lazyLoadImages ? 'ss360-suggests__variant-image--lazy' : undefined],
                attrs: {
                    loading: lazyLoadImages ? 'lazy' : undefined,
                    src: entry.optimizedImage || entry.image,
                    alt: '',
                    'aria-hidden': 'true',
                    role: 'presentation'
                },
                data: {
                    fallbackSrc: entry.optimizedImage && entry.optimizedImage !== entry.image ? entry.image : undefined
                }
            });
            var btn = DomElementBuilder.createElement({
                tag: 'button',
                classList: ['ss360-suggests__variant-button', 'ss360-ac-b--hover', 'ss360-n-button'],
                attrs: {
                    type: 'button',
                    title: entry.name,
                    role: 'radio',
                    'aria-checked': "".concat(idx === 0)
                },
                data: {
                    productIdentifier: entry.identifier,
                    link: entry.link
                },
                children: [img]
            });
            variantUl_1.appendChild(DomElementBuilder.createElement({
                tag: 'li',
                classList: ['ss360-suggests__variant'],
                children: [btn]
            }));
        });
        variantUl_1.appendChild(createVariantWrap('next', 'Scroll Right'));
        wrap.appendChild(variantUl_1);
    }
    return wrap;
};
var renderDataPoints = function (dataPoints, dataPointsConfig, queryTags, layout) {
    var keys = [];
    var excludedDataPoints = dataPointsConfig.exclude;
    var includedDataPoints = dataPointsConfig.include;
    var singleDataPoints = dataPointsConfig.single;
    var displayType = dataPointsConfig.displayType, unique = dataPointsConfig.unique, showOnlyPassOns = dataPointsConfig.showOnlyPassOns, sort = dataPointsConfig.sort, addTypeClass = dataPointsConfig.addTypeClass;
    var dataPointTypes = {};
    if (sort !== undefined && sort.length > 0) {
        dataPoints.sort(function (a, b) {
            var aIdx = sort.indexOf(a.key);
            var bIdx = sort.indexOf(b.key);
            if (aIdx === bIdx) {
                return 0;
            }
            if (aIdx === -1) {
                return 1;
            }
            if (bIdx === -1) {
                return -1;
            }
            return aIdx < bIdx ? -1 : 1;
        });
    }
    var addedValues = {};
    var groupedDataPoints = dataPoints.reduce(function (acc, dataPoint) {
        var show = dataPoint.show !== undefined ? dataPoint.show : dataPoint.type === 'PASS_ON';
        var key = dataPoint.key;
        if (addedValues[key] === undefined) {
            addedValues[key] = [];
        }
        var isIncluded = show || includedDataPoints.indexOf(key) !== -1;
        if ((show || isIncluded) && excludedDataPoints.indexOf(key) === -1 && (!showOnlyPassOns || dataPoint.type === undefined || dataPoint.type === 'PASS_ON')) {
            if (acc[key] === undefined) {
                acc[key] = [];
            }
            if (keys.indexOf(key) === -1) { // to keep order
                keys.push(key);
            }
            if (addTypeClass) {
                var value = dataPoint.value;
                if (dataPointTypes[key] === undefined) {
                    dataPointTypes[key] = {};
                }
                if (dataPointTypes[key][value] === undefined) {
                    dataPointTypes[key][value] = [];
                }
                if (dataPointTypes[key][value].indexOf(dataPoint.type) === -1) {
                    dataPointTypes[key][value].push(dataPoint.type);
                }
            }
            if (acc[key].length === 0 || singleDataPoints.indexOf(key) === -1) {
                var value = dataPoint.value;
                var valueNodes_1 = [];
                if (value && value.toString().indexOf('<') !== -1) {
                    var nodes = sxQuery(value);
                    if (nodes.length === 0) {
                        valueNodes_1.push(document.createTextNode(value));
                    }
                    else {
                        nodes.get().forEach(function (node) {
                            valueNodes_1.push(node);
                        });
                    }
                }
                else {
                    valueNodes_1.push(document.createTextNode(value));
                }
                if (!unique || addedValues[key].indexOf(value) === -1) {
                    addedValues[key].push(value);
                    if (displayType && dataPoint.type !== undefined) {
                        acc[key].push({
                            value: value,
                            children: __spreadArray(__spreadArray([], valueNodes_1, true), [
                                DomElementBuilder.createElement({
                                    tag: 'span',
                                    classList: ['ss360-data-point__type'],
                                    textContent: " [".concat(dataPoint.type, "]")
                                })
                            ], false)
                        });
                    }
                    else {
                        acc[key].push({
                            value: value,
                            children: __spreadArray([], valueNodes_1, true)
                        });
                    }
                }
            }
        }
        return acc;
    }, {});
    var collapseDataPoints = dataPointsConfig.collapseBy;
    var showNames = dataPointsConfig.showNames;
    var buildRow = function (key, value, isLast) {
        if (isLast === void 0) { isLast = false; }
        var tr = DomElementBuilder.createElement({
            tag: 'tr',
            classList: ['ss360-data-point', isLast ? ' ss360-data-point--last' : undefined, "ss360-dp__".concat(StringHelper.getSafeKey((key || '').toLowerCase()))],
            attrs: {
                'aria-label': key
            }
        });
        if (showNames) {
            tr.appendChild(DomElementBuilder.createElement({
                tag: 'td',
                classList: ['ss360-data-point__cell', 'ss360-data-point__cell--key'],
                textContent: key || ''
            }));
        }
        tr.appendChild(DomElementBuilder.createElement({
            tag: 'td',
            classList: ['ss360-data-point__cell', 'ss360-data-point__cell--value'],
            children: value
        }));
        return tr;
    };
    var kvTableRows = keys.map(function (key, idx) {
        if (addTypeClass) {
            var allTypes_1 = dataPointTypes[key] || {};
            groupedDataPoints[key] = groupedDataPoints[key].map(function (val, idx) {
                var valueTypes = __spreadArray([], (allTypes_1[val.value] || []), true);
                var queryTag = undefined;
                try {
                    queryTag = queryTags !== undefined && queryTags.indexOf(val.value.toLowerCase().trim()) !== -1;
                }
                catch (err) {
                    // ccl
                }
                var typeClass;
                if (unique) {
                    if (queryTag) {
                        valueTypes.push('TAG');
                    }
                    typeClass = valueTypes.filter(function (val) { return val !== undefined; }).length === 0 ? '' : " ".concat(valueTypes.filter(function (val) { return val !== undefined; }).map(function (val) { return "ss360-data-point__single-value--".concat(val.toLowerCase()); }).join(' '));
                }
                else {
                    typeClass = valueTypes[idx] !== undefined ? " ss360-data-point__single-value--".concat(valueTypes[idx].toLowerCase()) : '';
                    if (queryTag) {
                        typeClass = "".concat(typeClass, " ss360-data-point__single-value--tag");
                    }
                }
                return {
                    value: val.value,
                    children: [
                        DomElementBuilder.createElement({
                            tag: 'span',
                            classList: ["ss360-data-point__single-value".concat(typeClass)],
                            children: val.children
                        })
                    ]
                };
            });
        }
        if (collapseDataPoints !== undefined && collapseDataPoints !== null && groupedDataPoints[key].length > 0) {
            var isHTMLCollapse = collapseDataPoints.indexOf('<') !== -1 && collapseDataPoints.indexOf('>') !== -1;
            var isLast = idx === keys.length - 1;
            var children_1 = [];
            var values = groupedDataPoints[key];
            for (var i = 0; i < values.length; i++) {
                var val = values[i];
                val.children.forEach(function (child) { return children_1.push(child); });
                if (i !== values.length - 1) {
                    if (isHTMLCollapse) {
                        sxQuery(collapseDataPoints).get().forEach(function (tag) { return children_1.push(tag); });
                    }
                    else {
                        children_1.push(document.createTextNode(collapseDataPoints));
                    }
                }
            }
            return [buildRow(key, children_1, isLast)];
        }
        return groupedDataPoints[key].reduce(function (acc, value, innerIdx) {
            var isLast = idx === keys.length - 1 && innerIdx === groupedDataPoints[key].length - 1;
            acc.push(buildRow(key, value.children, isLast));
            return acc;
        }, []);
    });
    var dataPointsDirection = dataPointsConfig.direction;
    var isMobileGrid = layout.mobile.type === LayoutType.Grid || layout.mobile.type === LayoutType.Mixed;
    var isDesktopGrid = layout.desktop.type === LayoutType.Grid || layout.desktop.type === LayoutType.Mixed;
    if (kvTableRows.length > 0 || isMobileGrid || isDesktopGrid) {
        return DomElementBuilder.createElement({
            tag: 'table',
            classList: ['ss360-data-points', "ss360-data-points--".concat(dataPointsDirection)],
            children: [
                DomElementBuilder.createElement({
                    tag: 'tbody',
                    classList: ['ss360-data-points__body'],
                    children: kvTableRows.reduce(function (acc, row) {
                        row.forEach(function (entry) {
                            acc.push(entry);
                        });
                        return acc;
                    }, [])
                })
            ]
        });
    }
    return undefined;
};
export default {
    renderSS360Suggest: function (renderSettings) {
        var suggest = renderSettings.suggest, contentGroup = renderSettings.contentGroup, pluginConfiguration = renderSettings.pluginConfiguration, hiddenParts = renderSettings.hiddenParts, headingLevel = renderSettings.headingLevel, query = renderSettings.query, groupHasImages = renderSettings.groupHasImages, hasCtas = renderSettings.hasCtas, cta = renderSettings.cta, queryTags = renderSettings.queryTags, isZoovuBranding = renderSettings.isZoovuBranding, zoe = renderSettings.zoe, comparison = renderSettings.comparison;
        var isHidden = renderSettings.isHidden, scripts = renderSettings.scripts;
        if (isHidden === undefined) {
            isHidden = false;
        }
        if (scripts === undefined) {
            scripts = [];
        }
        var originalContentGroup = suggest.getOriginalContentGroup();
        var headingId = "ss360-heading-".concat(Math.round(100000 * Math.random()));
        var results = pluginConfiguration.results, layout = pluginConfiguration.layout, style = pluginConfiguration.style;
        var linksOpenNewTab = results.linksOpenNewTab;
        if (suggest.getType() === SuggestType.YOUTUBE_VIDEO) {
            linksOpenNewTab = true;
        }
        var originalGroup = contentGroup === '*' && originalContentGroup !== undefined ? "ss360-original-group-".concat(StringHelper.getSafeKey(originalContentGroup)) : '';
        var isCustomResult = suggest.getType() === 'custom';
        var colspan = suggest.getColSpan(pluginConfiguration.layout);
        var li = DomElementBuilder.createElement({
            tag: 'li',
            classList: ['ss360-suggests', isHidden ? 'ss360-suggests--hidden' : undefined, originalGroup, isCustomResult ? ' ss360-custom-result' : undefined],
            data: {
                productIdentifier: suggest.getIdentifier()
            },
            style: {
                display: isHidden && !pluginConfiguration.style.defaultCss ? 'none' : undefined,
                'grid-column': colspan ? "span ".concat(colspan) : undefined
            }
        });
        var article = DomElementBuilder.createElement({
            tag: 'article',
            classList: ['ss360-suggests__wrap', 'ss360-n-section'],
            attrs: {
                'aria-labelledby': headingId
            }
        });
        li.appendChild(article);
        if (isCustomResult) {
            var html = suggest.getHtml();
            if (html !== undefined && html.indexOf('<script') !== -1) {
                try {
                    sxQuery(html).each(function (node) {
                        var $node = sxQuery(node);
                        if ($node.get()[0].tagName === 'SCRIPT') {
                            scripts.push($node);
                        }
                        else {
                            $node.find('script').each(function (script) {
                                scripts.push(sxQuery(script));
                            });
                        }
                    });
                }
                catch (ex) {
                    // ccl
                }
            }
            article.innerHTML = html;
            return li;
        }
        var suggestLine = '';
        var suggestLink = suggest.getLink(pluginConfiguration.queryTerm.highlightMatchedContent, query, isZoovuBranding);
        if (!suggest.hasImage()) {
            suggest.setPlaceholderImage(results.placeholderImage);
        }
        var img = renderImage(suggest, hiddenParts, results, groupHasImages, suggestLink, renderSettings.preventLazyLoad !== true);
        if (img !== undefined) {
            article.appendChild(img);
        }
        // link and name
        var shouldRenderHeader = (hiddenParts.title !== 'all' || hiddenParts.url !== 'all');
        // content and image
        var contentWrap = DomElementBuilder.createElement({
            tag: 'div',
            classList: ['ss360-suggests__content', hasCtas ? ' ss360-suggests__content--has-cta' : undefined]
        });
        article.appendChild(contentWrap);
        if (shouldRenderHeader) {
            var title = renderTitle(suggest, suggestLink, hiddenParts, headingId, linksOpenNewTab, headingLevel, results);
            if (title !== undefined && title.title !== undefined) {
                contentWrap.appendChild(title.title);
            }
            if (title !== undefined && title.variantsLabel !== undefined) {
                contentWrap.appendChild(title.variantsLabel);
            }
            var link = suggestLink !== undefined ? renderLink(suggest, results.stripHttp, hiddenParts, suggestLink, linksOpenNewTab) : undefined;
            if (link !== undefined) {
                contentWrap.appendChild(link);
            }
        }
        var content = suggest.getContent(renderSettings.contentDataPointName);
        if (content !== undefined && content !== null && hiddenParts.snippet !== 'all') {
            contentWrap.appendChild(DomElementBuilder.createElement({
                tag: 'p',
                classList: ['ss360-suggests__snippet'],
                html: content
            }));
        }
        // Data Points
        if (hiddenParts.dataPoints !== 'all') {
            var dataPoints = renderDataPoints(suggest.getDataPoints(renderSettings.contentDataPointName), pluginConfiguration.dataPoints, queryTags, layout);
            if (dataPoints !== undefined) {
                contentWrap.appendChild(dataPoints);
            }
        }
        if (hasCtas) {
            var ctaAdditionalClass = undefined;
            if (results.ctaDirection === 'row') {
                var firstDirection = cta[0].position || 'left';
                if (firstDirection === 'left') {
                    ctaAdditionalClass = ' ss360-flex--justify-start';
                }
                else if (firstDirection === 'center') {
                    ctaAdditionalClass = ' ss360-flex--justify-center';
                }
                else if (firstDirection === 'right') {
                    ctaAdditionalClass = ' ss360-flex--justify-end';
                }
            }
            var ctaWrap_1 = DomElementBuilder.createElement({
                tag: 'div',
                classList: ['ss360-suggests__cta-wrap', "ss360-suggests__cta-wrap--".concat(results.ctaDirection), ctaAdditionalClass]
            });
            cta.forEach(function (singleCta) {
                var include = singleCta.includeContentGroups;
                var exclude = singleCta.excludeContentGroups;
                var showCta = true;
                var resultContentGroup = contentGroup;
                if (contentGroup === '*') {
                    resultContentGroup = suggest.getOriginalContentGroup();
                }
                if (include !== undefined && include.indexOf(resultContentGroup) === -1) {
                    showCta = false;
                }
                else if (exclude !== undefined && exclude.indexOf(resultContentGroup) !== -1) {
                    showCta = false;
                }
                if (!showCta) {
                    return;
                }
                var iconNode = undefined;
                var renderAsButton = singleCta.renderAsButton !== undefined ? singleCta.renderAsButton : true;
                var icon = renderAsButton ? singleCta.icon || 'ss360:arrow' : 'none';
                var position = singleCta.position !== undefined ? singleCta.position : 'left';
                var classList = ['ss360-n-button', 'ss360-tc-c', 'ss360-tc-b', 'ss360-cta', "ss360-cta--".concat(position), icon !== undefined && icon !== 'none' ? undefined : 'ss360-cta--noicon'];
                if (!renderAsButton) {
                    classList.push('ss360-cta--plain');
                }
                else {
                    classList.push('ss360-tc-bg--focus');
                }
                if (icon !== 'none') {
                    iconNode = DomElementBuilder.createElement({
                        tag: 'i',
                        classList: ['ss360-cta__icon'],
                        attrs: {
                            role: 'presentation'
                        }
                    });
                    var innerIconNode = undefined;
                    if (icon === 'ss360:shopping-cart' || icon === 'zoovu:shopping-cart') {
                        innerIconNode = Icons.getSvgIconNode(Icons.SHOPPING_CART, style.themeColor, 'ss360-cta__img');
                        classList.push('ss360-cta--ss360-icon');
                    }
                    else if (icon === 'ss360:arrow' || icon === 'zoovu:arrow') {
                        innerIconNode = Icons.getSvgIconNode(Icons.TRIANGLE_ARROW, style.themeColor, 'ss360-cta__img');
                        classList.push('ss360-cta--ss360-icon');
                    }
                    else if (icon.indexOf('<svg') !== -1 && icon.indexOf('</svg') !== -1) {
                        innerIconNode = sxQuery(icon).get(0);
                    }
                    else {
                        innerIconNode = DomElementBuilder.createElement({
                            tag: 'img',
                            classList: ['ss360-cta__img'],
                            attrs: {
                                src: icon
                            }
                        });
                    }
                    iconNode.appendChild(innerIconNode);
                }
                var text = singleCta.text !== undefined ? DomElementBuilder.createElement({
                    tag: 'span',
                    html: singleCta.text
                }) : undefined;
                var link;
                if (singleCta.getLink !== undefined) {
                    link = singleCta.getLink(suggest.suggest);
                }
                if (link === undefined) {
                    link = singleCta.link;
                }
                if (link === undefined && !renderAsButton) {
                    link = '#';
                }
                var hasLink = link !== undefined && link !== null;
                ctaWrap_1.appendChild(DomElementBuilder.createElement({
                    tag: hasLink ? 'a' : 'button',
                    classList: classList,
                    attrs: {
                        href: hasLink ? link.replace(/#RESULT_URL#/g, suggestLink) : undefined,
                        type: hasLink ? undefined : 'button'
                    },
                    children: [
                        iconNode, text
                    ]
                }));
            });
            contentWrap.appendChild(ctaWrap_1);
        }
        var similarContent = renderSettings.similarContent;
        var renderSimilarContentTrigger = similarContent !== undefined && similarContent.active && (suggest.getIdentifier() !== undefined || (!similarContent.isRelationSearch && !similarContent.isVisualSimilaritySearch));
        if (renderSimilarContentTrigger && similarContent.isRelationSearch && similarContent.includeTag !== undefined) {
            renderSimilarContentTrigger = suggest.getRelationTypes() !== undefined && (suggest.getRelationTypes().tags || []).length > 0 && suggest.getRelationTypes().tags.reduce(function (acc, tag) {
                if (acc) {
                    return true;
                }
                return (tag.relations || []).reduce(function (acc, relation) { return relation.exists; }, false);
            }, false);
        }
        if (renderSimilarContentTrigger) {
            var similarContentWrap = DomElementBuilder.createElement({
                tag: 'div',
                classList: ['ss360-suggests__similar-content-wrap', 'ss360-similar-content']
            });
            var button = DomElementBuilder.createElement({
                tag: 'button',
                classList: ['ss360-n-button', 'ss360-similar-content__trigger'],
                attrs: {
                    type: 'button'
                },
                children: [
                    DomElementBuilder.createElement({ tag: 'span', classList: ['ss360-similar-content__trigger-label'], html: similarContent.buttonLabel }),
                    Icons.getSvgIconNode(Icons.SIMPLE_ARROW_LIGHT, '#4a4f62', 'ss360-similar-content__trigger-icon', 14, 8, '0 0 14 8')
                ]
            });
            similarContentWrap.appendChild(button);
            contentWrap.appendChild(similarContentWrap);
        }
        var zoeShowChat = suggest.getIdentifier() && zoe && zoe.apiKey && zoe.showChat;
        var showComparison = suggest.getIdentifier() && comparison && comparison.enabled;
        if (zoeShowChat || showComparison) {
            var children = [];
            var createActionButton = function (modifier, label, icon) { return DomElementBuilder.createElement({
                tag: 'button',
                classList: ['ss360-n-button', 'ss360-actions__action', "ss360-actions__action--".concat(modifier)],
                attrs: {
                    type: 'button'
                },
                children: [
                    Icons.getSvgIconNode(icon, '#4a4f62'),
                    DomElementBuilder.createElement({
                        tag: 'span',
                        classList: ['ss360-actions__action-label'],
                        html: label
                    })
                ]
            }); };
            if (showComparison) {
                var label = DomElementBuilder.createElement({
                    tag: 'label',
                    classList: ['ss360-actions__compare'],
                    children: [
                        DomElementBuilder.createElement({
                            tag: 'input',
                            classList: ['ss360-actions__compare-checkbox'],
                            attrs: {
                                type: 'checkbox'
                            }
                        }),
                        DomElementBuilder.createElement({
                            tag: 'span',
                            classList: ['ss360-actions__compare-checkmark'],
                            attrs: {
                                role: 'presentation'
                            }
                        }),
                        DomElementBuilder.createElement({
                            tag: 'span',
                            classList: ['ss360-actions__compare-label'],
                            textContent: comparison.buttonLabel
                        }),
                    ]
                });
                children.push(label);
            }
            if (zoeShowChat) {
                children.push(createActionButton('chat', zoe.chatLabel, Icons.CHAT));
            }
            contentWrap.appendChild(DomElementBuilder.createElement({
                tag: 'div',
                classList: ['ss360-actions', zoeShowChat && showComparison ? null : 'ss360-actions--single'],
                children: children
            }));
        }
        return li;
    }
};

'use strict';
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import DataSourceType from '../../insights/interface/DataSourceType';
import DisplayType from '../../relatedContent/enums/DisplayType';
import sxQuery from '../../sxQuery/sxQuery';
import SxQueryUtils from '../../sxQuery/SxQueryUtils';
import Icons from '../components/Icons';
import Helper from '../utils/Helper';
var wasPreloaded = false;
var positionGroupCache = {};
export default {
    bind: function ($trigger, $item, suggest, context, originalQuery) {
        var similarContentConfiguration = context.pluginConfiguration.similarContent;
        if (!wasPreloaded && similarContentConfiguration.active) {
            // @ts-ignore
            import(/* webpackChunkName: "relatedContent" */ '../../relatedContent/relatedContent');
            wasPreloaded = true;
        }
        var $rowWrap;
        var appendToParent = similarContentConfiguration._appendToParent || similarContentConfiguration.displayType !== DisplayType.Inline;
        $trigger.on('click', function () {
            var offsetTop = $trigger.get(0).getBoundingClientRect().top;
            var isActive = $trigger.hasClass('ss360-similar-content__trigger--active');
            var id = "similar-content-".concat(Math.round(Math.random() * 100000));
            var configuration = context.pluginConfiguration;
            var $listWrap = $item.parents('.ss360-list', true);
            var removeActiveSignifiers = function () {
                $listWrap.find('.ss360-similar-content__trigger').removeClass('ss360-similar-content__trigger--active');
                $listWrap.find('.ss360-similar-content__trigger-icon path').attr('d', Icons.SIMPLE_ARROW_LIGHT);
            };
            removeActiveSignifiers();
            var hide = function () {
                removeActiveSignifiers();
                var $removeParent = appendToParent ? $listWrap.parents('.ss360-group', true) : $listWrap;
                $removeParent.find('.ss360-similar-content-block').slideUp(configuration.style.animationSpeed, function () {
                    $removeParent.find('.ss360-similar-content-block').remove();
                });
                sxQuery(window).off("resize.".concat(id, ", orientationchange.").concat(id));
                $rowWrap = undefined;
            };
            if (isActive) {
                hide();
                return;
            }
            $listWrap.find('.ss360-similar-content-block').remove();
            $trigger.addClass('ss360-similar-content__trigger--active');
            $trigger.find('.ss360-similar-content__trigger-icon path').attr('d', Icons.SIMPLE_ARROW_BOLD);
            var $currentSuggest = $trigger.parents('.ss360-suggests', true);
            var positionHasChanged = true;
            var appendWrap = function () {
                if (appendToParent) {
                    $rowWrap = $rowWrap !== undefined && $rowWrap.isMounted() ? $rowWrap : sxQuery("<section id=\"".concat(id, "\" class=\"ss360-similar-content-block\"></section>"));
                    $currentSuggest.parents('.ss360-group', true).append($rowWrap);
                }
                else {
                    var currentSuggest = $currentSuggest.get(0);
                    var offsetTop_1 = $currentSuggest.offset().top;
                    var lastSuggestInRow_1 = currentSuggest;
                    var lastOneFound_1 = false;
                    $listWrap.find('.ss360-suggests').each(function (otherSuggest) {
                        if (!lastOneFound_1) {
                            var otherOffsetTop = sxQuery(otherSuggest).offset().top;
                            if (otherOffsetTop === offsetTop_1) {
                                lastSuggestInRow_1 = otherSuggest;
                            }
                            else if (otherOffsetTop > offsetTop_1) {
                                lastOneFound_1 = true;
                            }
                        }
                    });
                    var groupKey = $listWrap.parents('.ss360-group', true).get()[0].className;
                    $rowWrap = $rowWrap !== undefined && $rowWrap.isMounted() ? $rowWrap : sxQuery("<li id=\"".concat(id, "\" class=\"ss360-similar-content-block\"></li>"));
                    lastSuggestInRow_1.parentNode.insertBefore($rowWrap.get()[0], lastSuggestInRow_1.nextElementSibling);
                    var position = Helper.getPositionInNodeList($listWrap.find('.ss360-suggests,.ss360-similar-content-block').get(), $rowWrap.get()[0]);
                    positionHasChanged = positionGroupCache[groupKey] !== position;
                    positionGroupCache[groupKey] = position;
                }
            };
            appendWrap();
            var isRecomputingStyles = false;
            sxQuery(window).on("resize.".concat(id, ", orientationchange.").concat(id), function () {
                if (isRecomputingStyles) {
                    return;
                }
                if ($rowWrap === undefined || !$rowWrap.isMounted()) {
                    sxQuery(window).off("resize.".concat(id, ", orientationchange.").concat(id));
                    return;
                }
                isRecomputingStyles = true;
                setTimeout(function () {
                    $rowWrap.hide();
                    SxQueryUtils.requestAnimationFrame(function () {
                        appendWrap();
                        $rowWrap.show();
                        isRecomputingStyles = false;
                    });
                }, 160);
            });
            // @ts-ignore
            import(/* webpackChunkName: "relatedContent" */ '../../relatedContent/relatedContent').then(function (_a) {
                var RelatedContent = _a.default;
                var similarContentConfiguration = configuration.similarContent;
                if (similarContentConfiguration.includeTag !== undefined && similarContentConfiguration.includeTag.title === undefined) {
                    similarContentConfiguration.includeTag.title = similarContentConfiguration.title;
                }
                var relatedContent = new RelatedContent(__assign(__assign({}, similarContentConfiguration), { querySource: {
                        type: DataSourceType.Static,
                        value: similarContentConfiguration.isRelationSearch || similarContentConfiguration.isVisualSimilaritySearch ? suggest.getIdentifier() : suggest.getName()
                    }, contentBlock: "#".concat(id), themeColor: configuration.style.accentColor, siteId: configuration.siteId, ecom: configuration.ecom, ecomDev: configuration.ecomDev, ecomStage: configuration.ecomStage, baseUrl: configuration.baseUrl, skipPage: suggest.getLink(), _cacheQueries: configuration._cacheQueries, _logQueries: configuration.tracking.logQueries, showRelationTitle: false, showCloseIcon: true, showLoader: true, includeRelations: similarContentConfiguration.includeRelation !== undefined && similarContentConfiguration.includeTag === undefined ? [configuration.similarContent.includeRelation] : undefined, includeTags: similarContentConfiguration.includeTag !== undefined ? [configuration.similarContent.includeTag] : undefined, onClose: function () {
                        hide();
                    }, resultTemplate: similarContentConfiguration.useResultTemplate ? context.pluginConfiguration.results.resultTemplate : similarContentConfiguration.resultTemplate }), context.getInsights(), context.getIsZoovu());
                relatedContent.loadAndRender(positionHasChanged ? configuration.style.animationSpeed : 0, originalQuery, similarContentConfiguration.displayType === DisplayType.Inline, true);
                if (similarContentConfiguration.displayType === DisplayType.Inline) {
                    var offsetDiff = offsetTop - $trigger.get(0).getBoundingClientRect().top;
                    if ('scrollTo' in window && offsetDiff !== 0) {
                        window.scrollTo(window.scrollX, window.scrollY - offsetDiff);
                    }
                }
            });
        });
    }
};

'use strict';
var BlockEntryType;
(function (BlockEntryType) {
    BlockEntryType["ResultGroup"] = "resultGroup";
    BlockEntryType["SearchHistory"] = "searchHistory";
    BlockEntryType["DataSet"] = "dataSet";
    BlockEntryType["Empty"] = "empty";
    BlockEntryType["PopularQueries"] = "popularQueries";
    BlockEntryType["PopularResults"] = "popularResults";
    // Chat = 'chat',
    // Html = 'html'
})(BlockEntryType || (BlockEntryType = {}));
export default BlockEntryType;

'use strict';
import MediaQueryMatchType from '../../sxQuery/MediaQueryMatchType';
import sxQuery from '../../sxQuery/sxQuery';
import SxQueryUtils from '../../sxQuery/SxQueryUtils';
import LayoutType from '../enums/LayoutType';
import Helper from '../utils/Helper';
// TODO: make instance specific #multiple-instances
var resizeGroup = function ($group) {
    var minY = Number.POSITIVE_INFINITY;
    var maxY = -1;
    $group.find('.ss360-suggests:not(.ss360-masonry-break):not(.ss360-suggests--hidden)').each(function (entry) {
        var $entry = sxQuery(entry);
        var offset = $entry.offset();
        minY = Math.min(offset.top, minY);
        maxY = Math.max(offset.top + $entry.outerHeight(), maxY);
    });
    var height = Math.ceil(maxY - minY) + 35;
    $group.height(height);
};
var getColumnCount = function (gridConfig) {
    var breakpointsDesc = Object.keys(gridConfig).map(function (key) { return parseInt(key, 10); }).sort(function (a, b) { return b - a; });
    for (var i = 0; i < breakpointsDesc.length; i++) {
        if (SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Min, breakpointsDesc[i])) {
            return gridConfig[breakpointsDesc[i]];
        }
    }
    return 3; // fallback, should never happen
};
var computeExpectedHeight = function (group, columnCount) {
    var heightSums = [];
    group.find('.ss360-suggests:not(.ss360-suggests--hidden)').each(function (el, idx) {
        var arrIdx = idx % columnCount;
        if (heightSums[arrIdx] === undefined) {
            heightSums[arrIdx] = 0;
        }
        heightSums[arrIdx] += sxQuery(el).outerHeight();
    });
    var maxHeight = heightSums.reduce(function (acc, heightSum) { return Math.max(acc, heightSum); }, 0);
    return maxHeight * 1.1;
};
var updateStyles = function (gridConfig) {
    sxQuery('#ss360-masonry-style').remove();
    var breakpointsAsc = Object.keys(gridConfig).map(function (key) { return parseInt(key, 10); }).sort(function (a, b) { return a - b; });
    var styleContent = '';
    breakpointsAsc.forEach(function (breakpoint, idx) {
        var cols = gridConfig[breakpoint];
        var totalMargin = (cols - 1) * 3; // 3% between each column
        var columnWidth = Math.floor((100 - totalMargin) / cols);
        var breakpointStyle = ".ss360-masonry--#BP# .ss360-suggests{width: ".concat(columnWidth, "%}"); // column width
        for (var i = 0; i < cols; i++) { // column order
            var order = i === 0 ? cols : i;
            breakpointStyle += ".ss360-masonry--#BP# .ss360-suggests:nth-of-type(".concat(cols, "n+").concat(i, "){order: ").concat(order, "}");
        }
        var nextBreakpointQuery = idx < breakpointsAsc.length - 1 ? "and (max-width: ".concat((breakpointsAsc[idx + 1] - 1), "px)") : '';
        styleContent += "@media(min-width: ".concat(breakpoint, "px)").concat(nextBreakpointQuery, " and (max-width: 767px){").concat(breakpointStyle.replace(/#BP#/g, 'sm'), "}");
        styleContent += "@media(min-width: ".concat(breakpoint, "px)").concat(nextBreakpointQuery, " and (min-width: 768px){").concat(breakpointStyle.replace(/#BP#/g, 'lg'), "}");
    });
    sxQuery('head').append("<style id=\"ss360-masonry-style\">".concat(styleContent, "</style>"));
};
var Masonry = /** @class */ (function () {
    function Masonry(context) {
        var _this = this;
        var layoutConfig = context.pluginConfiguration.layout;
        var eventNamePostfix = context.generateId('ss360Masonry');
        this.groups = context.getExistingLayer().find('.ss360-group');
        this.alwaysApply = layoutConfig.mobile.type === LayoutType.Masonry && layoutConfig.desktop.type === LayoutType.Masonry;
        this.layoutConfig = layoutConfig;
        var shouldApply = this.shouldApplyInCurrentContext();
        this.wasLayoutReset = false;
        this.handlers = [];
        var gridConfig = Helper.copyObject(layoutConfig.masonryCols);
        this.groups.each(function (group) {
            var isRunning = false;
            var $group = sxQuery(group).find('ul.ss360-list');
            var handler = function () {
                if (isRunning)
                    return;
                if (!shouldApply) {
                    if (!_this.wasLayoutReset) {
                        $group.height('auto');
                        _this.wasLayoutReset = true;
                    }
                    return;
                }
                _this.wasLayoutReset = false;
                $group.height(computeExpectedHeight($group, getColumnCount(gridConfig)));
                isRunning = true;
                $group.find('.ss360-suggests.ss360-masonry-break').remove();
                for (var i = 0; i < getColumnCount(gridConfig); i++) {
                    $group.append('<li class="ss360-suggests ss360-masonry-break"></li>');
                }
                setTimeout(function () {
                    $group.find('.ss360-suggests.ss360-masonry-break').remove();
                    for (var i = 0; i < getColumnCount(gridConfig); i++) {
                        $group.append('<li class="ss360-suggests ss360-masonry-break"></li>');
                    }
                    $group.height(computeExpectedHeight($group, getColumnCount(gridConfig)));
                    resizeGroup($group);
                    isRunning = false;
                }, 150);
            };
            $group.find('img').off("load.".concat(eventNamePostfix, ", error.").concat(eventNamePostfix)).on("load.".concat(eventNamePostfix, ", error.").concat(eventNamePostfix), handler);
            handler();
            _this.handlers.push(handler);
        });
        sxQuery(window).off("resize.".concat(eventNamePostfix, ", orientationchange.").concat(eventNamePostfix)).on("resize.".concat(eventNamePostfix, ", orientationchange.").concat(eventNamePostfix), function () {
            shouldApply = _this.shouldApplyInCurrentContext();
            _this.handlers.forEach(function (handler) { handler(); });
        });
        context.subscribe('navigationChange', 'masonry', function () {
            shouldApply = _this.shouldApplyInCurrentContext();
            _this.handlers.forEach(function (handler) { handler(); });
        });
        this.handlers.forEach(function (handler) { handler(); });
    }
    Masonry.prototype.shouldApplyInCurrentContext = function () {
        if (this.alwaysApply) {
            return true;
        }
        if (this.layoutConfig.mobile.type === LayoutType.Masonry) {
            return SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Max, 991);
        }
        return SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Min, 992);
    };
    return Masonry;
}());
export default {
    init: function (context) {
        new Masonry(context);
    },
    notifyConfigChange: function (layoutConfig) {
        if (layoutConfig.mobile.type === LayoutType.Masonry || layoutConfig.desktop.type === LayoutType.Masonry) {
            var gridConfig = Helper.copyObject(layoutConfig.masonryCols);
            updateStyles(gridConfig);
        }
        else {
            sxQuery('#ss360-masonry-style').remove();
        }
    }
};

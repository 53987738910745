'use strict';
import SxQueryUtils from '../sxQuery/SxQueryUtils';
var entityMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '/': '&#x2F;'
};
var inverseEntityMap = Object.keys(entityMap).reduce(function (acc, key) { acc[entityMap[key]] = key; return acc; }, {});
var highlightSearchWords = function (string, searchString) {
    if (string === undefined || searchString === undefined) {
        return string;
    }
    var words = searchString.replace(/[^a-zA-Z0-9äöüÄÖÜßçâêîôûàèùëïěščřžýáíéťňÇÂÊÎÔÛÀÈÙËÏĚŠČŘŽÝÁÍÉŤŇ]|\s+|\r?\n|\r/gmi, ' ')
        .replace(/[^a-zA-Z0-9äöüÄÖÜßçâêîôûàèùëïěščřžýáíéťňÇÂÊÎÔÛÀÈÙËÏĚŠČŘŽÝÁÍÉŤŇ]/g, ' ').split(' ');
    // sort words by length, longest first
    words.sort(function (a, b) { return b.length - a.length; });
    var markers = {};
    SxQueryUtils.each(words, function (idx, word) {
        if (word.length < 2) {
            return;
        }
        var matches = string.match(new RegExp("((".concat(word, ")(?!#<##|-\\d+#<##))(?!.*\\1)"), 'gi'));
        if (matches != null) {
            for (var i = 0; i < matches.length; i++) {
                var match = matches[i];
                var matchEsc = match.replace(/[-[\]/{}()*+?.^$|]/g, '\\$&');
                string = string.replace(new RegExp("(".concat(matchEsc, ")(?!#<##|-\\d+#<##)"), 'g'), "##>#".concat(idx, "-").concat(i, "#<##"));
                markers["##>#".concat(idx, "-").concat(i, "#<##")] = "<span class=\"unibox-ac-c\">".concat(match, "</span>");
            }
        }
    });
    var reversedMarkerKeys = Object.keys(markers).reverse();
    for (var i = 0; i < reversedMarkerKeys.length; i++) {
        var singleMarker = reversedMarkerKeys[i];
        var replacement = markers[singleMarker];
        string = string.replace(new RegExp(singleMarker, 'gi'), replacement);
    }
    return string;
};
var StringHelper = {
    escapeHtml: function (string) {
        return String(string).replace(/[&<>"'/]/g, function (s) { return entityMap[s]; });
    },
    unescapeHtml: function (string) {
        string = String(string);
        Object.keys(inverseEntityMap).forEach(function (entity) {
            string = string.replace(new RegExp(entity, 'g'), inverseEntityMap[entity]);
        });
        return string;
    },
    getSafeKey: function (key) {
        return (key.toString()).replace(/[ "§$%&/(){}+*,.;|:!]/g, '_').replace(/#/g, '__').replace(/'/g, '---').toLowerCase();
    },
    highlightSearchWords: highlightSearchWords
};
export default StringHelper;

'use strict';
var NoResultsPageBlockType;
(function (NoResultsPageBlockType) {
    NoResultsPageBlockType["TextList"] = "textList";
    NoResultsPageBlockType["FuzzyResults"] = "fuzzyResults";
    NoResultsPageBlockType["CustomHtml"] = "customHtml";
    NoResultsPageBlockType["PopularResults"] = "popularResults";
    NoResultsPageBlockType["SearchBox"] = "searchBox";
    NoResultsPageBlockType["PopularQueries"] = "popularQueries";
    NoResultsPageBlockType["RelatedQueries"] = "relatedQueries";
})(NoResultsPageBlockType || (NoResultsPageBlockType = {}));
export default NoResultsPageBlockType;

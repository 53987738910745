'use strict';
import Polyfill from '../polyfills/Polyfill';
import sxQuery from '../../sxQuery/sxQuery';
var observer = null;
var CLASS_TO_REMOVE = 'ss360-suggests__image--lazy ss360-shimmer ss360-suggests__variant-image--lazy';
var hideImage = function (node, placeholderImage, isVariantImage) {
    if (isVariantImage === void 0) { isVariantImage = false; }
    var $parent = node.parents(isVariantImage ? '.ss360-suggests__variant' : '.ss360-suggests__image-wrap', true);
    if (placeholderImage !== null && placeholderImage !== undefined && placeholderImage.length > 0) {
        node.get(0).onload = function () { };
        node.attr('src', placeholderImage);
    }
    else if (placeholderImage !== null && !isVariantImage) {
        node.hide();
        $parent.addClass('ss360-suggests__image-wrap--empty');
    }
    else {
        node.hide();
        $parent.remove();
    }
};
var onError = function (node, callbacks, placeholderImage, isVariantImage) {
    if (isVariantImage === void 0) { isVariantImage = false; }
    return function () {
        var resultImageError = callbacks.resultImageError;
        node.onerror = function () { };
        var $node = sxQuery(node);
        var newImage;
        // @ts-ignore
        var fallbackSrc = $node.data('fallbackSrc');
        if (fallbackSrc !== undefined && fallbackSrc !== null) {
            newImage = fallbackSrc;
            var fallbackImage = new Image();
            fallbackImage.onerror = function () {
                hideImage($node, placeholderImage, isVariantImage);
            };
            fallbackImage.src = fallbackSrc;
        }
        else if (placeholderImage !== null && placeholderImage !== undefined && !isVariantImage) {
            newImage = placeholderImage;
        }
        else if (resultImageError !== undefined && !isVariantImage) {
            newImage = resultImageError($node.parents('.ss360-suggests', false)[0]);
        }
        if (!newImage) {
            hideImage($node, placeholderImage, isVariantImage);
        }
        else {
            $node.removeClass(CLASS_TO_REMOVE);
            $node.attr('src', newImage);
        }
    };
};
var onImageLoaded = function (node, pluginConfig) {
    var imageLoaded = pluginConfig.callbacks.imageLoaded;
    var _a = pluginConfig.results, placeholderImage = _a.placeholderImage, checkImageQuality = _a.checkImageQuality;
    var isVariantImage = node.hasClass('ss360-suggests__variant-image');
    // @ts-ignore
    var src = node.attr('src');
    var img = node.get(0);
    var isSvg = src.toLowerCase().indexOf('.svg') === src.length - 4;
    var width = img.naturalWidth || img.width;
    var height = img.naturalHeight || img.height;
    var aspectRatio = width / height;
    var polyfillBackgroundSize = height >= 100 ? 'contain' : undefined;
    if (!isSvg && checkImageQuality) {
        if (aspectRatio < 4) {
            // do nothing, this is fine for now
        }
        else if ((aspectRatio < 8 && height >= 150) || isVariantImage) {
            node.addClass(isVariantImage ? 'ss360-suggests__variant-image--cover' : 'ss360-suggests__image--cover');
            polyfillBackgroundSize = 'cover';
        }
        else if ((aspectRatio >= 6 && height < 150) || aspectRatio >= 8) {
            hideImage(node, placeholderImage);
            return;
        }
    }
    node.removeClass(CLASS_TO_REMOVE);
    node.attr('style', null);
    if (!('objectFit' in document.documentElement.style)) {
        Polyfill.objectFit(sxQuery(node).parent(), polyfillBackgroundSize);
    }
    if (imageLoaded !== undefined && !isVariantImage) {
        imageLoaded(node.get()[0], src);
    }
};
export default (function (context) {
    if (observer !== null) {
        observer.disconnect();
    }
    observer = null; // destruct existing reference
    var $layer = sxQuery("#".concat(context.layer404Id, ", #").concat(context.layerId));
    var callbacks = context.pluginConfiguration.callbacks;
    var placeholderImage = context.pluginConfiguration.results.placeholderImage;
    var images = $layer.find('.ss360-suggests__image,.ss360-suggests__variant-image').get();
    if (images.length === 0) {
        var fallbackImages = $layer.find('.ss360-suggests__image[data-fallback-src]:not([data-fallback-src=""])');
        if (fallbackImages.length > 0) {
            fallbackImages.each(function (node) {
                node.onerror = onError(node, callbacks, placeholderImage);
            });
        }
        return;
    }
    images.forEach(function (node) {
        node.onload = function () {
            onImageLoaded(sxQuery(node), context.pluginConfiguration);
        };
        node.onerror = onError(node, callbacks, placeholderImage, sxQuery(node).hasClass('ss360-suggests__variant-image'));
    });
});

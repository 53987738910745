'use strict';
import StringHelper from "../../global/StringHelper";
import sxQuery from "../../sxQuery/sxQuery";
import RelatedQueriesPosition from "../enums/RelatedQueriesPosition";
import Icons from "./Icons";
var RelatedQueries = /** @class */ (function () {
    function RelatedQueries(queries, context) {
        this.queries = queries || [];
        this.context = context;
    }
    RelatedQueries.prototype.render = function (layer, renderTitle, renderTarget) {
        var _this = this;
        if (renderTitle === void 0) { renderTitle = true; }
        if (this.queries.length === 0) {
            return;
        }
        var title = this.context.pluginConfiguration.results.relatedQueriesTitle;
        var target = renderTarget !== undefined ? renderTarget : layer.find('.ss360-group');
        var relatedQueriesPosition = this.context.pluginConfiguration.results.relatedQueriesPosition;
        if (renderTarget === undefined) {
            if (relatedQueriesPosition === RelatedQueriesPosition.AboveResultLayer) {
                target = layer;
            }
            else if (relatedQueriesPosition === RelatedQueriesPosition.BelowResultLayer) {
                var $filteredResults = layer.find('.ss360-layer__filtered-results');
                target = $filteredResults.length > 0 ? $filteredResults : layer;
            }
        }
        target.each(function (group) {
            var $group = sxQuery(group);
            var $wrap = sxQuery("<section role=\"search\" class=\"ss360-related-queries ss360-related-queries--".concat(relatedQueriesPosition, " ss360-n-section\"></section>"));
            $wrap.attr('aria-label', title);
            var $ul = sxQuery('<ul class="ss360-related-queries__list"></ul>');
            if (renderTitle) {
                var $titleItem = sxQuery('<li class="ss360-related-queries__item ss360-related-queries__item--title"></li>');
                var $titleSpan = sxQuery('<span class="ss360-related-queries__title"></span>');
                $titleSpan.text(title);
                $titleItem.append($titleSpan);
                $ul.append($titleItem);
            }
            _this.queries.forEach(function (query) {
                var $li = sxQuery('<li class="ss360-related-queries__item"></li>');
                var $a = sxQuery("<button class=\"ss360-related-queries__term ss360-n-button ss360-ac-b--hover ss360-ac-bg--hover\">".concat(Icons.getSvgIcon(Icons.MAGNIFIER, _this.context.pluginConfiguration.style.accentColor, 'ss360-related-queries__icon', 16, 16)).concat(StringHelper.escapeHtml(query), "</button>"));
                $a.attr('aria-label', "Search for ".concat(query));
                $a.on('click', function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    _this.context.core.showResults(query, {
                        submitSource: 'relatedQueries'
                    });
                    sxQuery("#".concat(_this.context.customSearchBoxId)).val(query);
                });
                $li.append($a);
                $ul.append($li);
            });
            $wrap.append($ul);
            if (renderTarget !== undefined) {
                $group.append($wrap);
            }
            else if (relatedQueriesPosition === RelatedQueriesPosition.AboveResultLayer) {
                if ($group.find('.ss360-custom-search').length === 0) {
                    $group.prepend($wrap);
                }
                else {
                    var searchBox = $group.find('.ss360-custom-search').get(0);
                    searchBox.parentNode.insertBefore($wrap.get(0), searchBox.nextElementSibling);
                }
            }
            else if (relatedQueriesPosition === RelatedQueriesPosition.BelowResultLayer) {
                $group.append($wrap);
            }
            else {
                $group.prepend($wrap);
            }
        });
    };
    return RelatedQueries;
}());
export default RelatedQueries;

'use strict';
var ImagePosition;
(function (ImagePosition) {
    ImagePosition["None"] = "none";
    ImagePosition["Top"] = "top";
    ImagePosition["Left"] = "left";
    ImagePosition["Right"] = "right";
    ImagePosition["Bottom"] = "bottom";
})(ImagePosition || (ImagePosition = {}));
export default ImagePosition;

'use strict';
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// TODO: logger should be set on Context #multiple-instances
var loggers = {
    info: function (param) {
        var rest = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            rest[_i - 1] = arguments[_i];
        }
        console.log.apply(console, __spreadArray([param], rest, false));
    },
    warn: function (param) {
        var rest = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            rest[_i - 1] = arguments[_i];
        }
        console.warn.apply(console, __spreadArray([param], rest, false));
    },
    error: function (param) {
        var rest = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            rest[_i - 1] = arguments[_i];
        }
        console.error.apply(console, __spreadArray([param], rest, false));
    }
};
var LoggerLevel;
(function (LoggerLevel) {
    LoggerLevel["Info"] = "info";
    LoggerLevel["Warn"] = "warn";
    LoggerLevel["Error"] = "error";
})(LoggerLevel || (LoggerLevel = {}));
var Logger = {
    info: loggers.info,
    warn: loggers.warn,
    error: loggers.error,
    set: function (level, handler) {
        Logger[level] = handler;
    }
};
export default Logger;

'use strict';
import ResultGroup from './ResultGroup';
var SortingOrder;
(function (SortingOrder) {
    SortingOrder["ASC"] = "ASC";
    SortingOrder["DESC"] = "DESC";
})(SortingOrder || (SortingOrder = {}));
var SearchResponse = /** @class */ (function () {
    function SearchResponse(data, sanitizeResults) {
        if (sanitizeResults === void 0) { sanitizeResults = false; }
        this.showAllResults = true;
        this.additionalResults = 0;
        this.plain = data;
        if (data.query !== undefined) {
            this.query = data.query;
        }
        else if (data.interpretedQuery !== undefined) {
            this.query = data.interpretedQuery.original;
        }
        if (data.suggests === undefined) { // product search fallbacks
            if (data.searchResults !== undefined) {
                data.searchResults.sort(function (a, b) {
                    var aType = a.type;
                    var bType = b.type;
                    var isAPromoted = aType === 'all' || aType === 'products';
                    var isBPromoted = bType === 'all' || bType === 'products';
                    if (isAPromoted === isBPromoted) {
                        return 0;
                    }
                    if (isAPromoted) {
                        return -1;
                    }
                    return 1;
                });
                data.suggests = data.searchResults.reduce(function (acc, entry) {
                    if ((entry.results || []).length !== 0) {
                        acc[entry.name] = entry.results;
                    }
                    return acc;
                }, {});
                if (data.totalResultsPerContentGroup === undefined) {
                    data.totalResultsPerContentGroup = data.searchResults.reduce(function (acc, entry) {
                        if (entry.totalResults !== undefined && entry.type !== 'all') {
                            acc[entry.name] = entry.totalResults;
                        }
                        return acc;
                    }, {});
                }
            }
        }
        if (sanitizeResults) {
            try {
                Object.keys(data.suggests).forEach(function (key) {
                    var suggests = data.suggests[key];
                    var arr = [];
                    suggests.forEach(function (entry) {
                        if (entry instanceof Array) {
                            entry.forEach(function (singleSuggest) {
                                arr.push(singleSuggest);
                            });
                        }
                        else {
                            arr.push(entry);
                        }
                    });
                    var sanitizeProperty = function (value) {
                        if (value === undefined || value === null || value.length === 0) {
                            return value;
                        }
                        if (value.indexOf('<') === -1 && value.indexOf('>') === -1) {
                            return value;
                        }
                        return value.replace(/alert\(/g, 'ss360Alert').replace(/alert\.bind/g, 'ss360Alert.bind').replace(/localStorage\(/g, 'ss360LocalStorage')
                            .replace(/localStorage\./g, 'ss360LocalStorage.')
                            .replace(/localStorage.bind/g, 'ss360LocalStorage');
                    };
                    if (data.searchResults !== undefined) {
                        var allResults = data.searchResults.filter(function (entry) { return entry.type === 'all'; })[0];
                        // @ts-ignore
                        if (allResults !== undefined && allResults.placements !== undefined) {
                            allResults.placements.forEach(function (entry) {
                                // @ts-ignore
                                arr.push(entry);
                            });
                        }
                    }
                    arr.forEach(function (entry) {
                        entry.content = sanitizeProperty(entry.content);
                        entry.name = sanitizeProperty(entry.name);
                        entry.image = sanitizeProperty(entry.image);
                        entry.link = sanitizeProperty(entry.link);
                    });
                });
            }
            catch (err) {
                console.error(err);
            }
        }
        if (data.suggests !== undefined && data.suggests !== null && data.totalResultsPerContentGroup !== undefined) {
            Object.keys(data.suggests).forEach(function (group) {
                if (data.totalResultsPerContentGroup[group] !== undefined && data.totalResultsPerContentGroup[group] < data.suggests[group].length) {
                    data.totalResultsPerContentGroup[group] = data.suggests[group].length;
                }
            });
        }
        // this can happen when a customer pins a custom result in the all results tab + toggles the pinned only setting --> otherwise we'd show an empty serp while a pinned result would be expected
        if (Object.keys(data.suggests || {}).length === 0 && data.searchResults !== undefined) {
            var allResultsPlacements = data.searchResults.filter(function (val) { return val.type === 'all'; })[0];
            if (allResultsPlacements !== undefined && allResultsPlacements.placements !== undefined) {
                allResultsPlacements.placements = allResultsPlacements.placements.filter(function (placement) { return placement.position >= 0; });
                allResultsPlacements.placements
                    .sort(function (a, b) { return (a.position !== undefined ? a.position : 99999) - (b.position !== undefined ? b.position : 99999); });
                data.suggests = {
                    // @ts-ignore
                    _: allResultsPlacements.placements
                };
                if (data.totalResults === 0) {
                    data.totalResults = allResultsPlacements.placements.length;
                }
            }
        }
        this.setSuggests(data.suggests);
        this.searchResults = data.searchResults;
        this.activeFilterOptions = data.activeFilterOptions;
        this.filterOptions = data.filterOptions;
        this.groupedResultsAvailable = data.groupedResultsAvailable;
        this.totalResults = data.totalResults;
        this.sortingOptions = data.sortingOptions;
        this.sorting = data.sorting;
        this.activeSortingOption = data.activeSortingOption;
        this.filterNameMapping = data.filterNameMapping;
        this.redirect = data.redirect;
        this.plan = data.plan;
        this.attribution = data.attribution;
        this.queryCorrection = data.queryCorrection;
        this.queryCorrectionRewrite = data.queryCorrectionRewrite;
        if (data.interpretedQuery !== undefined && data.interpretedQuery !== null && data.interpretedQuery.queryWasCorrected) {
            this.queryCorrectionRewrite = data.interpretedQuery.corrected;
        }
        this.filterMapping = data.filterMapping;
        this.totalResultsPerContentGroup = data.totalResultsPerContentGroup;
        this.sortingOrder = data.sortingOrder !== undefined ? data.sortingOrder : SortingOrder.DESC;
        this.banner = data.banner;
        this.relatedQueries = data.relatedQueries || [];
        this.showAllResults = data.showAllResults !== false;
        this.guidedQuestions = data.guidedQuestion && data.guidedQuestion.questions ? data.guidedQuestion.questions : [];
        this.guidedQuestionsTicketId = data.guidedQuestion ? data.guidedQuestion.ticketNumber : undefined;
        this.activeGuidedQuestions = data.activeGuidedQuestions || [];
    }
    SearchResponse.prototype.setSuggests = function (suggests) {
        this.suggests = Object.keys(suggests || {}).map(function (key) { return new ResultGroup(key, suggests[key]); });
    };
    SearchResponse.prototype.getSuggestsArray = function (groupName) {
        var group = this.suggests.filter(function (g) { return g.name === groupName; })[0];
        return group !== undefined ? group.suggests : undefined;
    };
    SearchResponse.prototype.addResultGroup = function (groupName, suggestions, searchSuggests) {
        var group = new ResultGroup(groupName, suggestions, searchSuggests);
        this.suggests.push(group);
    };
    SearchResponse.prototype.getResultGroupNames = function () {
        return this.suggests.map(function (g) { return g.name; });
    };
    SearchResponse.prototype.getFlatSuggests = function () {
        return this.suggests.reduce(function (acc, resultGroup) {
            acc = acc.concat(resultGroup.suggests);
            return acc;
        }, []);
    };
    SearchResponse.prototype.setResultTitle = function (title) {
        this.resultTitle = title;
    };
    SearchResponse.prototype.getResultTitle = function () {
        return this.resultTitle;
    };
    SearchResponse.prototype.setRelationId = function (id) {
        this.relationId = id;
    };
    SearchResponse.prototype.getRelationId = function () {
        return this.relationId;
    };
    SearchResponse.prototype.setRelationTag = function (tag) {
        this.relationTag = tag;
    };
    SearchResponse.prototype.getRelationTag = function () {
        return this.relationTag;
    };
    SearchResponse.prototype.getQueryTags = function () {
        if (this.plain.interpretedQuery === undefined || (this.plain.interpretedQuery.tags || []).length === 0) {
            return [];
        }
        return this.plain.interpretedQuery.tags.map(function (q) { return q.name; }).filter(function (name) { return (name || '').length > 0; }).map(function (name) { return name.toLowerCase().trim(); });
    };
    SearchResponse.prototype.getGuidedQuestions = function () {
        return this.guidedQuestions;
    };
    SearchResponse.prototype.getGuidedQuestionsTicketId = function () {
        return this.guidedQuestionsTicketId;
    };
    return SearchResponse;
}());
export default SearchResponse;

'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import UiHelper from './UiHelper';
import Masonry from '../layout/Masonry';
import SearchResultType from '../enums/SearchResultType';
import NavigationPosition from '../enums/NavigationPosition';
import LayoutType from '../enums/LayoutType';
var createSkeletonResult = function (randomizeImageHeight) {
    var header = '<header class="ss360-skeleton__result-heading ss360-shimmer"></header>';
    var paragraphLine = '<div class="ss360-skeleton__result-snippet-row ss360-shimmer"></div>';
    var paragraph = "<div class=\"ss360-skeleton__result-snippet\">".concat(paragraphLine).concat(paragraphLine).concat(paragraphLine
        .replace(/class="/g, 'class="ss360-skeleton__result-snippet-row--last '), "</div>");
    var body = "<div class=\"ss360-skeleton__content-container\">\n        <div class=\"ss360-skeleton__result-image ss360-shimmer\"".concat(randomizeImageHeight ? " style=\"height:".concat(75 + Math.round(100 * Math.random()), "px\"") : '', "></div>").concat(paragraph, "</div>");
    return sxQuery("<li class=\"ss360-skeleton-part ss360-suggests ss360-skeleton__result\"><article>".concat(header).concat(body, "<div class=\"ss360-ca\"></div></article></li>"));
};
var createSkeletonHeading = function () { return sxQuery('<div class="ss360-skeleton-part ss360-skeleton__block-heading ss360-shimmer"></div>'); };
// eslint-disable-next-line max-len
var createSkeletonGroup = function (layoutConfig, context) { return sxQuery("<section class=\"ss360-skeleton-part ss360-group ss360-skeleton__group\"><ul class=\"ss360-list".concat(UiHelper.getGridClassName(layoutConfig, undefined, context), "\"></ul></section>")); };
var navigationEntry = '<div class="ss360-skeleton__navigation-entry ss360-shimmer"></div>';
var createNavigation = function (position) { return sxQuery("<section class=\"ss360-skeleton-part ss360-nav ss360-nav--".concat(position, " ss360-skeleton__navigation\">").concat(navigationEntry
    .replace(/class="/g, 'class="ss360-skeleton__navigation-entry--first ')).concat(navigationEntry).concat(navigationEntry.replace(/class="/g, 'class="ss360-skeleton__navigation-entry--last '), "</section>")); };
var addHiddenClasses = function (layer, layoutConfig) {
    addSpecificHiddenClasses(layer, 'mobile', layoutConfig.mobile);
    addSpecificHiddenClasses(layer, 'desktop', layoutConfig.desktop);
};
var addSpecificHiddenClasses = function (layer, postfix, config) {
    if (!config.showTitle) {
        layer.addClass("ss360-skeleton__hide-titles--".concat(postfix));
    }
    if (!config.showImages) {
        layer.addClass("ss360-skeleton__hide-images--".concat(postfix));
    }
    if (!config.showSnippet) {
        layer.addClass("ss360-skeleton__hide-snippets--".concat(postfix));
    }
};
var createFilter = function (position) {
    var blockTemplate = '<div class="ss360-skeleton-part ss360-skeleton__filter-block ss360-shimmer"></div>';
    return sxQuery("<section class=\"ss360-skeleton__filter ss360-skeleton__filter--".concat(position, "\">").concat(blockTemplate).concat(blockTemplate).concat(blockTemplate, "</section>"));
};
/**
 *
 *
 * @param config The skelet configuration.
 * @param config.searchResultType The type of the search results ('fullscreen', 'embed' or 'layover')
 * @param config.uiBuilder The ui builder instance.
 * @param config.closeLayerHandler The close layer callback.
 * @param config.searchFieldRenderer A function that appends a search field to layover layer
 * @param config.contentOnly Whether to render only the content or the full layer.
 */
var Skelet = /** @class */ (function () {
    function Skelet(config) {
        var pluginConfig = config.pluginConfig;
        this.pluginConfig = pluginConfig;
        this.contentBlock = (pluginConfig.results.embedConfig || {}).contentBlock;
        this.navigationPosition = pluginConfig.results.group ? pluginConfig.layout.navigation.position : NavigationPosition.None;
        this.filterPosition = pluginConfig.filters.enabled ? pluginConfig.filters.position : undefined;
        this.config = config;
    }
    Skelet.prototype.show = function (wrapperBlock, headingId, context) {
        if (this.config.searchResultType === SearchResultType.Embed && context.pluginConfiguration.results._recreateParent
            && wrapperBlock.parents(this.contentBlock, true).length === 0) {
            UiHelper.recreateNode(sxQuery(this.contentBlock));
        }
        var group = createSkeletonGroup(this.pluginConfig.layout, context);
        var resultBlock = group.find('ul');
        var layoutConfig = this.pluginConfig.layout;
        var isMasonryLayout = layoutConfig.mobile.type === LayoutType.Masonry || layoutConfig.desktop.type === LayoutType.Masonry;
        var numberOfSkelets = isMasonryLayout ? 33 : 12;
        for (var i = 0; i < numberOfSkelets; i++) {
            resultBlock.append(createSkeletonResult(isMasonryLayout));
        }
        if (this.config.contentOnly) {
            addHiddenClasses(group, layoutConfig);
            wrapperBlock.append(group);
            if (isMasonryLayout) {
                Masonry.init(context);
            }
            return;
        }
        var showNavigation = this.filterPosition === undefined && this.navigationPosition !== 'none';
        var layerContent = UiHelper.createLayerContent(this.config.searchResultType, layoutConfig, headingId);
        addHiddenClasses(layerContent, layoutConfig);
        if (this.filterPosition !== undefined) {
            layerContent.append(group);
        }
        else {
            layerContent.append(createSkeletonHeading()).append(group);
        }
        sxQuery(wrapperBlock).html('');
        this.config.searchFieldRenderer();
        if (this.filterPosition !== undefined) {
            wrapperBlock.append(createSkeletonHeading());
            if (!this.pluginConfig.filters.forceSlideIn) {
                wrapperBlock.append(createFilter(this.filterPosition));
                if (this.filterPosition === 'left') {
                    layerContent.addClass('ss360-layer__content--skeleton-filter-left');
                    wrapperBlock.addClass('ss360-flex').addClass('ss360-flex--wrap').addClass('ss360-skeleton--filter-left');
                }
            }
        }
        else if (this.navigationPosition !== 'none') { // keep it simple, show navigation only if not prerendering filters
            wrapperBlock.append(createNavigation(this.navigationPosition));
            if (this.navigationPosition === 'left') {
                layerContent.addClass('ss360-layer__content--skeleton-right');
            }
        }
        wrapperBlock.append(layerContent);
        wrapperBlock.attr('aria-busy', 'true');
        sxQuery("#".concat(this.config.layerId)).addClass('ss360--skeleton');
        if (this.config.searchResultType === SearchResultType.Layover) {
            UiHelper.prepareLayoverLayer(wrapperBlock, this.config.closeLayerHandler, this.pluginConfig);
            if (this.filterPosition === 'left' || (showNavigation && this.navigationPosition === 'left')) {
                wrapperBlock.css('display', 'flex');
                wrapperBlock.find('.ss360-custom-search').css('width', '100%');
            }
            Masonry.init(context);
        }
        else {
            sxQuery(this.contentBlock, true).html(wrapperBlock);
            if (isMasonryLayout) {
                wrapperBlock.show();
                Masonry.init(context);
            }
            else {
                wrapperBlock.fadeIn(undefined, undefined, (this.filterPosition === 'left' && !this.pluginConfig.filters.forceSlideIn) || (showNavigation && this.navigationPosition === 'left') ? 'flex' : undefined);
            }
        }
    };
    return Skelet;
}());
export default Skelet;
